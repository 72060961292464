import { Component, OnInit, HostListener, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OktaAuthService } from '@okta/okta-angular';
import * as OktaSignIn from '@okta/okta-signin-widget';
import config from '../../okta.config';
import * as $  from 'jquery';
import { environment } from 'environments/environment';

import { AuthenticationService } from '../../services/authentication/authentication.service';
import { AuthApiCallService } from '../../services/auth-api-call/auth-api-call.service';

import { OktaAuthService as AuthService } from '../../services/authentication/okta.authentication.service';

const DEFAULT_ORIGINAL_URI = window.location.origin;

let USE_INTERACTION_CODE = false;
if (config.oidc.useInteractionCodeFlow === 'true') {
  USE_INTERACTION_CODE = true;
}
@Injectable()
@Component({
  selector: 'app-okta-login',
  templateUrl: './okta-login.component.html',
  styleUrls: ['./okta-login.component.scss']
})
export class OktaLoginComponent implements OnInit {
  signIn: any;
  pageLoading: boolean = false;
  isC2fUser: boolean = true;
  unKnownUser: any;
  displayUpperImage: boolean;
  errorMessage: any;
  isValidEmail: boolean = false
  constructor(private apiCallService: AuthApiCallService,
    private authenticationService: AuthenticationService,
    public authService: AuthService,
    public oktaAuth: OktaAuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.isC2fUser = true;
    this.signIn = new OktaSignIn({
      /**
       * Note: when using the Sign-In Widget for an OIDC flow, it still
       * needs to be configured with the base URL for your Okta Org. Here
       * we derive it from the given issuer for convenience.
       */
      el: "#sign-in-widget",
      baseUrl: config.oidc.issuer.split('/oauth2')[0],
      clientId: config.oidc.clientId,
      redirectUri: config.oidc.redirectUri,
      logo: '../../../../../assets/icons/connected-world.png',
      i18n: {
        en: {
          'primaryauth.title': 'Sign In',
          'errors.E0000004':'Invalid credentials'
        },
      },
      features: {
        idpDiscovery: true,
        autoPush: true,
        multiOptionalFactorEnroll: true
      },
      idpDiscovery: {
        requestContext: config.oidc.callBackUrl
      },
      authParams: {
        issuer: config.oidc.issuer,
        scopes: config.oidc.scopes,
        display: 'page',
      },
      useInteractionCodeFlow: USE_INTERACTION_CODE,
    });

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.displayUpperImage = event.target.innerWidth <= 960;
  }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(params => {
      this.errorMessage = params['idp_error'];
    });

    let that = this
    let getUserName;
    // Restrict the external Idp redirection for non-sso okta group users.
    $(async function () {
      // $("#okta-sign-in .beacon-container").html("<div>Connected World</div>");

      $("form").attr('autocomplete', 'off');
      $("#idp-discovery-username").attr('autocomplete', 'off');
      $("#idp-discovery-submit").addClass('link-button-disabled').prop('disabled', true);
      if (that.errorMessage == 'resource_not_found') {
        $(".o-form-error-container").html(that.errorMessageRender('E0000007', null))
      }
      else if (that.errorMessage == 'user_not_assigned') {
        $(".o-form-error-container").html(that.errorMessageRender('user_not_assigned', null))
      }
      getUserName = $("#idp-discovery-username").val().toString();
      that.isValidEmail = that.emailIsValid(getUserName)
      if (that.isValidEmail) {
        // check sso process with remember-me checkbox enabled ( onload event)
        $("#idp-discovery-submit").addClass('link-button-disabled').prop('disabled', true);
        await handleSSOProcess(getUserName, that);
      } else {
        $("#idp-discovery-submit").removeClass('link-button-disabled').prop('disabled', false);
        $(".o-form-error-container").html('')
      }

      $(document).on('change', '#idp-discovery-username', async function (e) {
        getUserName = $("#idp-discovery-username").val().toString();
        that.isValidEmail = that.emailIsValid(getUserName)
        if (that.isValidEmail) {
          $("#idp-discovery-submit").addClass('link-button-disabled').prop('disabled', true);
          await handleSSOProcess(getUserName, that);
        } else {
          $("#idp-discovery-submit").removeClass('link-button-disabled').prop('disabled', false);
          $(".o-form-error-container").html('')
        }
      });

      async function handleSSOProcess(getUserName, that){
        let groupResult: any
        let groupIds: any
        $(".o-form-error-container").html('')
          groupResult = await that.fetchOktaGroups(getUserName).then(res => { return res });
          groupIds = groupResult.groupIds
          if (groupResult.isC2fUser) {
            // user who exists in C2F
            if (groupResult.isC2fSSOEnabled) {
              // user who exists in C2F and SSO activated in C2F
              if (groupIds.length === 0) {
                $("#idp-discovery-submit").removeClass('link-button-disabled').prop('disabled', false);
                $(".o-form-error-container").html('')
              }
              else if (groupIds.length > 0 && !groupIds.includes(environment.oktaSSOGroupId)) {
                // user who exists in C2F and Okta. But SSO not enabled in okta
                $("#idp-discovery-submit").addClass('link-button-disabled').prop('disabled', true);
                $(".o-form-error-container").html(that.errorMessageRender('user_not_assigned', null))
              } else {
                $("#idp-discovery-submit").removeClass('link-button-disabled').prop('disabled', false);
                $(".o-form-error-container").html('')
              }
            }
            else {
              // user who exists in C2F (SSO was not enabled), but not in Okta
              $("#idp-discovery-submit").addClass('link-button-disabled').prop('disabled', true);
              $(".o-form-error-container").html(that.errorMessageRender('user_not_assigned', null))
            }
          } else {
              // user who not exists in C2F and SSO not enabled in okta
              $("#idp-discovery-submit").addClass('link-button-disabled').prop('disabled', true);
              $(".o-form-error-container").html(that.errorMessageRender('user_not_found', getUserName))
          }
      }
    });
    if (window.innerWidth <= 960) { this.displayUpperImage = true; }
    // When navigating to a protected route, the route path will be saved as the `originalUri`
    // If no `originalUri` has been saved, then redirect back to the app root
    const originalUri = this.oktaAuth.getOriginalUri();
    if (!originalUri || originalUri === DEFAULT_ORIGINAL_URI) {
      this.oktaAuth.setOriginalUri('/');
    }

    // authenticate with general okta sso
    this.signIn.showSignInToGetTokens({
      el: '#sign-in-widget',
      scopes: config.oidc.scopes
    }).then(tokens => {
      this.signIn.authClient.tokenManager.setTokens(tokens);
      const idToken = tokens.idToken;
      that.c2fLogin(idToken);
    }).catch(err => {
      // Typically due to misconfiguration
      throw err;
    });
  }

  async fetchOktaGroups(username){        
    let apiUrl = environment.nodeApiUrl +`/v1/user/oktaUserGroups?username=`+username;
    var data = await fetch(apiUrl); // Notice the await
    return data.json(); 
  }

  // Login to connected2fiber
  c2fLogin(idToken) {
    if (idToken != null) {
      const body = { 'email': idToken.claims.email };
      this.apiCallService.makePostRequest('/v1/user/ssoLogin/okta', body).subscribe(apiRes => {
        let lastUsedAccount = apiRes.account_list.find(account => account.id === apiRes.user.last_used_account_id);
        apiRes.account_list = apiRes.account_list.filter(account => account.display_role_name !== "Portal User");
        const userCurrentAccountId = lastUsedAccount ? lastUsedAccount.company_id : apiRes.account_list[0].company_id;
        localStorage.setItem('token', apiRes['session_token']);
        localStorage.setItem('selected_company_id', `${userCurrentAccountId}`);
        const account = lastUsedAccount ? lastUsedAccount : apiRes.account_list[0];
        this.authenticationService.onAccountChange(apiRes, account);
        this.router.navigateByUrl('/');
      },
        error => {
          this.isC2fUser = false;
          this.pageLoading = false;
          this.signIn.remove();
          this.authService.logout();
          this.unKnownUser = { 'email': idToken.claims.email, 'name': idToken.claims.name }
        }
      );
    }
  }
  
  back() {
    this.router.navigateByUrl('/login');
  }

  emailIsValid(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  errorMessageRender(error_code, userName) {
    let error_message;
    switch (error_code) {
      case 'user_not_found':
      case 'user_not_assigned':
        error_message = `Login Unsuccessful`;
        break;
      case 'E0000007':
        error_message = 'Please ensure your browser allows third-party cookies.';
        break;
      default:
        error_message = 'SignIn Failed';
    }
    let errorDiv = '<div><div class="okta-form-infobox-error infobox infobox-error" role="alert"><span class="icon error-16"></span><p>' + error_message + '</p></div></div>';
    return errorDiv;
  }
  
  ngOnDestroy() {
    this.signIn.remove();
  }
}
