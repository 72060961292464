import {Action} from '@ngrx/store';

export const SET_USER = '[USER] SET_USER';

export class SetUser implements Action {
  readonly type = SET_USER;
  constructor(public payload: any) {}
}


export const SET_AUTHENTICATION = '[USER] SET_AUTHENTICATION';

export class SetAuthentication implements Action {
  readonly type = SET_AUTHENTICATION;
  constructor(public payload: boolean) {}
}

export const SET_TERMS = '[USER] SET_TERMS_CONDITION';
export class SetTermsandCondition implements Action {
  readonly type = SET_TERMS;
  constructor(public payload: any) {}
}

export type UserActions = SetUser | SetAuthentication | SetTermsandCondition;
