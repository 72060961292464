import { throwError as observableThrowError,  Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthApiCallService {

  private defaultHeader = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE'
  };

  constructor(
    private http: HttpClient
  ) { }

  private createDefaultHeader() {
    return new HttpHeaders({
      ...this.defaultHeader,
    });
  }

  private createDefaultAuthenticatedHeader() {
    return new HttpHeaders({
      ...this.defaultHeader,
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      'Company-ID': localStorage.getItem('selected_company_id') ? localStorage.getItem('selected_company_id') : ''
    });
  }

  makePostRequest(route, body): Observable<any> {
    return this.http.post(`${route}`, body, { headers: this.createDefaultHeader() }).pipe(
      catchError((error: Response) => {
        return observableThrowError(error);
      }));
  }

  makePostWithCustomHeaderRequest(route, body, header): Observable<any> {
    return this.http.post(`${route}`, body, { headers: header }).pipe(
      catchError((error: Response) => {
        return observableThrowError(error);
      }));
  }


  makeAuthenticatedPostRequest(route, body): Observable<any> {
    return this.http.post(`${route}`, body, { headers: this.createDefaultAuthenticatedHeader() }).pipe(
      catchError((error: Response) => {
        return observableThrowError(error);
      }));
  }

  makeAuthenticatedGetRequest(route): Observable<any> {
    return this.http.get(`${route}`, { headers: this.createDefaultAuthenticatedHeader() }).pipe(
      catchError((error: Response) => {
        return observableThrowError(error);
      }));
  }

  makeAuthenticatedPatchRequest(route, body): Observable<any> {
    return this.http.patch(`${route}`, body, { headers: this.createDefaultAuthenticatedHeader()}).pipe(
      catchError((error: Response) => {
        return observableThrowError(error);
      }));
  }

}
