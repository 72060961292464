import {Component, HostListener, OnInit} from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';

import { AuthenticationService } from '../../services/authentication/authentication.service';
import { User } from '../../models/user.model';
import { Router } from '@angular/router';
import {environment} from '../../../../../environments/environment';

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

@Component({
  selector: 'app-signup',
  templateUrl: 'signup.component.html',
  styleUrls: ['./signup.component.scss']
})

export class SignupComponent implements OnInit {

  showSignupComp = true;
  signupForm: FormGroup;
  formSubmitted: boolean = false;
  errorMessage: string;
  displayUpperImage: boolean;
  title: string;

  constructor(
    private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.displayUpperImage = event.target.innerWidth <= 960;
  }

  ngOnInit() {
    if (window.innerWidth <= 960) { this.displayUpperImage = true; }
    this.title = environment.applicationTitle;
    this.checkLocalStorage();
    this.createSignupForm();
  }

  createSignupForm() {
    this.signupForm = this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null, [
        Validators.required,
        Validators.pattern(EMAIL_REGEX)
      ]],
      password: [null, [
        Validators.required,
        Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
      ]],
      confirmPassword: [null, [
        Validators.required
      ]],
    }, {
      validator: this.MatchPassword
    });
  }

  MatchPassword(AC: AbstractControl) {
    const password = AC.get('password').value;
    const confirmPassword = AC.get('confirmPassword').value;
    if (password !== confirmPassword) {
      AC.get('confirmPassword').setErrors( {MatchPassword: true} )
    } else {
      return null;
    }
  }

  submitForm() {
    const user = new User(
      this.signupForm.value.email,
      this.signupForm.value.password,
      this.signupForm.value.firstName,
      this.signupForm.value.lastName
    );
    this.authenticationService.signup(user, 'ep')
      .subscribe(
        () => {
          this.formSubmitted = true;
        },
        err => {
          this.errorMessage = `${err.error.message}`;
        }
      );
    this.signupForm.reset();
  }

  checkLocalStorage() {
    const token = localStorage.getItem('token');
    if (token) {
      this.validateRoute();
    } else {
      this.showSignupComp = true;
    }
  }

  validateRoute() {
    this.authenticationService.isValidUser().subscribe(
      (res) => {
        this.showSignupComp = false;
        this.router.navigateByUrl('/');
      },
      (error) => {
        this.showSignupComp = true;
      });
  }
}
