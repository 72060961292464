<div class="reset-password-wrapper" *ngIf="showResetComp">
  <div *ngIf="displayUpperImage" class="upper-logo-image">
    <img src="../../../../../assets/connectbase.svg" alt="" width="150px" >
  </div>
  <div class="box-wrapper">
    <div class="card">
      <div class="title">{{title}}</div>
      <div class="login-form" fxLayout="column">

        <div class="send-email">
          <h3 class="center-div">Reset password</h3>
          <div class="error" *ngIf="errorMessage">
            <mat-error>{{errorMessage}}</mat-error>
          </div>
          <form [formGroup]="resetPasswordForm" (ngSubmit)="submitForm()" class="login-form">
            <div class="center-div">
              <mat-form-field class="container">
                <input
                  matInput
                  placeholder="New Password"
                  type="password"
                  id="password"
                  class="form-control"
                  formControlName="password"
                >
                <mat-error *ngIf="resetPasswordForm.get('password').errors &amp;&amp; resetPasswordForm.get('password').errors.required">
                  Password is required
                </mat-error>
                <!-- <mat-error *ngIf="resetPasswordForm.get('password').errors &amp;&amp; resetPasswordForm.get('password').errors.pattern">
                  Must be at least 8 character sand contain one uppercase character, one lowercase character, one digit and one special character
                </mat-error> -->
              </mat-form-field>
            </div>
            <div class="center-div">
              <mat-form-field class="container">
                <input
                  matInput
                  placeholder="Confirm New Password"
                  type="password"
                  id="confirmPassword"
                  class="form-control"
                  formControlName="confirmPassword"
                >
                <mat-error *ngIf="resetPasswordForm.get('confirmPassword').errors &amp;&amp; resetPasswordForm.get('confirmPassword').errors.MatchPassword">
                  Must match password
                </mat-error>
              </mat-form-field>
            </div>
            <h5 class="center-div">Password should have at least:</h5>
            <h6>
              <ul>
                <li>Eight characters</li>
                <li>One upper case character</li>
                <li>One lower case character</li>
                <li>One digit</li>
                <li>One special character</li>
              </ul>
            </h6>
            <div class="button">
              <button
                mat-raised-button
                type="submit"
                [disabled]="!resetPasswordForm.valid"
              >Submit</button>
            </div>
            <div class="back-to-login-link">
              <a class="link" routerLink="/">Back to Login</a>
            </div>
          </form>
        </div>

      </div>
    </div>
    <div class="company-logo-card">
      <div class="img-container"><img src="../../../../../assets/connectbase.svg" alt="" width="250px"></div>
    </div>
  </div>
</div>

<div class="token-expired" *ngIf="showTokenExpired">
  Link is expired!
</div>