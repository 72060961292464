import { Injectable } from '@angular/core';
import { AuthApiCallService } from '../auth-api-call/auth-api-call.service';

@Injectable()
export class ResetPasswordService {

  constructor(
    private apiCallService: AuthApiCallService
  ) {}

  resetPassword(newPassword, token) {
    const body = { newPassword, token };
    return this.apiCallService.makePostRequest(`/v1/reset-password-in-db`, body)
  }

}
