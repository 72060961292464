import { MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition } from '@angular/material';
import { isEqual, filter, cloneDeep } from 'lodash';
import { environment } from 'environments/environment';

export class CommonUtil {
    public static EMAIL_PATTERN = '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$' ;
    public static PHONE_PATTERN = '^(?=.*[0-9])[- +()0-9]+$';
    public static PASSWORD_PATTERN = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[$@('@')$!%*#?&{}[\]<>()^+=;:'_&quot;/\\|-])[A-Za-z\d$@('@')$!%*#?&{}[\]<>()^+=;:'_&quot;/\\|-]{8,20}$/;

    public static extractProperty(list: Array<Object>, property) {
        return list.map(function(e) {
            return e[property];
        })
    }

    public static extractDistinctProperty(list: Array<Object>, property) {
       return list.map(item => item[property]).filter((value, index, self) => self.indexOf(value) === index);
    }

    public static getSnackBarConfig(verticalPosition: MatSnackBarVerticalPosition, horizontalPosition: MatSnackBarHorizontalPosition) {
      return {
        verticalPosition: verticalPosition,
        horizontalPosition: horizontalPosition,
        duration: 2000
      };
    }

    public static remove$original(list) {
      list.forEach(function(item) {
        delete item['$original'];
      })
    }

    public static findModifiedObjectsInList(list, deleteOrig) {
      let modifiedObj = {};

      list.forEach((item, index)  => {
        var original = item.$original;
        var modifiedItem = cloneDeep(item);
        delete modifiedItem['$original'];

        if(deleteOrig)
          delete item['$original'];

        if(!isEqual(modifiedItem,original))
          modifiedObj[index] = item;
      });
      return modifiedObj;
    }

    public static findInArray(list, property, value) {
      return filter(list, item => item[property] === value);
    }

    public static findInStrArray(list, property, value) {
      return filter(list, item => {
        if(item[property]) {
           return item[property].toLowerCase() === value.toLowerCase()
        }
      });
    }

    public static isEmpty(str) {
      return str == undefined || str.trim() == '';
    }

    public static formatResponse(rows) {
      rows.forEach(row => {
        row['$original'] = /* JSON.parse(JSON.stringify(row)); */ cloneDeep(row);
      });
      return rows;
    }

    public static setOptionsDefault(list, inputs, property) {
      let val;
      list.forEach(element => {
        val = typeof element[property] == 'string' ? parseInt(element[property]) : element[property];
        element.checked = inputs.indexOf(val) > -1;
      });
    }

    /**
     * Build form data params to be
     * sent to the file upload api
     * @param files
     */
    public static getFormData(files, uploadType) {
      const formData: FormData = new FormData();
      for (let file of files) {
        formData.append('files', file, file.name);
      }
      formData.append('upload_type', uploadType);
      return formData;
    }

    public static downloadFile(data: any, type: string, filename: string) {
      const url = URL.createObjectURL(new Blob([data], { type: type }));
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }

}
