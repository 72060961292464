<div class="flex-btw">
  <h3 mat-dialog-title>Reset Password</h3>
</div>
<div mat-dialog-content>
  <form fxLayout="column" class="full-width" #rpForm="ngForm" fxLayoutGap="20px">
      <mat-form-field>
        <input id="pwdOrig" name="pwdOrig" matInput type="password" #pwdOrig="ngModel"
          placeholder="Password" [(ngModel)]="currentPassword" required [pattern]="passwordPattern">

        <mat-error *ngIf="pwdOrig.invalid && pwdOrig.errors.required">
          Password is required
        </mat-error>
        <mat-error *ngIf="pwdOrig.invalid && pwdOrig.errors.pattern">
          Password should contain uppercase, lowercase, number, special character and  at least 8 characters.
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input id="pwdConfirm" name="pwdConfirm" matInput type="password" #pwdConfirm="ngModel"
          placeholder="Confirm Password" [(ngModel)]="confirmPassword" required>

        <mat-error *ngIf="pwdConfirm.invalid && pwdConfirm.errors.required">
            This is required
        </mat-error>
      </mat-form-field>
  </form>
</div>
<div mat-dialog-actions class="flex-box-end m-t-10">
  <span class="reset_pwd_error">{{errorMsg}}</span>
  <button mat-raised-button color="primary" [disabled]="!rpForm.valid" id="reset_pwd_btn" (click)="resetPassword()">Reset Password</button>
  <button mat-raised-button mat-dialog-close id="reset_cancel_btn">Cancel</button>
</div>

<!-- <div>
  <h3 class="title-txt">Reset Password</h3>
  <form fxLayout="column" class="full-width pad-15" #rpForm="ngForm">
    <div class="feedback_box">
      <mat-form-field>
        <input id="pwdOrig" name="pwdOrig" matInput type="password" #pwdOrig="ngModel"
          placeholder="Password" [(ngModel)]="currentPassword" required [pattern]="passwordPattern">

        <mat-error *ngIf="pwdOrig.invalid && pwdOrig.errors.required">
          Password is required
        </mat-error>
        <mat-error *ngIf="pwdOrig.invalid && pwdOrig.errors.pattern">
          Password should contain uppercase, lowercase, number, special character and  at least 8 characters.
        </mat-error>

      </mat-form-field>
      <mat-form-field class="mt-20">
        <input id="pwdConfirm" name="pwdConfirm" matInput type="password" #pwdConfirm="ngModel"
          placeholder="Confirm Password" [(ngModel)]="confirmPassword" required>

        <mat-error *ngIf="pwdConfirm.invalid && pwdConfirm.errors.required">
            This is required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="submit_feedback flex-btw">
      <span>{{errorMsg}}</span>
      <button class="text-uppercase" mat-raised-button color="primary" [disabled]="!rpForm.valid" (click)="resetPassword()">Reset Password</button>
    </div>
  </form>
</div> -->
