import { Component, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { InfoService } from 'app/services/info.service';

@Component({
  selector: 'app-quick-quote-sheet',
  templateUrl: './quick-quote-sheet.component.html',
  styleUrls: ['./quick-quote-sheet.component.css']
})
export class QuickQuoteSheetComponent implements OnInit {

  buildingId;
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public passedData: any,
  private infoService: InfoService) { }

  ngOnInit() {
  }

  hideBottomSheet() {
    this.infoService.notifyOther(InfoService.HIDE_BOTTOM_SHEET);
  }
}
