import { Injectable } from '@angular/core';
import { Router, NavigationStart, ActivatedRoute, Params } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { find } from 'lodash';
/*
  Application Environment File
  Needs to be added manually from root of application
 */
import { environment } from '../../../../../environments/environment';

/* Services Start */
import { AuthApiCallService } from '../auth-api-call/auth-api-call.service';
import { OktaAuthService } from '@okta/okta-angular';

/* Services End */

/* NGRX Start */
import {select, Store} from '@ngrx/store';
import * as fromApp from '../../../../app.reducers';
import * as userActions from '../../../authentication/store/user.actions';
/* NGRX End */

// Other
import { User } from '../../models/user.model';
import {Observable, BehaviorSubject} from 'rxjs';
import {filter, map} from 'rxjs/operators';

@Injectable()
export class AuthenticationService {

  private _activeCompany: string;
  get activeCompany() { return this._activeCompany; }
  set activeCompany(activeCompany: string) { this._activeCompany = activeCompany; }

  constructor(
    private apiCallService: AuthApiCallService,
    private store: Store<fromApp.AppState>,
    private router: Router,
    private oktaAuth: OktaAuthService,
  ) {}

  public isAuthenticated(queryToken?: string): boolean {
    const helper = new JwtHelperService();
    const token = queryToken ? queryToken : localStorage.getItem('token');
    const isExpired = helper.isTokenExpired(token);

    if (queryToken) {
      localStorage.setItem('token', queryToken);
    }

    return !isExpired;
  }

  isValidUser(): Observable<any> {
    return this.apiCallService.makeAuthenticatedGetRequest(`/v1/is-valid-user`);
  }

  login(user: User): Observable<any> {
    return this.apiCallService.makePostRequest(`/v1/user/login`, user);
  }

  ssoLogin(provider): Observable<any> {
    return this.apiCallService.makePostRequest('/v1/user/ssoLogin', {});
  }

  accountLogin(onChangeAccountID: number): Observable<any> {
    return this.apiCallService.makeAuthenticatedPostRequest(`/v1/user/accountLogin`, {onChangeAccountID: onChangeAccountID});
  }

  updateUserCurrentAccount(accountId: number): Observable<any> {
    return this.apiCallService.makeAuthenticatedPatchRequest(`/v1/user/account`, { accountId });
  }

  logout() {
    this.store.dispatch(new userActions.SetAuthentication(false));
    this.store.dispatch(new userActions.SetUser(null));
    const initialPage = localStorage.getItem('initialPage') ? localStorage.getItem('initialPage') : '/';
    const lastLocationsSearch = localStorage.getItem('lastLocationsSearch') ? localStorage.getItem('lastLocationsSearch') : '';
    if(localStorage.getItem('okta-token-storage')){
      let that = this
      this.oktaAuth.session.get()
      .then(function (session) {
          if (session || session.status === "ACTIVE") {
            that.oktaAuth.closeSession()
          }
      }).catch(function (error) {
        console.error('Exception in logout', error)
      });
    }    
    this.activeCompany =  this.activeCompany || localStorage.getItem('portal_company_name');
    localStorage.clear();
    localStorage.setItem('initialPage', initialPage);
    localStorage.setItem('lastLocationsSearch', lastLocationsSearch);
    (environment.application === 'PORTAL') ? this.router.navigateByUrl(this.activeCompany + '/login') : this.router.navigate(['login']);
    this.deleteAllCookies();
  }

  initialUserValidation() {
    if (this.isAuthenticated()) {
      this.isValidUser().subscribe(
        (apiRes) => {
          let selectedID = Number(localStorage.selected_company_id);
          if (environment.application !== 'CW' && environment.application !== 'PORTAL') {
            const lastUsedAccount = apiRes.account_list.find(account => account.id === apiRes.user.last_used_account_id);
            selectedID = lastUsedAccount ? lastUsedAccount.company_id : apiRes.account_list[0].company_id;
            localStorage.setItem('selected_company_id', `${selectedID}`);
            // CW uses accountLogin route while all other apps are using login route to dispatch userInformation data.
            this.setUserInformation(apiRes, selectedID);
          }
          if (environment.application === 'CW') {
            // CW uses accountLogin route to dispatch userInformation data.
            let lastUsedAccount = apiRes.user.last_used_account_id ? apiRes.account_list.find(account => account.id === apiRes.user.last_used_account_id && account.display_role_name !== "Portal User") : undefined;
            if(  !lastUsedAccount ) lastUsedAccount = apiRes['account_list'].find(elem => elem.company_id === selectedID);
            this.onAccountChange(apiRes, lastUsedAccount);
          }
          if (environment.application === 'PORTAL') {
            let acc = find(apiRes['account_list'], { display_role_name: 'Portal User', portal_url_suffix:this.activeCompany });
            if (acc && acc['is_att_portal'] == 0) {
              if (acc['portal_url_suffix'] !== this.activeCompany) {
                this.logout();
              }
              else {
                this.onAccountChange(apiRes, acc);
                this.router.navigateByUrl('/' + this.activeCompany);
              }
            }
            else {
              this.logout();
            }
          }
        }, (err) => {
          this.logout();
        });
    }
  }

  setUserInformation(apiRes, selectedCompanyID: number): void {
    apiRes['settings'] = apiRes['account_list'].find(elem => elem.company_id === selectedCompanyID);
    apiRes['token'] = apiRes['session_token'];
    this.store.dispatch(new userActions.SetUser(apiRes));
    this.store.dispatch(new userActions.SetAuthentication(true));
  }

  signup(user: User, application) {
    const body = {user, application};
    return this.apiCallService.makePostRequest(`/v1/user/signup`, body);
  }

  multiTabValidation(e) {
    if ( e.key === 'selected_company_id') {
      this.initialUserValidation();
      const initialRoutes = ['/login', '/forgot-password', 'reset-password', 'signup'];
      // @ts-ignore
      if (initialRoutes.includes(this.router.url)) {
        this.router.navigate(['/']);
      }
    }
    if (e.key === null && e.newValue === null) {
      this.store.dispatch(new userActions.SetAuthentication(false));
      (environment.application === 'PORTAL') ? this.router.navigateByUrl(this.activeCompany + '/login') : this.router.navigate(['login']);
    }
  }

  getSelectedCompany(userInfomration: any, companyId: number) {
    return userInfomration.account_list.find(account => account.company_id === companyId);
  }

  updateUserInfomationData(userInformationData: any, selectedCompany: any) {
    return {
      ...userInformationData,
      settings: selectedCompany,
      user: { ...userInformationData.user, last_used_account_id: selectedCompany.id }
    };
  }

  onAccountChange(userInformation: any, selectedCompany: any) {
    localStorage.setItem('selected_company_id', `${selectedCompany.company_id}`);
    this.updateUserCurrentAccount(selectedCompany.id).subscribe();
    this.accountLogin(selectedCompany.id).subscribe(apiRes => {
      const newToken = apiRes.access_token;
      localStorage.setItem('token', newToken);
      userInformation.session_token = `Bearer ${newToken}`;
      userInformation.token = `Bearer ${newToken}`;
      if (environment.application === 'CW' || environment.application === 'PORTAL') {
        userInformation.settings = apiRes;
        // Note: account_id is being used by EP in many places. Not sure in how many places id within connected world side is being used. Both values should be identical.
        userInformation.settings.account_id = userInformation.settings.id;
        // Check user level permission for list manager
        let isListManagerUserPermission = userInformation.groups.some(
          group => group.permission_id === environment.LIST_MANAGER_PERMISSION && group.user_account_id === userInformation.settings.id
        )
        userInformation.settings.is_list_manager_enabled = (userInformation.settings.is_list_manager_enabled && isListManagerUserPermission) ? true : false;
      }
      this.store.dispatch(new userActions.SetUser(userInformation));
      if(!('portal_terms_condition' in selectedCompany)) // Portal User 
        this.store.dispatch(new userActions.SetAuthentication(true));
      if (environment.application === 'CW' && (this.router.url === '/login' ||
        this.router.url === '/chooselogin' || this.router.url.includes('/connected_world')) ) {
            this.router.navigateByUrl('/');
      }
    });
  }

  isAuthenticated$(): Observable<boolean> {
    return this.store.pipe(select(state => state['userFeature']['userData']['is_authenticated']));
  }

  onLogout$(): Observable<boolean> {
    return this.store.pipe(
      select(state => state['userFeature']['userData']['is_authenticated']),
      filter(is_authenticated => is_authenticated === false),
      map(() => true)
    );
  }

  userInformation$(): Observable<any> {
    return this.store.pipe(
      select(userState => userState['userFeature']['userData']['user_information']),
      filter(user_information => user_information !== null)
    );
  }

  enterpriseAccess$(): Observable<boolean> {
    return this.userInformation$().pipe(
      select(userData => userData['user']['ep_access_level']),
      map(accessLevel => (accessLevel >= 0 && accessLevel <= 3))
    );
  }

  accountInfo$(): Observable<any> {
    return this.userInformation$().pipe(
      select(userInformation => userInformation['settings'])
    );
  }

  private _userInfo = undefined;

  private _accountUser: BehaviorSubject<any> = new BehaviorSubject(this._userInfo);
  get accountUser() {
    return this._accountUser;
  }
  updatAccountUser(newValue) {
    this._accountUser.next(newValue);
  }

  deleteAllCookies() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
}
