<div fxLayout="row wrap" id="quick_quote_table">
   
    <p-table #dt
    [columns]="tableCols" 
    [paginator]="!accountInfo.is_att_portal" [rows]="10" [totalRecords]="undefined != rows[0] ? rows[0].length : 0"
    [value]="rows[0]" [(selection)]="selectedItems" [resizableColumns]="true" columnResizeMode="expand">

      <ng-template pTemplate="header" let-columns>
        <tr>
          <th class="box-table" *ngIf="accountInfo.is_att_portal">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>

          <th *ngFor="let col of columns"  [ngSwitch]="col.field" [pSortableColumn]="col.sort" pResizableColumn>
              {{col.title}}
              <mat-icon *ngSwitchCase="'total'" title="Term * MRC + NRC">info</mat-icon>
              <mat-icon *ngSwitchCase="'totalMrc'" title="Additional IP Block Fee (MRC) + Equipment Fees (MRC) + Circuit MRC">info</mat-icon>
              <mat-icon *ngSwitchCase="'totalNrc'" title="Installation (NRC)">info</mat-icon>
          </th>

          <th *ngIf="accountInfo.is_att_portal">
            <div class="reqInfo" [ngClass]="{'disable_click': selectedItems.length == 0}" (click)="sendSelectedReqInfo()">Request Selected</div>
          </th>
        </tr>
        <tr>
          <th  *ngIf="accountInfo.is_att_portal" class="box-table">
            <div class="icon-trigger">
              <i matTooltip="Clear All Filters" class="material-icons icon_click" (click)="clearAllFilters()">highlight_off</i>
            </div>
          </th>
          <th *ngFor="let col of columns" [ngSwitch]="col.field">
        
            <input class = "filter_element_width" pInputText type="text" *ngSwitchCase="'tierName'"
              (input)="(dt.filter($event.target.value,  'tierName', 'tierName'.filterMatchMode))" [(ngModel)]="pricingFilter.tierName" #ctrl="ngModel" placeholder="Filter">        
            <input class = "filter_element_width" pInputText type="text" *ngSwitchCase="'speed'"
              (input)="(dt.filter($event.target.value,  'speed', 'speed'.filterMatchMode))" [(ngModel)]="pricingFilter.speed" placeholder="Filter">
            
            <input class = "filter_element_width" pInputText type="text" *ngSwitchCase="'speedName'"
              (input)="(dt.filter($event.target.value,  'speedName', 'speedName'.filterMatchMode))" placeholder="Filter">
            
            <input class = "filter_element_width" pInputText type="text" *ngSwitchCase="'accessMedium'"
              (input)="(dt.filter($event.target.value,  'accessMedium', 'accessMedium'.filterMatchMode))" placeholder="Filter">

            <input class = "filter_element_width" pInputText type="text" *ngSwitchCase="'serviceName'"
              (input)="(dt.filter($event.target.value,  'serviceName', 'serviceName'.filterMatchMode))" placeholder="Filter">

            <input class = "filter_element_width" pInputText type="text" *ngSwitchCase="'portSpeed'"
            (input)="(dt.filter($event.target.value,  'portSpeed', 'portSpeed'.filterMatchMode))" [(ngModel)]="pricingFilter.portSpeed" #ctrl="ngModel"  placeholder="Filter">
            <input class = "filter_element_width" pInputText type="text" *ngSwitchCase="'bandwidth'"
            (input)="(dt.filter($event.target.value,  'bandwidth', 'bandwidth'.filterMatchMode))" [(ngModel)]="pricingFilter.bandwidth" placeholder="Filter">  
            <input class = "filter_element_width" pInputText type="text" *ngSwitchCase="'cir'"
              (input)="(dt.filter($event.target.value,  'cir', 'cir'.filterMatchMode))" [(ngModel)]="pricingFilter.cir" placeholder="Filter">
            <input class = "filter_element_width" pInputText type="text" *ngSwitchCase="'cos'"
            (input)="(dt.filter($event.target.value,  'cos', 'cos'.filterMatchMode))" [(ngModel)]="pricingFilter.cos" placeholder="Filter">
            <input class = "filter_element_width" pInputText type="text" *ngSwitchCase="'term'"
            (input)="(dt.filter($event.target.value,  'term', 'term'.filterMatchMode))" [(ngModel)]="pricingFilter.term" placeholder="Filter">
            <input class = "filter_element_width" pInputText type="text" *ngSwitchCase="'mrc'"
            (input)="(dt.filter($event.target.value,  'mrc', 'mrc'.filterMatchMode))" [(ngModel)]="pricingFilter.mrc" placeholder="Filter">   
            <input class = "filter_element_width" pInputText type="text" *ngSwitchCase="'nrc'"
            (input)="(dt.filter($event.target.value,  'nrc', 'nrc'.filterMatchMode))" [(ngModel)]="pricingFilter.nrc" placeholder="Filter">   
            <input class = "filter_element_width" pInputText type="text" *ngSwitchCase="'productAvailability'"
            (input)="(dt.filter($event.target.value,  'productAvailability', 'productAvailability'.filterMatchMode))" [(ngModel)]="pricingFilter.productAvailability" placeholder="Filter">   
            <input class = "filter_element_width" pInputText type="text" *ngSwitchCase="'offerType'"
            (input)="(dt.filter($event.target.value,  'offerType', 'offerType'.filterMatchMode))" [(ngModel)]="pricingFilter.offerType" placeholder="Filter">   
            <input class = "filter_element_width" pInputText type="text" *ngSwitchCase="'ipType'"
            (input)="(dt.filter($event.target.value,  'ipType', 'ipType'.filterMatchMode))" [(ngModel)]="pricingFilter.ipType" placeholder="Filter">   
            <input class = "filter_element_width" pInputText type="text" *ngSwitchCase="'addnIpBlockFeeMrc'"
            (input)="(dt.filter(accountInfo.currency_type+$event.target.value,  'addnIpBlockFeeMrc', 'addnIpBlockFeeMrc'.filterMatchMode))" [(ngModel)]="pricingFilter.addnIpBlockFeeMrc" placeholder="Filter">   
            <input class = "filter_element_width" pInputText type="text" *ngSwitchCase="'equipmentFeeMrc'"
            (input)="(dt.filter(accountInfo.currency_type+$event.target.value,  'equipmentFeeMrc', 'equipmentFeeMrc'.filterMatchMode))" [(ngModel)]="pricingFilter.equipmentFeeMrc" placeholder="Filter">   
            <input class = "filter_element_width" pInputText type="text" *ngSwitchCase="'installationNRC'"
            (input)="(dt.filter(accountInfo.currency_type+$event.target.value,  'installationNRC', 'installationNRC'.filterMatchMode))" [(ngModel)]="pricingFilter.installationNRC" placeholder="Filter">   
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-row let-columns="columns">
        <tr [pSelectableRow]="row" id="custom_qq">
          <td *ngIf="accountInfo.is_att_portal" >
              <p-tableCheckbox [value]="row"></p-tableCheckbox>
          </td>
          <td *ngFor="let col of columns" class="ui-resizable-column column_data_prop">
            <div class="lable_restrict" title = "{{tableUtilityService.getCellData(row, col)}}">{{ tableUtilityService.getCellData(row, col) }}</div>
          </td>
          <td *ngIf="accountInfo.is_att_portal"><div class="reqInfo" (click)="sendQuoteRequestMail(row)">Request Info</div></td>
        </tr>
      </ng-template>
       <!-- test -->
       <ng-template pTemplate="paginatorleft" let-state>
        <div  *ngIf="!productLoading && state.totalRecords > 0">
            {{ tableUtilityService.getPaginatorContent(state.page+1, state.rows, state.totalRecords) }} records.
        </div>
      </ng-template>
      <!-- test -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr *ngIf="!productLoading">
          <!-- <div class="text-center data_not_found">No Results Found</div> -->
          <td class="text-center" [attr.colspan]="columns.length" >No Results Found</td>
        </tr>
      </ng-template>

    </p-table>
    
    <div class="backdrop" *ngIf="productLoading">
      <div class="flex-box custom-loader isn-loader-location"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
    </div>
  </div>
