import * as UserActions from './user.actions';

export interface State {
  user_information: any;
  is_authenticated: boolean;
  terms_condition: any;
}

const initialState: State = {
  user_information: null,
  is_authenticated: null,
  terms_condition: null
};

export function userReducer(state = initialState, action: UserActions.UserActions) {
  switch (action.type) {
    case UserActions.SET_USER:
      return {
        ...state,
        user_information: action.payload
      };
    case UserActions.SET_AUTHENTICATION:
      return {
        ...state,
        is_authenticated: action.payload
      };
    case UserActions.SET_TERMS:
      return {
        ...state,
        terms_condition: action.payload
      };
    default:
      return state;
  }
}
