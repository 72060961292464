import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import { LookupIdentifiers } from '../../shared/lookup-identifiers';
import { LookupService } from 'app/services/lookup/lookup.service';
import { ApiCallService } from 'app/services/api-call.service';
import { SharedDataService } from './../shared-data.service';

@Injectable()
export class CompanyLookupService extends LookupService {

  private _pricingChannels =  [{channelId: 1, channelName:"Default"}, {channelId: 2, channelName:"Wholesale"},
                        {channelId: 3, channelName:"Enterprise"}, {channelId: 4, channelName:"Channel"}];

  private _roles = [];
  private _accessLevels = [];
  private _groups = [];

  constructor(apiCallService: ApiCallService,
    sharedDataService: SharedDataService) {
    super(apiCallService, sharedDataService);
  }


  get pricingChannels() {
    return this._pricingChannels;
  }

  public getEntitledChannel(entitledChannelIds) {
    let entitledChannelArr = entitledChannelIds ? entitledChannelIds.split(',') : [];
    const channels = [];

    this.pricingChannels.forEach(item => {
      if(entitledChannelArr.includes(item.channelId.toString()))
        channels.push(item);
    });
    return channels;
  }

  get roles() {
    return this._roles;
  }
  set roles(roles) {
    this._roles = roles;
  }

  public fetchRoles() {
    this.apiCallService.processHttpRequest('GET', '/api/v1/data/roles', undefined, undefined,
      (res => this._roles = res.filter(e => e.Id != 4 && e.Id != 5 && e.Id != 6)), undefined); // remove super admin and support roles
  }

  get accessLevels() {
    return this._accessLevels;
  }
  set accessLevels(accessLevels) {
    this._accessLevels = accessLevels;
  }

  public fetchAccessLevels() {
    this.apiCallService.processHttpRequest('GET', '/api/v1/data/access/levels', undefined, undefined,
      (res => this._accessLevels = res), undefined);
  }

  get groups() {
    return this._groups;
  }

  set groups(groups) {
    this._groups = groups;
  }

  public fetchGroups(id: number) {
    this.apiCallService.processHttpRequest('GET', '/api/v1/groups?companyId=' + id, undefined, undefined,
    (res => this._groups = res), (err => console.log('Error on Compant groups fetch: ', err)));
  }
}
