import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from 'environments/environment';

@Injectable()
export class SharedDataService {
  private _pageOptions = [25, 50, 100/* , 500 */];

  get pageOptions() {
    return this._pageOptions;
  }

  private lookupOptions: any = {
    FiberConnected: [],
    BuildingConnectivity: [],
    FiberOwnership: [],
    locationTypeRef: [],
    AccessMedium: [],
    competitiveRating: ['Green', 'Yellow', 'Red'],
    INSTALL_INTERVAL: [],
    PROVIDER: [],
    HANDOFF_TYPE: [],
    WAVE_HANDOFF_TYPE: [],
    PROTECTION_TYPE: [],
    DISTANCE_BAND: [],
    DIVERSITY_TYPE: [],
    PRICING_LOCATION_TYPE: [],
    locationTypeGroup: [],
    CUSTOM_FIELDS: [],
    Country: [],
    State: [],
    ADDRESS_STATUS: [{id: 1, text: 'Valid USPS'},
                    {id: 2, text: 'No USPS'}],
    Zoning: []
  };
  _lookupSource: BehaviorSubject<any> = new BehaviorSubject(this.lookupOptions);

  get lookupSource(): BehaviorSubject<any> {
    return this._lookupSource;
  }

  public updateLookupSource(newValue) {
    let currentVal = this._lookupSource.getValue();
    this._lookupSource.next(Object.assign(currentVal, newValue));
  }
  resetLookupSource() {
    /* this.lookupOptions = {
      FiberConnected: [],
      BuildingConnectivity: [],
      FiberOwnership: [],
      locationTypeRef: [],
      AccessMedium: [],
      competitiveRating: ['Green', 'Yellow', 'Red'],
      INSTALL_INTERVAL: [],
      PROVIDER: [],
      HANDOFF_TYPE: [],
      PROTECTION_TYPE: [],
      DISTANCE_BAND: [],
      DIVERSITY_TYPE: [],
      locationTypeGroup: [],
      CUSTOM_FIELDS: [],
      Country: [],
      State: [],
      ADDRESS_STATUS: [{id: 1, text: 'Valid USPS'},
                      {id: 2, text: 'No USPS'}],
      Zoning: []
    } */
    this.lookupOptions = Object.assign(this.lookupOptions, { CUSTOM_FIELDS: [] });
    this._lookupSource = new BehaviorSubject(this.lookupOptions);
  }

  cwOptions: any = {
    nr_supplier: [],
    company_routes: [],
    saved_routes: [],
    published_routes: [],
    supplier: [],
    product: [],
    access_tech: [],
    speed: []
  };

  _cwSource: BehaviorSubject<any> = new BehaviorSubject(this.cwOptions);

  get cwSource(): BehaviorSubject<any> {
    return this._cwSource;
  }

  updateCWSource(newValue) {
    let currentVal = this._cwSource.getValue();
    this._cwSource.next(Object.assign(currentVal, newValue));
  }

  resetCWSource() {
    this.cwOptions = {
      nr_supplier: [],
      company_routes: [],
      saved_routes: [],
      published_routes: [],
      supplier: [],
      product: [],
      access_tech: [],
      speed: []
    };
    this._cwSource = new BehaviorSubject(this.cwOptions);
  }

  private exportedFiles: any[] = [];
  _exportSource: BehaviorSubject<any> = new BehaviorSubject(this.exportedFiles);
  get exportSource(): BehaviorSubject<any> {
    return this._exportSource;
  }
  updateExportSource(newValue) {
    this._exportSource.next(newValue);
  }

  private importedFiles: any[] = [];
  _importSource: BehaviorSubject<any> = new BehaviorSubject(this.importedFiles);
  get importSource() {
    return this._importSource;
  }
  updateImportSource(newValue) {
    this._importSource.next(newValue);
  }

  private _importActionFlag = undefined;
  _importActionSource: BehaviorSubject<any> = new BehaviorSubject(this._importActionFlag);
  get importActionSource() {
    return this._importActionSource;
  }
  updateActionSource(newValue) {
    this._importActionSource.next(newValue);
  }
  resetActionSource() {
    this._importActionFlag = undefined;
    this._importActionSource = new BehaviorSubject(this._importActionFlag);
  }

  private _routeStatusOptions: any[] = [];
  get routeStatusOptions() {
    return this._routeStatusOptions;
  }
  set routeStatusOptions(options) {
    this._routeStatusOptions = options;
  }

  private _aerialPlantOptions = [{label: 'Yes', value: true}, {label: 'No', value: false}];
  get aerialPlantOptions() {
    return this._aerialPlantOptions;
  }

  private _listTypeOptions = [{label: 'Static', value: 1, valueBoolean: true}, {label: 'Dynamic', value: 0, valueBoolean: false}];
  get listTypeOptions() {
    return this._listTypeOptions;
  }

  private _statusOptions = [{label: 'Active', value: 1, displayLabel: 'Active'}, {label: 'Inactive', value: 0, displayLabel: 'Expired'}];
  get statusOptions() {
    return this._statusOptions;
  }

  private _contactCategoryOptions = [];
  get contactCategoryOptions() {
    return this._contactCategoryOptions;
  }
  set contactCategoryOptions(options) {
    this._contactCategoryOptions = options;
  }

  private _listStatus = [{id : 1, text : "Building"}, {id : 4, text : "Route"}, {id : 8, text : "Pricing"}];
  get listStatus() {
    return this._listStatus;
  }

  private _frequencyOptions = [];
  get frequencyOptions() {
    return this._frequencyOptions;
  }
  set frequencyOptions(options) {
    this._frequencyOptions = options;
  }

  private _listConfigTypeOptions = [{label: 'All', value: true}, {label: 'Choose Configuration', value: false}];
  get listConfigTypeOptions() {
    return this._listConfigTypeOptions;
  }

  private _bListFormatOptions = [];
  get bListFormatOptions() {
    return this._bListFormatOptions;
  }
  set bListFormatOptions(options) {
    this._bListFormatOptions = options;
  }

  private _rListFormatOptions = [];
  get rListFormatOptions() {
    return this._rListFormatOptions;
  }
  set rListFormatOptions(options) {
    this._rListFormatOptions = options;
  }

  private _pListFormatOptions = [];
  get pListFormatOptions() {
    return this._pListFormatOptions;
  }
  set pListFormatOptions(options) {
    this._pListFormatOptions = options;
  }

  get maxFileUploadSize() {
    return environment.maxUploadSize * environment.unitMBInBytes;
  }

  resetValues() {
    this.resetLookupSource();
    this.resetCWSource();
    this.resetActionSource();
    // reset export
    this.exportedFiles = [];
    this._exportSource = new BehaviorSubject(this.exportedFiles);
    // reset import
    this.importedFiles = [];
    this._importSource = new BehaviorSubject(this.importedFiles);
  }

}
