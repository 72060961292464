<div class="side-navbox-container full-height">
  <mat-sidenav-container  class="example-sidenav-container full-height" (backdropClick)="close('backdrop')" hasBackdrop="false">
    <mat-sidenav #mapsidenav mode="over" (keydown.escape)="close('escape')"
      class="map-sidenav side-visible custm_close" position="end">
      <div class="side-close icon_click" (click)="close('button')">
        <i class="material-icons">close</i>
      </div>

      <div class="building-info-sidenav" color="secondary" *ngIf="sideNavContent.buildingInfo" class="sidefull_height">
        <app-map-building-info [marketInfo]="mpxFeature.properties" [attPricingInfo]="attPricingTierInfo" [productSelected] ="productSelected"></app-map-building-info>
      </div>

    </mat-sidenav>

    <mat-sidenav-content>
      <div class="container map_container">
          <!-- <button id="canvas_filter_icon" mat-icon-button (click)="toggleSideBar(1)" class="side_menu_btn" matTooltip="Filter" matTooltipPosition="left">
            <img src="/assets/images/filter.svg"/>
          </button> -->
          <!-- <button *ngIf="isFullscreen" mat-icon-button class="side_menu_btn" matTooltip="Help" matTooltipPosition="left" (click)="inlineService.showPanel()">
            <img src="/assets/images/support.svg"/>
          </button> -->
          <div id="cw_map" class="full-height"></div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

    <div class="map-layer" mat-button [matMenuTriggerFor]="view_type"><i class="material-icons">layers</i></div>
    <mat-menu #view_type="matMenu">
      <mat-radio-group class="flex-column" [(ngModel)]="currentStyle">
      <mat-radio-button class="pad-10" *ngFor="let style of styles" [value]="style.value" color="primary" (change)="changeMapStyle($event)">
        {{style.name}}
      </mat-radio-button>
    </mat-radio-group>
    </mat-menu>

</div>
<div class="lds-ellipsis map-loader" *ngIf="mapLoading"><div></div><div></div><div></div><div></div></div>
<div class="distance-container" *ngIf="showDistanceDialog">
  <div><span class="distance-container-close" (click)="showDistanceDialog = false"><i class="fa fa-times" aria-hidden="true"></i></span></div>
  <pre>{{distancePopupContent}}
  </pre>
</div>

