import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { AppConstants } from "app/app.constants";
import { ApiCallService } from "app/services/api-call.service";
import { SharedDataService } from "app/services/shared-data.service";
import { CommonUtil } from "app/shared/utils/common-util";
import { environment } from "environments/environment";
import { cloneDeep, find, isEmpty, isEqual } from "lodash";
import { interval, Observable, Subscription } from "rxjs";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: "app-file-export",
  templateUrl: "./file-export.component.html",
  styleUrls: ["./file-export.component.css"],
})
export class FileExportComponent implements OnInit {
  @Input() feExpanded;
  @Output() minimize: EventEmitter<any[]> = new EventEmitter();

  FAILED = AppConstants.FAILED;
  IN_PROGRESS = AppConstants.IN_PROGRESS;
  STARTED = AppConstants.STARTED;
  FINISHED = AppConstants.FINISHED;

  exportedFiles: any[] = [];
  currentExportType: string[] = [];
  currentModuleExports = [];

  private intervalSubscription: Subscription;
  private exportSubscription: Subscription;

  constructor(
    private apiCallService: ApiCallService,
    private sharedDataService: SharedDataService,
    private router: Router,
    private http: HttpClient
  ) {
    this.exportSubscription = this.sharedDataService.exportSource.subscribe(
      (res) => {
        if (!isEqual(this.exportedFiles, res)) this.runTimer();
        this.exportedFiles = cloneDeep(res);
      }
    );
  }

  ngOnInit() {
    this.findExportType();
    if (!this.exportedFiles.length) this.fetchExportStatus(true);
  }

  ngOnChanges(changes) {
    this.feExpanded = changes.feExpanded.currentValue;
  }

  runTimer() {
    if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
    this.intervalSubscription = interval(3000)
      .pipe(takeWhile(() => this.isTimerAlive()))
      .subscribe(() => {
        this.fetchExportStatus(false);
      });
  }

  isTimerAlive() {
    const completedFiles = this.exportedFiles.filter((item) => {
      return item.status == this.FINISHED || item.status == this.FAILED;
    });
    return (
      this.exportedFiles.length > 0 &&
      completedFiles.length != this.exportedFiles.length
    );
  }

  fetchExportStatus(initialFetch) {
    this.apiCallService.processHttpRequest(
      "POST",
      "/api/v1/export_files/status",
      undefined,
      this.exportedFiles,
      (response) => {
        if (initialFetch) this.sharedDataService.updateExportSource(response);
        else {
          let filteredResponse = this.filterByModule(response);
          this.updateExportStatus(filteredResponse);
          this.sharedDataService.updateExportSource(this.exportedFiles);
        }
      },
      undefined
    );
  }

  updateExportStatus(files) {
    if (isEmpty(files)) this.exportedFiles = [];
    else {
      this.exportedFiles.forEach((element, index) => {
        const obj = find(files, ["fileExportId", element.fileExportId]);
        if (obj) this.exportedFiles[index] = obj;
      });
    }
  }

  filterByModule(list) {
    if (!list) return [];
    return list.filter((item) => {
      return this.currentExportType.includes(item.module);
    });
  }

  findExportType() {
    let url = this.router.url;
    if (url.includes(AppConstants.PORTAL)) {
      this.currentExportType[0] =
        AppConstants.SERVICEABILITY_PORTAL_ADDRESS_EXPORT;
    }
  }

  onMinimize(event) {
    event.preventDefault();
    event.stopPropagation();
    this.feExpanded = false;
    this.minimize.emit(event);
  }

  cancelFileExport(exportFile) {
    this.apiCallService.processHttpRequest(
      "POST",
      "/api/v1/export/cancel",
      undefined,
      exportFile,
      undefined,
      undefined
    );
  }

  showExportStatusWindow() {
    return this.filterByModule(this.exportedFiles).length > 0;
  }

  closePopup(event) {
    event.stopPropagation();
    if (!this.isTimerAlive()) {
      for (let i = 0; i < this.exportedFiles.length; i++) {
        this.removeExportedTrashFile(i);
      }
    }
  }

  removeExportedTrashFile(index) {
    let api = "/api/v1/export_files/" + this.exportedFiles[index].fileExportId;
    this.apiCallService.processHttpRequest(
      "DELETE",
      api,
      undefined,
      undefined,
      (response) => {
        this.exportedFiles.splice(index, 1);
        this.sharedDataService.updateExportSource(this.exportedFiles);
      },
      undefined
    );
  }

  downloadExportedFile(exportFile) {
    this.getExportFile(exportFile.fileName, exportFile.module).subscribe(
      (response) => {
        CommonUtil.downloadFile(
          response,
          'application/octet-stream',
          exportFile.fileName
        );
      }
    );
  }

  private getExportFile(fileName: string, module: string): Observable<any> {
    const params = { fileName: fileName, module: module };
    let headers = new HttpHeaders({
      Authorization: localStorage.getItem("token")
    });
    return this.http.get(
      `${environment.apiUrl}/api/v1/download/exportedfile`,
      {
        headers,
        params,
        responseType: 'arraybuffer'
      }
    );
  }

  ngOnDestroy() {
    this.exportedFiles = [];
    this.exportSubscription.unsubscribe();
    if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
  }
}
