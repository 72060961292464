import {Subscription} from 'rxjs';

/*
    Use this to unsubscribe to avoid memory leaks.
 */

export function unsubscribe(subscription: Subscription): void {
  if (subscription) {
    subscription.unsubscribe();
  }
}
