import { Injectable } from '@angular/core';

import { SharedApiCallService } from './shared-api-call.service';

import { Observable } from 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class ProvidersService {

  constructor(
    private sharedApiCallService: SharedApiCallService
  ) { }

  searchProviders(providerName?: string): Observable<any> {
    return this.sharedApiCallService.makeGetRequest(`/v1/providers${providerName ? `?provider_name=${providerName}` : ''}`)
  }
}
