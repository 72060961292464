import {map} from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { CommonUtil } from './../../shared/utils/common-util';
import { Injectable } from '@angular/core';
import { ApiCallService } from '../api-call.service';
import { SharedDataService } from './../shared-data.service';
import { environment } from 'environments/environment';
import { LookupIdentifiers } from './../../shared/lookup-identifiers';

@Injectable()
export class LookupService {

  constructor(public apiCallService: ApiCallService,
  public sharedDataService: SharedDataService) { }

  public getCountryOptions() {
    this.apiCallService.processHttpRequest('GET', '/data/country', undefined, undefined, (response => {
      let countryOptions = this.formatCountryOptions(response);
      this.sharedDataService.updateLookupSource( { Country: countryOptions } );
    }), undefined);
  }

  setStateOptions(stateOptions) {
    this.sharedDataService.updateLookupSource( { State: stateOptions } );
  }

  public retrieveStateOptions(countryId, callback) {
    this.apiCallService.processHttpRequest('POST', '/data/state', undefined, {countryid: countryId},
      (response => {
        let stateOptions = this.formatStateOptions(response);
        callback(stateOptions);
      }), undefined
    );
  }

  public formatCountryOptions(countries) {
    const arr = [];
    countries.forEach(function(item) {
      arr.push({id: item.countryID, text: item.countryName, code: item.countryAbbreviation});
    });
    return arr;
  }

  public formatStateOptions(states) {
    const arr = [];
    states.forEach(function(item) {
      arr.push({id: item.stateID, text: item.stateName, code: item.stateAbbreviation});
    });
    return arr;
  }

  public formatCityOptions(cities) {
    const arr = [];
    cities.forEach(function(item) {
      arr.push({id: item.cityID, text: item.cityName});
    });
    return arr;
  }

  public retriveLookupOptions(lookupType, callback) {
    this.apiCallService.processHttpRequest('POST', '/data/lookup', undefined, {lookuptype: lookupType},
      (response => {
      if(lookupType == LookupIdentifiers.CONNECTION_STATUS)
        response = response.filter(function(item) { return item.id != 3 && item.id != 4});
      else if(lookupType == LookupIdentifiers.ZONING)
        response = response.filter(function(item) { return item.id == 1 || item.id == 2});
      callback(lookupType, response);
      }), undefined
    );
  }
}
