import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import {map} from 'rxjs/operators';
import { MatDialogRef } from '@angular/material';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { ApiCallService } from '../../../services/api-call.service';
import { CommonUtil } from 'app/shared/utils/common-util';
import { CommonUtilityService } from './../../../services/common/common-utility.service';
import { PersistanceService } from './../../../services/persistence.service';

import * as fromStore from 'app/app.reducers';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  errorMsg: string = '';
  confirmPassword: string;
  currentPassword: string;
  passwordPattern = CommonUtil.PASSWORD_PATTERN;

  private accountInfo ;
  private userSubscription: Subscription;

  constructor(public dialogRef: MatDialogRef<ResetPasswordComponent>,
              public commonUtilityService: CommonUtilityService,
              private apiCallService: ApiCallService,
              private persistanceService: PersistanceService,
              private store: Store<fromStore.AppState>) {
    this.userSubscription = store.select(userState => userState['userFeature']['userData']).subscribe(userData => {
      this.accountInfo = userData.user_information ? userData.user_information.settings : {};
    });
  }

  ngOnInit() {
  }

  /**
   * Call api to reset password
   */
  resetPassword() {
    if(this.currentPassword != this.confirmPassword) {
      this.errorMsg = 'Passwords do not match';
      setTimeout(() => {
        this.errorMsg = '';
      }, 2000);
      return;
    }
    this.updatePassword();
  }

  updatePassword() {
    let api = environment.nodeApiUrl + '/v1/change-password';
    let payload = { newPassword: this.currentPassword, confirmPassword: this.currentPassword };

    this.apiCallService.processHttpRequest('POST', api, undefined, payload, (response => {
      this.commonUtilityService.openSnackBar('Password changed successfully');
      this.dialogRef.close();
      }),
      (error => {this.commonUtilityService.openSnackBar('Problem occurs')}))
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

}
