<div class="mx-btm-sheet-fill">
  <div fxLayout="row wrap" class="left-align">
    <mat-icon
      style="padding-left: 20px"
      mat-icon-button
      class="material-icons header-icon"
      matTooltip="Export address"
      (click)="exportAddress()"
    >
      file_download
    </mat-icon>
  </div>
  
  <div fxLayout="row wrap" class="right-align">
    <button
      mat-icon-button
      (click)="hideBottomSheet()"
    >
      <mat-icon class="pointer">close</mat-icon>
    </button>
  </div>
  <div fxLayout="row wrap" id="account_location_table" *ngIf="locationColumns.length"></div>
  <p-table #locationTable
    [columns]="locationColumns"
    [value]="rows"
    [paginator]="true"
    [rows]="payload.pageSize"
    [totalRecords]="totalCount" [resizableColumns]="true" columnResizeMode="expand">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th class="box-table">
          </th>
          <th *ngFor="let col of columns" pResizableColumn class="column_prop" [ngClass]="{'w-3':(col.field === 'priceQuote' || col.field === 'requestInfo'), 'w-10': (col.field === 'state' || col.field === 'zipcode' || col.field === 'country'), 'w-17' : (col.field === 'networkConnectionStatus' || col.field === 'buildingConnectionStatus')}">
              {{col.title}}
          </th>
        </tr>
        <tr>
          <th class="box-table">
              <div class="icon-trigger">
                <i matTooltip="Clear All Filters" class="material-icons icon_click" (click)="clearAllFilters()">highlight_off</i>
              </div>
            </th>
          <th *ngFor="let col of columns" [ngSwitch]="col.field" pReorderableColumn>
            <select [value]="tableUtilityService.setFilterValue(locationTable, col.filter)" [(ngModel)]="filter.networkConnectionStatus"
            class="filter_element_width select_filter" *ngSwitchCase="'networkConnectionStatus'" (change)="tableUtilityService.filterRecord(locationTable, $event.target.value, col.field)">
              <option selected="selected" value="">Filter</option>
              <option *ngFor="let item of nwConnOptions" [value]="item.text">{{ item.text }}</option>
            </select>

            <select [value]="tableUtilityService.setFilterValue(locationTable, col.filter)" [(ngModel)]="filter.buildingConnectionStatus"
            class="filter_element_width select_filter" *ngSwitchCase="'buildingConnectionStatus'" (change)="tableUtilityService.filterRecord(locationTable, $event.target.value, col.field)">
              <option selected="selected" value="">Filter</option>
              <option *ngFor="let item of buildingConnOptions" [value]="item.text">{{ item.text }}</option>
            </select>

            <select [value]="tableUtilityService.setFilterValue(locationTable, col.filter)" [(ngModel)]="filter.mediaType"
            class="filter_element_width select_filter" *ngSwitchCase="'mediaType'" (change)="tableUtilityService.filterRecord(locationTable, $event.target.value, col.field, 'contains')">
              <option selected="selected" value="">Filter</option>
              <option *ngFor="let item of accessMediums" [value]="item.text">{{ item.text }}</option>
            </select>

            <div *ngSwitchCase="'priceQuote'"></div>
            <div *ngSwitchCase="'requestInfo'"></div>
            <div *ngSwitchCase="'found'"></div>

            <input class = "filter_element_width" [(ngModel)]="filter.address"
            pInputText type="text"  *ngSwitchCase="'address'" (input)="tableUtilityService.filterRecord(locationTable, $event.target.value, col.field, 'contains')" placeholder="Filter">
            <input class = "filter_element_width" [(ngModel)]="filter.city"
            pInputText type="text"  *ngSwitchCase="'city'" (input)="tableUtilityService.filterRecord(locationTable, $event.target.value, col.field, 'contains')" placeholder="Filter">
            <input class = "filter_element_width" [(ngModel)]="filter.state"
            pInputText type="text"  *ngSwitchCase="'state'" (input)="tableUtilityService.filterRecord(locationTable, $event.target.value, col.field, 'contains')" placeholder="Filter">
            <input class = "filter_element_width" [(ngModel)]="filter.zipcode"
            pInputText type="text"  *ngSwitchCase="'zipcode'" (input)="tableUtilityService.filterRecord(locationTable, $event.target.value, col.field, 'contains')" placeholder="Filter">
            <input class = "filter_element_width" [(ngModel)]="filter.country"
            pInputText type="text"  *ngSwitchCase="'country'" (input)="tableUtilityService.filterRecord(locationTable, $event.target.value, col.field, 'contains')" placeholder="Filter">

            <input class = "filter_element_width" pInputText type="text" *ngSwitchDefault (input)="tableUtilityService.filterRecord(locationTable, $event.target.value, col.field, 'contains')" placeholder="Filter">

          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row let-columns="columns">

        <tr [pSelectableRow]="row" id="custom_mx_intersection">
            <th class="box-table">
              </th>
          <td *ngFor="let col of columns" [ngSwitch]="col.field" class="ui-resizable-column column_data_prop">
            <div *ngSwitchCase="'priceQuote'">
              <div *ngIf="!accountInfo.is_att_portal && row.buildingConnectionStatus" matTooltip="{{getImportStatus(row)}}" (click)="showQuickQuote(row)"><i class="fa fa-usd fa-lg" aria-hidden="true"></i></div>
              <div *ngIf="accountInfo.is_att_portal" matTooltip="{{getImportStatus(row)}}" (click)="(accountInfo.is_att_portal && row.pricingTier == undefined) ? $event.stopPropagation() : showQuickQuote(row);" [ngClass]="calculateClasses(row)"><i class="fa fa-usd fa-lg" aria-hidden="true"></i></div>
            </div>
            <div *ngSwitchCase="'requestInfo'" (click)="(accountInfo.is_att_portal && row.pricingTier == undefined) ? $event.stopPropagation() : sendRequestInfoMailFromImport(row);" [ngClass]="{'disabled': (accountInfo.is_att_portal && row.pricingTier == undefined)}"><i class="fa fa-info-circle fa-lg" aria-hidden="true"></i></div>
            <div *ngSwitchCase="'found'" class="text-center">
              <mat-icon class="status-icon" [ngClass]="(tableUtilityService.getCellData(row, col) > 0) ? 'green' : 'red'">
                {{ tableUtilityService.getCellData(row, col) > 0 ? 'check_circle_outline' : 'highlight_off' }}
              </mat-icon>
            </div>
            <div *ngSwitchCase="'mediaType'" title="{{tableUtilityService.getCellData(row, col)}}">
              {{ setMediaType(row, col) }}</div>
            <div *ngSwitchDefault class="lable_restrict" title="{{ tableUtilityService.getCellData(row, col) }}">{{ tableUtilityService.getCellData(row, col) }}</div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="paginatorleft" let-state>
          <div class="paginator_left" *ngIf="locationTable.totalRecords>0">
              {{ tableUtilityService.getPaginatorContent(payload.start+1, payload.pageSize, locationTable.totalRecords) }}
              address results.
          </div>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
          <tr *ngIf="bottomSheetData.showData && totalCount==0">
            <div class="text-center data_not_found">No Results Found</div>
          </tr>
      </ng-template>
  </p-table>
  <div class="backdrop" *ngIf="!bottomSheetData.showData">
      <div class="flex-box custom-loader isn-loader-location"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
  </div>
</div>

<div class="pad-10 main-export-import-con">
  <span
    *ngIf="!feExpanded"
    (click)="feExpanded = true"
    class="fie-icon default"
    ><i class="fa fa-info-circle fa-2x"></i
  ></span>
  <div flex [hidden]="!exportedFiles.length">
    <app-file-export
      [feExpanded]="feExpanded"
      (minimize)="minimizeExportPopup()"
    ></app-file-export>
  </div>
</div>
