<div class="flex-btw">
  <h3 mat-dialog-title>About</h3>
</div>

<div mat-dialog-content>
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
    <div>
        Version : {{ appVersion }}
    </div>
    <div>
        &copy; {{ currentYear }} <a href="https://www.connectbase.com/" target="_blank">Connectbase, Inc</a> All rights reserved.
    </div>
    <div>
        <a href="https://www.connectbase.com/privacy-policy/" target="_blank">Privacy Policy</a> | <a href="https://www.connectbase.com/terms/" target="_blank">Terms of Use</a>
    </div>
  </div>
</div>