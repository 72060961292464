import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private _selectedClientCompany;
  private _selectedCompanySource: BehaviorSubject<any> = new BehaviorSubject(this._selectedClientCompany);

  constructor() { }

  get selectedCompanySource() {
    return this._selectedCompanySource;
  }
  updateSelectedCompanySource(newValue) {
    this._selectedCompanySource.next(newValue);
  }
  resetSelectedCompanySource() {
    this._selectedClientCompany = undefined;
    this._selectedCompanySource = new BehaviorSubject(this._selectedClientCompany);
  }
  resetValues() {
    this.resetSelectedCompanySource();
  }
}
