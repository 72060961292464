import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import {CalendarModule} from 'primeng/calendar';
import { MaterialModule } from '../../material.module';
import {TableModule} from 'primeng/table';
import {MultiSelectModule} from 'primeng/multiselect';
import {InputTextModule} from 'primeng/inputtext';
import {DropdownModule} from 'primeng/dropdown';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import {FileUploadModule} from 'primeng/fileupload';
import { LinkyModule } from 'ngx-linky';

import { HeaderComponent } from './header/header.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { AddressAutocompleteComponent } from './address-autocomplete/address-autocomplete.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from 'app/app.reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from './../../../environments/environment';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { AboutComponent } from './about/about.component';
import { CommonConfirmationComponent } from './common-confirmation/common-confirmation.component';

// Pipes
import { CallbackPipe } from 'app/pipes/callback.pipe';
// Directives
import { AutofocusDirective } from 'app/directives/auto_focus.directive';
import { NgxInitDirective } from './../../directives/ngx-init.directive';
import { NumberHavingCommas, RestrictCommas } from 'app/directives/common.directive';
import { CheckAccessDirective } from './../../directives/check-access.directive';
import { TwoDigitDecimalDirective } from './../../directives/two-digit-decimal.directive';
import { FileExportComponent } from './file-export/file-export.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    CalendarModule,
    MaterialModule,
    TableModule,
    MultiSelectModule,
    InputTextModule,
    DropdownModule,
    RouterModule,
    FlexLayoutModule,
    FileUploadModule,
    LinkyModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : []
  ],
  declarations: [
    HeaderComponent,
    MainNavComponent,
    AddressAutocompleteComponent,
    FeedbackComponent,
    CallbackPipe,
    ResetPasswordComponent,
    UserDetailComponent,
    AboutComponent,
    CommonConfirmationComponent,
    AutofocusDirective,
    NgxInitDirective,
    TwoDigitDecimalDirective,
    NumberHavingCommas,
    RestrictCommas,
    CheckAccessDirective,
    FileExportComponent
  ],
  entryComponents: [
   FeedbackComponent,
   ResetPasswordComponent,
   UserDetailComponent,
   AboutComponent
  ],
  exports: [
    HeaderComponent,
    MainNavComponent,
    AddressAutocompleteComponent,
    FeedbackComponent,
    ResetPasswordComponent,
    UserDetailComponent,
    AboutComponent,
    CommonConfirmationComponent,
    CallbackPipe,
    AutofocusDirective,
    NgxInitDirective,
    TwoDigitDecimalDirective,
    NumberHavingCommas,
    RestrictCommas,
    CheckAccessDirective,
    FileExportComponent
  ]
})
export class SharedModule { }
