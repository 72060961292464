import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-address-import-modal',
  templateUrl: './address-import-modal.component.html',
  styleUrls: ['./address-import-modal.component.scss']
})
export class AddressImportModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    private _dialogRef: MatDialogRef<AddressImportModalComponent>,
  ) {}

  ngOnInit() {

  }

  closeDialog($event) {
    this._dialogRef.close($event);
  }
}
