
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {SharedApiCallService} from '../shared-api-call.service';

import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class AutocompleteService {

  constructor(
      private sharedApiCallService: SharedApiCallService,
      private http: HttpClient
  ) {}

  autocompleteCompanyName(name: string): Observable<any> {
    return this.sharedApiCallService.makePostRequest(`/v1/company-autocomplete`, {name: name}).pipe(
      map(res => res))
  }

  autocompleteLocationsMapbox(query: string): Observable<any> {
    // Changing endpoint from mapbox.places-permanent to mapbox.places
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${environment.mapboxKey}`;

    return this.http.get(url);
  }

  getUsersByCompany(name: string, companyId: number): Observable<any> {
    return this.sharedApiCallService.makeGetRequest(`/v1/company-autocomplete/users?name=${name}&companyId=${companyId}`).pipe(
      map(res => res.data))
  }

  getManagerInfo(id, companyId): Observable<any> {
    return this.sharedApiCallService.makeGetRequest(`/v1/company-autocomplete/managers?managerIds=${id}&companyId=${companyId}`).pipe(
      map(res => res.data))
  }

}
