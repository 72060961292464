export class AppConstants {
    public static PRICING_DISTRIBUTION: number = 8;
    public static CLICK_EVENT: string = 'click';
    public static OTHER_EVENT: string = 'other';
    public static NETWORK_RADAR_MARGIN_LABEL: string = ' (Network Radar)';
    public static INTERNAL_API_LABEL: string = ' (Internal API)';
    public static EXTERNAL_API_LABEL: string = ' (External API)';
    public static CUSTOM_PRICING_ZONE_GEOGRAPHY: number = 9;
    public static BOUNDARY_GEOGRAPHY: number = 10;
    public static ETHERNET_SWITCHED: string = 'Ethernet - Switched';
    public static DEDICATED_INTERNET: string = 'Dedicated Internet';
    public static BROADBAND: string = 'Broadband';
    public static FAILED: string = 'FAILED';
    public static IN_PROGRESS: string = 'INPROGRESS';
    public static STARTED: string = 'STARTED';
    public static FINISHED: string = 'FINISHED';
    public static PORTAL: string = 'portal';
    public static SERVICEABILITY_PORTAL_ADDRESS_EXPORT: string = 'SERVICEABILITY_PORTAL_ADDRESS_EXPORT';
    public static PORTAL_REQUEST_TYPE_ID: number = 8;
}
