<div class="lds-ellipsis" *ngIf="loadingInfo"><div></div><div></div><div></div></div>
<mat-tab-group class="buildings_tap" *ngIf="!isEmpty()">
    <mat-tab label="Building Information">
      <ng-template matTabContent>
        <div fxLayout="row" class="wrap_row flex building_wrapper">
          <div *ngIf="!accountInfo.is_att_portal" class="holder w-100">
            <h5>Building Name</h5>
            <p>{{buildingInfo.buildingname}}</p>
          </div>
          <div class="holder">
            <h5>Street Address</h5>
            <p>{{buildingInfo.location.street}}</p>
          </div>
          <div class="holder">
            <h5>City</h5>
            <p>{{buildingInfo.location.city.text}}</p>
          </div>
          <div class="holder">
            <h5>State</h5>
            <p>{{buildingInfo.location.state.text}}</p>
          </div>
          <div class="holder">
            <h5>Postal</h5>
            <p>{{buildingInfo.location.postal}}</p>
          </div>
          <div class="holder">
            <h5>Country</h5>
            <p>{{buildingInfo.location.country.text}}</p>
          </div>
          <div *ngIf="!accountInfo.is_att_portal" class="holder">
            <h5>MSA Name</h5>
            <p>{{buildingInfo.location.msaname}}</p>
          </div>
          <div class="holder">
            <h5>Latitude</h5>
            <p>{{buildingInfo.location.latitude}}</p>
          </div>
          <div class="holder">
            <h5>Longitude</h5>
            <p>{{buildingInfo.location.longitude}}</p>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Products" *ngIf="false">
      <ng-template matTabContent>
        <div *ngIf="serviceNames.length" class="building_wrapper">
          <div fxLayout="column" *ngFor="let serviceName of serviceNames">
            <p>{{ serviceName }}</p>
          </div>
        </div>
        <div *ngIf="!serviceNames.length" class="building_wrapper">
          <p> No Products Available </p>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Building Reference">
      <ng-template matTabContent>
        <div fxLayout="row" class="wrap_row building-manager-info flex building_wrapper">
          <div *ngIf="!accountInfo.is_att_portal" class="holder">
            <h5>Building Category</h5>
            <p>{{buildingInfo.buildingCategory && buildingInfo.buildingCategory.length > 0 ? concatItem(buildingInfo.buildingCategory, 'text') : ''}}</p>
          </div>
          <div *ngIf="!accountInfo.is_att_portal" class="holder">
            <h5>CB Building Number</h5>
            <p>{{buildingInfo.c2fbuildingnumber}}</p>
          </div>
          <div *ngIf="!accountInfo.is_att_portal" class="holder">
            <h5>Network Build Status</h5>
            <p>{{buildingInfo.networkBuildStatus ? buildingInfo.networkBuildStatus.text : ''}}</p>
          </div>
          <div class="holder">
            <h5>Building Connection Status</h5>
            <p>{{buildingInfo.buildingConnectionStatus ? buildingInfo.buildingConnectionStatus.text : ''}}</p>
          </div>
          <div *ngIf="!accountInfo.is_att_portal" class="holder">
            <h5>Fiber Ownership</h5>
            <p>{{buildingInfo.fiberOwnership ? buildingInfo.fiberOwnership.text : ''}}</p>
          </div>
          <div *ngIf="!accountInfo.is_att_portal" class="holder">
            <h5>Install Interval</h5>
            <p>{{buildingInfo.installIntervalValue ? buildingInfo.installIntervalValue : 0}}</p>
          </div>
          <div *ngIf="!accountInfo.is_att_portal" class="holder">
            <h5>Demarc Code</h5>
            <p>{{buildingInfo.demarccode}}</p>
          </div>
          <div class="holder">
            <h5>SWC CLLI Code</h5>
            <p>{{buildingInfo.cllicode}}</p>
          </div>
          <div *ngIf="!accountInfo.is_att_portal" class="holder">
            <h5>Building CLLI</h5>
            <p>{{buildingInfo.buildingClli}}</p>
          </div>
          <div class="holder">
            <h5>NPA</h5>
            <p>{{buildingInfo.npa}}</p>
          </div>
          <div class="holder">
            <h5>NXX</h5>
            <p>{{buildingInfo.nxx}}</p>
          </div>
          <div *ngIf="!accountInfo.is_att_portal" class="holder">
            <h5>Special Construction Cost ($)</h5>
            <p>{{buildingInfo.specialConstructionCost}}</p>
          </div>
          <div *ngIf="!accountInfo.is_att_portal"  class="holder">
            <h5>Supplier Name</h5>
            <p>{{accountInfo.company_name ? accountInfo.company_name : ''}}</p>
          </div>
          <div *ngIf="accountInfo.is_att_portal"  class="holder">
            <h5>Supplier Name</h5>
            <p>{{buildingInfo.supplierName}}</p>
          </div>
          <div class="holder">
            <h5>Access Medium</h5>
            <p>{{buildingInfo.accessMediums && buildingInfo.accessMediums.length > 0 ? concatItem(buildingInfo.accessMediums, 'text') : ''}}</p>
          </div>
        </div>
      </ng-template>
    </mat-tab>
</mat-tab-group>
