import { environment } from './../../../environments/environment';
const USE_INTERACTION_CODE = false;

export default {
  oidc: {
    clientId: `${environment.oktaClientId}`,
    issuer: `${environment.oktaIssuer}`,
    redirectUri: `${environment.oktaRedirectURL}`,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    useInteractionCodeFlow: `${USE_INTERACTION_CODE}`,
    callBackUrl: `${environment.oktaCallBackUrl}`,
  },
  resourceServer: {
    messagesUrl: '',
  },
};
