<div class="login-page-wrapper">
  <div class="upper-logo-image">
    <img src="../../../../../assets/images/logo-white.png" alt="" width="200px">
  </div>
  <div class="box-wrapper">
    <div class="login-card">
      <div class="login-form">
        <div class="title">Terms & Conditions</div>
        <div class="terms" [innerHTML]="termsconditionTemplate"></div>
      </div>
      <div style="padding-top: 20px; padding-bottom: 20px; text-align: center;">
        <div *ngIf="!hideAcceptDecline">
          <mat-checkbox class="mr-1" style="padding-right: 200px" decicolor="primary" color="primary" [(ngModel)]="decline"
            (ngModelChange)="declineoraccpet()">
            Decline & Exit
          </mat-checkbox>

          <mat-checkbox class="mr-1" color="primary" color="primary" [(ngModel)]="termsAccepted"
            (ngModelChange)="declineoraccpet()">
            Accept & Continue
          </mat-checkbox>
        </div>
        <div *ngIf="termsAccepted">
          <button class="cancel-bt bt-style" mat-raised-button color="primary" style="margin-right: 150px;" type="submit" (click)="onCancel()">Cancel</button>
          <button class="accept-bt bt-style" mat-raised-button color="primary" type="submit" (click)="onAgreeTermsCond()">I Agree</button>
        </div>
      </div>
    </div>
  </div>
</div>