import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { BuildingLookupService } from './building-lookup.service';
import { ApiCallService } from 'app/services/api-call.service';
import { SharedDataService } from './../shared-data.service';

import * as fromStore from 'app/app.reducers';
import { LookupOptions } from 'app/shared/components/store/shared-data.actions';

@Injectable()
export class CWLookupService extends BuildingLookupService {

  constructor(apiCallService: ApiCallService,
      sharedDataService: SharedDataService,
      store: Store<fromStore.AppState>) {
    super(apiCallService, sharedDataService, store);
  }

  public getNRSuppliers() {
    this.apiCallService.processHttpRequest('GET', '/api/v1/receivers/suppliers/published', undefined, undefined,
      (response => {
        this.sharedDataService.updateCWSource( {nr_supplier: response.suppliers} )
      }), undefined);
  }

  public retrieveRoutes(isOwn, isPublished, optionType, callback) {
    // let obj = { start : 0, pagesize : 10, isFav : false, ownRoute : isOwn, isPublished: isPublished };
    let obj = { start : 0, pagesize : 10, isFav : false, ownRoute : false, portalVisible: true };
    this.apiCallService.processHttpRequest('POST', '/api/v1/data/route/kmllist', undefined, obj, (response => {
      // let routes = {};
      // routes[optionType] = response['routeList'];
      // this.sharedDataService.updateCWSource(routes);
      if(callback)
        callback(response['routeList'], optionType);
    }), undefined);
  }

  public getCircuitResources() {
    this.apiCallService.processHttpRequest('GET', '/api/v1/circuit_views/properties', undefined, undefined,
      (response => {
        this.sharedDataService.updateCWSource(response);
      }), undefined);
  }


}
