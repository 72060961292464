import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './material.module';
import { AppRoutingModule } from './app-routing.module';
import { PapaParseModule } from 'ngx-papaparse';
import { DatePipe } from '@angular/common';
// import {APP_BASE_HREF} from '@angular/common';

/* Application Modules */
import { ConnectedWorldModule } from './components/connected-world/connected-world.module';
import { SharedModule } from './shared/components/shared.module';
import { SharedComponentsModule } from './modules/shared-components/shared-components.module';

/* Common Sub Modules */
import { AuthenticationModule } from './modules/authentication/authentication.module';

/* App Components */
import { AppComponent } from './app-component/app.component';
/* Services Start */

import { ApiCallService } from './services/api-call.service';

// Common
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { LookupService } from './services/lookup/lookup.service';
import { CompanyLookupService } from './services/lookup/company-lookup.service';
import { BuildingLookupService } from './services/lookup/building-lookup.service';
import { AuthErrorService } from './services/auth-error/auth-error.service';
import { PersistanceService } from './services/persistence.service';
import { BuildingManagerLookupService } from './services/lookup/building-manager-lookup.service';
import { CWLookupService } from './services/lookup/cw-lookup.service';
import { SharedDataService } from './services/shared-data.service';
import { BottomSheetService } from './services/bottom-sheet.service';
import { CWService } from './services/cw.service';
import { TableUtilityService } from './services/table-utility/table-utility.service';
import { CommonUtilityService } from './services/common/common-utility.service';
import { HttpConfigInterceptor } from './interceptor/http-config.interceptor';
import { BuildingService } from './services/building/building.service';
import { WindowRefService } from './services/inline-manual/window-ref.service';
import { InlineService } from './services/inline-manual/inline-service';
import { HeaderService } from './services/header.service';
import { LocationSearchService } from './services/location-search/location-search.service';
/* Services End */

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers, metaReducers } from './app.reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';

/* Effects Start */
import { AdjacentBusinessesEffects } from './modules/shared-components/store/adjacentBusinesses.effects';
/* Effects End */

import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule } from '@angular/material';
import { InfoService } from './services/info.service';
import { AccountModule } from './components/account/account.module';
import { TermsConditionComponent } from './components/terms-condition/terms-condition.component';

@NgModule({
  declarations: [
    AppComponent,
    TermsConditionComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MaterialModule,
    AppRoutingModule,
    PapaParseModule,
    ConnectedWorldModule,
    SharedModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([
      AdjacentBusinessesEffects
    ]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    AuthenticationModule.forRoot(),
    SharedComponentsModule.forRoot(),
    AccountModule
  ],
  providers: [
    ApiCallService,
    LookupService,
    CompanyLookupService,
    BuildingLookupService,
    AuthErrorService,
    AuthGuardService,
    PersistanceService,
    BuildingManagerLookupService,
    CWLookupService,
    SharedDataService,
    BottomSheetService,
    CWService,
    TableUtilityService,
    CommonUtilityService,
    InfoService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    BuildingService,
    WindowRefService,
    InlineService,
    HeaderService,
    LocationSearchService
    // {provide: APP_BASE_HREF, useValue: window['base-href']}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

