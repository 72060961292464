export class LookupIdentifiers {
  public static NETWORK_STATUS = 'FiberConnected';
  public static CONNECTION_STATUS = 'BuildingConnectivity';
  public static FIBER_OWNERSHIP = 'FiberOwnership';
  public static BUILDING_CATEGORY = 'locationTypeRef';
  public static ACCESS_MEDIUM = 'AccessMedium';
  public static COUNTRY = "Country";
  public static STATE = "State";
  public static INSTALL_INTERVAL = "INSTALL_INTERVAL";
  public static PROVIDER = "PROVIDER";
  public static HANDOFF_TYPE = "HANDOFF_TYPE";
  public static WAVE_HANDOFF_TYPE = "WAVE_HANDOFF_TYPE";
  public static PROTECTION_TYPE = "PROTECTION_TYPE";
  public static DISTANCE_BAND = "DISTANCE_BAND";
  public static DIVERSITY_TYPE = "DIVERSITY_TYPE";
  public static COMPETITIVE_RATING = "competitiveRating";
  public static PRICING_LOCATION_TYPE = "PRICING_LOCATION_TYPE";

  public static PROPERTY_TYPE = "PropertyType";
  public static SUB_TYPE = "Subtype";
  public static ADDITIONAL_SUB_TYPE = "AdditionalSubtype";
  public static BUILDING_CLASS = "BuildingClass";
  public static EASEMENT_STATUS = "BAAStatus";
  public static RISER_OWNER = "RiserOwner";
  public static CABLE_TYPE = "DataCenterCrossConnect";
  public static DEMARC_POINT = "DemarcPoint";
  public static DESIGNATION = "DemarcDesignation";

  public static ROUTE_STATUS = 'Route_Status';

  public static CONTACT_CATEGORY = 'ContactCategory';
  public static FREQUENCY = "Frequency";
  public static ZONING = "Zoning";
  public static BUILDING_EXPORT_FORMAT = "Exportbuildingformat";

  public static  LOCATION_TYPE_GROUP = "locationTypeGroup";

  public static DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

  /* Map Filter History  - Start */

  public static BUILDING_MODULE_ID = 1;
  public static SUB_MODULE_BUILDING_CATEGORY = 1;
  public static SUB_MODULE_BUILDING_CONNECTIVITY = 6;
  public static MARKET_LOCATION_MODULE_ID = 2;
  public static SUB_MODULE_LOCATIONTYPE = 3;
  public static SUB_MODULE_LOCATIONSUBTYPE = 7;
  public static SUB_MODULE_ZONING = 2;
  public static MYROUTES_MODULE_ID = 4;
  public static SUB_MODULE_SAVED_ROUTES = 4;
  public static SUB_MODULE_COMPANY_ROUTES = 5;
  public static SUB_MODULE_PUBLISHED_ROUTES = 8;
  public static SUB_MODULE_DISTANCE_BAND = 9;
  public static SUB_MODULE_SUPPLIER = 10;
  public static SUB_MODULE_SUPPLIER_CATEGORY = 11;
  public static SUB_MODULE_SUPPLIER_CONNECTIVITY = 12;
  public static CIRCUIT_MODULE_ID = 5;
  public static SUB_MODULE_CIRCUIT_SUPPLIER = 13;
  public static SUB_MODULE_CIRCUIT_PRODUCT = 14;
  public static SUB_MODULE_CIRCUIT_TECH = 15;
  public static SUB_MODULE_CIRCUIT_SPEED = 18;
  public static SUB_MODULE_CIRCUIT_MRC = 19;
  public static SUB_MODULE_ROUTE_STATUS = 16;
  public static SUB_MODULE_ROUTE_OWNERSHIP = 17;
  public static MD_STATE = 20;
  public static MD_CITY = 21;
  public static SUB_MODULE_COMPETITIVE_RATING = 22;
  public static SUB_MODULE_ACCEESS_MEDIUM = 23;
  public static SUB_MODULE_NETWORK_CONNECTION_STATUS = 24;
  public static SUB_MODULE_SUPPLIER_CONNECTION_STATUS = 25;
  public static SUB_MODULE_CUSTOM_FIELD_VALUES = 26;
  public static MARKET_EXPLORER_MODULE_ID = 6;
  public static SUB_MODULE_MX_LOCATION_TYPE = 27;
  public static SUB_MODULE_MX_LOCATION_SUB_TYPE = 28;
  public static SUB_MODULE_MX_STATE = 29;
  public static SUB_MODULE_MX_CITY = 30;
  public static SUB_MODULE_MX_BUILDING_CONNECTION_STATUS = 31;
  public static SUB_MODULE_MX_COMPETITIVE_RATING = 32;
  public static SUB_MODULE_MX_MSA = 33;
  public static SUB_MODULE_MX_INCLUDE_MARKET_DATA = 34;
  public static SUB_MODULE_MX_MINIMUM_TENANTS = 35;
  public static SUB_MODULE_MX_REVENUE = 36;
  public static SUB_MODULE_MX_NETWORK_SPEND = 37;
  public static SUB_MODULE_MX_EMPLOYEES = 38;
  public static SUB_MODULE_MX_HEAD_QUARTERS = 39;
  public static SUB_MODULE_MX_BUSINESS_NAME = 40;
  public static SUB_MODULE_MX_PROXIMITY_RADIUS = 43;
  public static SUB_MODULE_MX_PROXIMITY_ADDRESS = 41;
  public static SUB_MODULE_MX_PROXIMITY_COORDINATES = 42;
  public static SUB_MODULE_MX_PROXIMITY_ROUTE_ID = 47;
  public static MY_LAYER_MODULE_ID = 13;
  public static SUB_MODULE_INDUSTRY_LAYER = 44;
  public static SUB_MODULE_SHARED_LAYER = 45;
  public static SUB_MODULE_OWN_LAYER = 46;

  /* Map Filter History - End */
}
