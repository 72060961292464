import { Injectable } from '@angular/core';
import { ApiCallService } from 'app/services/api-call.service';

@Injectable()
export class BuildingManagerLookupService {

  propertyTypeOptions: Array<object> = [];
  subTypeOptions: Array<object> = [];
  additionalSubTypeOptions: Array<object> = [];
  buildingClassOptions: Array<object> = [];
  easementStatusOptions: Array<object> = [];
  riserOwnerOptions: Array<object> = [];
  cableTypeOptions: Array<object> = [];
  demarcPointOptions: Array<object> = [];
  designationOptions: Array<object> = [];
  
  public getPropertyTypeOptions() {
    return this.propertyTypeOptions;
  }
  public setPropertyTypeOptions(propertyTypeOptions) {
    this.propertyTypeOptions = propertyTypeOptions;
  }

  public getSubTypeOptions() {
    return this.subTypeOptions;
  }
  public setSubTypeOptions(subTypeOptions) {
    this.subTypeOptions = subTypeOptions;
  }

  public getAdditionalSubTypeOptions() {
    return this.additionalSubTypeOptions;
  }
  public setAdditionalSubTypeOptions(additionalSubTypeOptions) {
    this.additionalSubTypeOptions = additionalSubTypeOptions;
  }

  public getBuildingClassOptions() {
    return this.buildingClassOptions;
  }
  public setBuildingClassOptions(buildingClassOptions) {
    this.buildingClassOptions = buildingClassOptions;
  }

  public getEasementStatusOptions() {
    return this.easementStatusOptions;
  }
  public setEasementStatusOptions(easementStatusOptions) {
    this.easementStatusOptions = easementStatusOptions;
  }

  public getRiserOwnerOptions() {
    return this.riserOwnerOptions;
  }
  public setRiserOwnerOptions(riserOwnerOptions) {
    this.riserOwnerOptions = riserOwnerOptions;
  }

  public getCableTypeOptions() {
    return this.cableTypeOptions;
  }
  public setCableTypeOptions(cableTypeOptions) {
    this.cableTypeOptions = cableTypeOptions;
  }

  public getDemarcPointOptions() {
    return this.demarcPointOptions;
  }
  public setDemarcPointOptions(demarcPointOptions) {
    this.demarcPointOptions = demarcPointOptions;
  }

  public getDesignationOptions() {
    return this.designationOptions;
  }
  public setDesignationOptions(designationOptions) {
    this.designationOptions = designationOptions;
  }

  

  


}
