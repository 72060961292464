import { Component, OnInit, ViewChild, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar, MatDialog, MatSlideToggleChange } from '@angular/material';
import { FormControl } from '@angular/forms';

import { Subscription, forkJoin } from 'rxjs';

import { Papa } from 'ngx-papaparse';
import { saveAs as importedSaveAs} from 'file-saver';
import * as jsonexport from 'jsonexport/dist';
import { sortBy } from 'lodash';
import { AutocompleteService } from 'app/modules/shared-components/services/autocomplete/autocomplete.service';
import { SnackbarService } from 'app/modules/shared-components/services/snackbar.service';
import { AddressService } from 'app/modules/shared-components/services/address.service';
import { FormatAddress } from 'app/modules/shared-components/utils/formatAddress.func';
import { Address } from 'app/modules/shared-components/models/address.model';
import { unsubscribe } from 'app/modules/shared-components/utils/unsubscribe.func';
import { ImportDetailsComponent } from 'app/modules/shared-components/components/import-details/import-details.component';
import { Store } from '@ngrx/store';
import * as fromStore from 'app/app.reducers';
import { ConnectedWorldUtil } from 'app/components/connected-world/connected-world-util';

@Component({
  selector: 'app-address-import',
  templateUrl: './address-import.component.html',
  styleUrls: ['./address-import.component.scss']
})
export class AddressImportComponent implements OnInit, OnDestroy {
  @Input() passedData: any;
  @Output() onImportEnd = new EventEmitter<any>();
  @ViewChild('addressImportTable') table: any;
  @ViewChild('fileInput') fileInput: any;
  addressesInput = new FormControl();
  searchControl = new FormControl();
  searchControlA = new FormControl();
  searchControlZ = new FormControl();
  temp: any[] = [];
  locationsForDeal: any[] = [];
  selectedRows = [];
  zIndexes: number[] = [];
  limit: number;
  pageNumber = 0;
  validationSpinner = false;
  locationOptions: any = [];
  locationAOptions: any = [];
  locationZOptions: any = [];
  optionsTypes: any[] = [
    { optionsName: 'locationOptions', controlName: 'searchControl' },
    { optionsName: 'locationAOptions', controlName: 'searchControlA' },
    { optionsName: 'locationZOptions', controlName: 'searchControlZ' },
  ];
  sampleData: Address[];
  address_col_filter = '';
  city_col_filter = '';
  state_col_filter = '';
  zipcode_col_filter = '';
  provider_col_filter = '';
  country_col_filter = '';
  unique_key_col_filter = '';
  media_type_col_filter = '';
  excludeInvalidAddresses = false;
  addressLoading = false;
  invalidAddresses = [];
  importRequiredFields = ['address', 'city', 'state', 'country'];
  importLoading = false;
  isP2PMode = false;
  csvSchema: any[] = [];
  parsedSearchAddress = '';

  private _subscribers: Subscription = new Subscription();
  private _accountDataSub: Subscription;
  private userSubscription: Subscription;
  private accountInfo;

  constructor(
    private _addressService: AddressService,
    private _autocompleteService: AutocompleteService,
    private _papa: Papa,
    private _snackbarService: SnackbarService,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private store: Store<fromStore.AppState>) {
      this.userSubscription = store.select(userState => userState['userFeature']['userData']).subscribe(userData => {
          this.accountInfo = userData;
      });
  }

  ngOnInit() {
    this.optionsTypes.forEach(type => {
      this._subscribers.add(this[type.controlName].valueChanges.subscribe(value => {
        if (value) {
          this._autocompleteService.autocompleteLocationsMapbox(value).subscribe(
            res => {
              if (res) { this[type.optionsName] = res.features; }
            });
        } else {
          this[type.optionsName] = [];
        }
      }));
    });
  }

  getRowClass(row) {
    const classes = {
      'invalid-address-row': row.found === 0,
      'z-location-row': row.zLocation,
    };

    return classes;
  }

  onModeChange({ checked }: MatSlideToggleChange) {
    this.optionsTypes.forEach(type => this[type.controlName].setValue(null));
  }

  validateAddresses() {
    this.validationSpinner = true;
    this.zIndexes = [];
    let addresses = [];
    addresses = [
      this.parsedSearchAddress,
      ...(this.addressesInput.value ? this.addressesInput.value.split(/\n/) : [])
    ].filter(Boolean);
    if (addresses.length) {
         this.standardizeAddressesAsStrings(addresses);
     } else {
      this.validationSpinner = false;
    }
  }

  onFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>(evt.target);
    this.validationSpinner = true;

    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }

    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      const csvFile: string = e.target.result;

      if (this.validateFile(csvFile)) {
        this._papa.parse(csvFile, {
          header: false,
          skipEmptyLines: true,
          complete: result => {
            let addressesArray = [];
            let coordsArray = [];
            this.csvSchema = [];
            this.zIndexes = [];

            result['data'].forEach((value, index, self) => {
              if (index) {
                const addressIndex = self[0].findIndex(arr => arr.toLowerCase().includes('address'));
                const cityIndex = self[0].findIndex(arr => arr.toLowerCase().includes('city'));
                const stateIndex = self[0].findIndex(arr => arr.toLowerCase().includes('state'));
                const zipcodeIndex = self[0].findIndex(arr => arr.toLowerCase().includes('zip code'));
                const countryIndex = self[0].findIndex(arr => arr.toLowerCase().includes('country'));
                const latitudeIndex = self[0].findIndex(arr => arr.toLowerCase().includes('latitude'));
                const longitudeIndex = self[0].findIndex(arr => arr.toLowerCase().includes('longitude'));
                const addressZIndex = self[0].findIndex(arr => arr.toLowerCase().includes('address z'));
                const cityZIndex = self[0].findIndex(arr => arr.toLowerCase().includes('city z'));
                const stateZIndex = self[0].findIndex(arr => arr.toLowerCase().includes('state z'));
                const zipcodeZIndex = self[0].findIndex(arr => arr.toLowerCase().includes('zip code z'));
                const countryZIndex = self[0].findIndex(arr => arr.toLowerCase().includes('country z'));
                const latitudeZIndex = self[0].findIndex(arr => arr.toLowerCase().includes('latitude z'));
                const longitudeZIndex = self[0].findIndex(arr => arr.toLowerCase().includes('longitude z'));

                if (
                  (value[addressIndex] && value[addressIndex].trim()) &&
                  (value[cityIndex] && value[cityIndex].trim()) &&
                  (value[stateIndex] && value[stateIndex].trim())
                ) {
                  addressesArray.push({
                    address: value[addressIndex].trim(),
                    city: value[cityIndex].trim(),
                    state: value[stateIndex].trim(),
                    zipcode: zipcodeIndex !== -1 ? value[zipcodeIndex].trim() : '',
                    country: countryIndex !== -1 ? value[countryIndex].trim() : 'USA'
                  });
                  this.csvSchema.push({ type: 'address' });
                } else if (
                  (value[latitudeIndex] && value[latitudeIndex].trim()) &&
                  (value[longitudeIndex] && value[longitudeIndex].trim())
                ) {
                  coordsArray.push({
                    lat: value[latitudeIndex],
                    lng: value[longitudeIndex]
                  });
                  this.csvSchema.push({ type: 'coords' });
                }

                if (
                  (value[addressZIndex] && value[addressZIndex].trim()) &&
                  (value[cityZIndex] && value[cityZIndex].trim()) &&
                  (value[stateZIndex] && value[stateZIndex].trim())
                ) {
                  addressesArray.push({
                    address: value[addressZIndex].trim(),
                    city: value[cityZIndex].trim(),
                    state: value[stateZIndex].trim(),
                    zipcode: zipcodeZIndex !== -1 ? value[zipcodeZIndex].trim() : '',
                    country: countryZIndex !== -1 ? value[countryZIndex].trim() : 'USA',
                    zLocation: true
                  });
                  this.csvSchema.push({ type: 'address', zLocation: true });
                } else if (
                  (value[latitudeZIndex] && value[latitudeZIndex].trim()) &&
                  (value[longitudeZIndex] && value[longitudeZIndex].trim())
                ) {
                  coordsArray.push({
                    lat: value[latitudeZIndex],
                    lng: value[longitudeZIndex],
                    zLocation: true
                  });
                  this.csvSchema.push({ type: 'coords', zLocation: true });
                }
              }
            });

            this.zIndexes = this.csvSchema.reduce((acc, cur, index) => {
              if (cur.zLocation) { acc = [ ...acc, index ]; }
              return acc;
            }, []);

            if (this.checkImportLimit(addressesArray)) {
              this.standardizeAddresses(FormatAddress.formatKeys(addressesArray), coordsArray);
            }
          },
          error: () => {
            this._snackbarService.createErrorSnackBar({
              message: 'Something went wrong! Could not parse the file.',
              duration: 4000
            });
            this.validationSpinner = false;
          }
        });
      } else {
        this._snackbarService.createErrorSnackBar({
          message: `Required csv column is missing`,
          duration: 4000
        });
        this.validationSpinner = false;
      }
    };
    reader.readAsBinaryString(target.files[0]);
    this.fileInput.nativeElement.value = '';
  }
 checkImportLimit(address) {
    if (this.accountInfo.user_information.settings.enable_import_portal_limit) {
      if (address.length <= this.accountInfo.user_information.settings.import_portal_limit) {
        return true;
      } else {
        this._snackbarService.createErrorSnackBar({
          message: 'Address upload limited to  ' + this.accountInfo.user_information.settings.import_portal_limit + ' records.',
          duration: 4000
        });
        this.validationSpinner = false;
        return false;
      }
    } else {
      return true;
    }
  }
  validateFile(file) {
    return (
      file.toLowerCase().includes('address') &&
      file.toLowerCase().includes('city') &&
      file.toLowerCase().includes('state')
    ) || (
      file.toLowerCase().includes('latitude') &&
      file.toLowerCase().includes('longitude')
    );
  }

  standardizeAddresses(addresses, coords) {
    this.temp = [];

    const addresses$ = forkJoin([
      ...(addresses.length ? [ this._addressService.standardizeAddresses(addresses) ] : []),
      ...(coords.length ? [ this._addressService.getAddressesByCoords(coords) ] : [])
    ]);

    addresses$.subscribe(
      res => {
        let standardizedAddresses;

        if (res && res.length) {
            if (this.zIndexes.length) {
            let addressIndex = 0;
            let coordsIndex = 0;

            standardizedAddresses = this.csvSchema.map(location => {
              const isAddress = location.type === 'address';
              const loc = {
                ...res[isAddress ? 0 : 1][isAddress ? addressIndex : coordsIndex],
                ...(location.zLocation ? { zLocation: location.zLocation } : {})
              };
              isAddress ? addressIndex++ : coordsIndex++;

              return loc;
            });
          } else {
            standardizedAddresses = [ ...(res[0] ? res[0] : []), ...(res[1] ? res[1] : []) ];
          }

          this.renderTable(standardizedAddresses);
        } else {
          this._snackbarService.createWarningSnackBar({
            message: 'There are no found locations for provided data',
            duration: 4000
          });
          this.validationSpinner = false;
        }
      },
      err => {
        this._snackbarService.createErrorSnackBar({
          message: err.error && err.error.message ? err.error.message : 'Something went wrong! Please refresh the page and try again.',
          duration: 4000
        });
        this.validationSpinner = false;
      }
    );
  }

  standardizeAddressesAsStrings(newData) {
    this.temp = [];

    this._addressService.standardizeAddressesAsStrings(newData).subscribe(
      res => {
        if (res && res.length) {
          if (this.zIndexes.length) {
            res = res.map((address, index) => ({
              ...address,
              ...(this.zIndexes.includes(index) ? { zLocation: true } : {})
            }));
          }

          this.renderTable(res);
        } else {
          this._snackbarService.createWarningSnackBar({
            message: 'There are no found locations for provided data',
            duration: 4000
          });
          this.validationSpinner = false;
        }
      }, (err) => {
        this._snackbarService.createErrorSnackBar({
          message: err.error && err.error.message ? err.error.message : 'Something went wrong! Please refresh the page and try again.',
          duration: 4000
        });
        this.validationSpinner = false;
      });
  }

  renderTable(addresses) {
    let allProviders = [];
    const addresskeys = addresses.reduce((acc, cur) => {
      if (cur.addresskey && !acc.includes(cur.addresskey)) {
        acc = [ ...acc, cur.addresskey];
      }

      return acc;
    }, []);

    if (addresskeys.length) {
      let result = [];

        addresses = addresses
          .map(addr => ({
            ...addr,
            name: this.passedData.name,
            zip: addr.zipcode,
            providers: allProviders.filter(provider => provider.addresskey === addr.addresskey)
          }))
          .map((addr, index, self) => {
            const nextItemIsZLocation = (self[index + 1] && self[index + 1].zLocation);

            if (addr.zLocation || nextItemIsZLocation) {
              addr.providers = addr.providers.filter(item => (
                self[nextItemIsZLocation ? index + 1 : index - 1].providers.find(
                  provider => provider.provider === item.provider
                )
              ));
            }

            return addr;
          })
          .map((addr, index, self) => {
            const nextItemIsZLocation = (self[index + 1] && self[index + 1].zLocation);
            const previousItemProviders = self[index - 1] ? self[index - 1].providers : [];

            if (addr.providers.length) {
              if (addr.zLocation) {
                result = [ ...result, ...(previousItemProviders.length ?
                  previousItemProviders.reduce((acc, cur) => {
                    addr.providers.forEach(item => {
                      acc = [ ...acc, { ...self[index - 1], ...cur }, { ...addr, ...item } ];
                    });
                    return acc;
                  }, []) : [ addr ] )];
              } else {
                if (nextItemIsZLocation) {
                  result = [ ...result, ...(self[index + 1].providers.length ? [] : [ addr ]) ];
                } else {
                  result = [ ...result, ...addr.providers.map(item => ({ ...addr, ...item })) ];
                }
              }
            } else {
              result = [ ...result, addr ];
            }

            return addr;
          });

        this.sortLocations([ ...this.locationsForDeal, ...result ]);
        this.temp = this.locationsForDeal;
        this.validationSpinner = false;
    } else {
      addresses.forEach(address => {
        address.name = this.passedData.name;
        address.zip = address.zipcode;
        address.provider = null;
        address.unique_key = null;
      });

      this.sortLocations([ ...this.locationsForDeal, ...addresses ]);
      this.temp = this.locationsForDeal;
      this.validationSpinner = false;
    }
  }

  sortLocations(locations) {
    let pairedLocations = [];
    let simpleLocations = [];

    locations.forEach((location, index, self) => {
      if (location.zLocation || (self[index + 1] && self[index + 1].zLocation)) {
        pairedLocations.push(location);
      } else {
        simpleLocations.push(location);
      }
    }, []);

    simpleLocations = sortBy(
      simpleLocations,
      [ location => location.provider ? location.provider.toLowerCase() : null ],
      ['address', 'provider']
    );
    this.locationsForDeal = [ ...pairedLocations, ...simpleLocations ]
      .map((row, index) => ({ ...row, index }));
  }

  onSelectRow({ checked }, index) {
    const selected = [ ...this.selectedRows ];
    const nextRow = this.locationsForDeal[index + 1];

    if (nextRow && nextRow.zLocation) {
      if (checked) {
        selected.push(nextRow);
      } else {
        const nextRowIndex = selected.findIndex(row => row.index === nextRow.index);
        selected.splice(nextRowIndex, 1);
      }

      this.onSelect({ selected });
    }
  }

  onSelect({ selected }) {
    this.selectedRows.splice(0, this.selectedRows.length);
    this.selectedRows = [ ...selected ];
  }

  searchTable(event) {
    const val = event.target.value.toLowerCase();

    const finalWords = [];
    const finalCommands = [];
    val.split(' ').forEach((word, i) => {
      if ( i % 2 !== 0) {
        if (word === 'and') {
          finalCommands.push('and');
        } else if (word === 'or') {
          finalCommands.push('or');
        }
      } else {
        finalWords.push(word);
      }
    });

    let temp = this.temp;
    let ortemp;

    // filter our data
    finalWords.forEach((word, i) => {
      if ((i > 0 && finalCommands[i - 1] === 'and') || i === 0) {
        ortemp = temp;

        temp = temp.filter(d =>
          String(d.address).toLowerCase().indexOf(word) !== -1 ||
          String(d.city).toLowerCase().indexOf(word) !== -1 ||
          String(d.state).toLowerCase().indexOf(word) !== -1 ||
          String(d.zipcode).toLowerCase().indexOf(word) !== -1 ||
          !word
        );
      } else if (i > 0 && finalCommands[i - 1] === 'or') {
        temp = temp;

        temp = temp.concat(ortemp.filter(d =>
          String(d.address).toLowerCase().indexOf(word) !== -1 ||
          String(d.city).toLowerCase().indexOf(word) !== -1 ||
          String(d.state).toLowerCase().indexOf(word) !== -1 ||
          String(d.zipcode).toLowerCase().indexOf(word) !== -1 ||
          !word
        ));

        temp = temp.filter((item, pos, self) => self.indexOf(item) === pos);
      }

    });

    // update the rows
    this.locationsForDeal = temp;
  }

  filterColumn(event, column) {
    const val = event.target.value.toLowerCase();
    let temp;

    for (const variable in this) {
      if (variable.indexOf('_col_filter') !== -1 && variable !== (column + '_col_filter')) {
        this[variable] = null;
      }
    }

    temp = this.temp.filter(d => String(d[column]).toLowerCase().indexOf(val) !== -1 || !val);

    this.locationsForDeal = temp;
  }

  onPageLimitChanged(limit) {
    this.limit = limit;
    this.pageNumber = 0;
  }

  setPage(e) {
    this.pageNumber = e.offset;
  }

  removeSampleKeys(passData) {
    return passData.map(element => ({
      'Address': element.address,
      'City': element.city,
      'State': element.state,
      'Zip Code': element.zipcode,
      'Country': element.country,
      'Latitude': element.latitude,
      'Longitude': element.longitude
    }));
  }

  downloadSample() {
    this.sampleData = FormatAddress.defaultSample();
    jsonexport(this.removeSampleKeys(this.sampleData), {rowDelimiter: ', '}, (err, csv) => {
      if (err) { return console.log(err); }
      const csvData = csv;
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      importedSaveAs(blob, `UploadSample.csv`);
    });
  }

  onExcludeInvalidAddresses() {
    let locationsForDeal = [];

    if (this.excludeInvalidAddresses) {
      locationsForDeal = this.locationsForDeal.filter(address => {
        if (address.found) {
          return true;
        } else {
          this.invalidAddresses.push(address);
          return false;
        }
      });
    } else {
      locationsForDeal = [ ...this.invalidAddresses, ...this.locationsForDeal ];
      this.invalidAddresses = [];
    }

    this.sortLocations(locationsForDeal);
    this.temp = this.locationsForDeal;
  }

  importAddresses() {
    if (this.checkImportLimit(this.selectedRows)) {
    this.importLoading = true;
    const invalidAddresses = this.selectedRows.filter(address => !address.found);
    let selectedRowsNotFullyFilled = [];

    this.selectedRows.forEach(row => {
      selectedRowsNotFullyFilled = [ ...selectedRowsNotFullyFilled, this.importRequiredFields.some(reqField => !row[reqField]) ];
    });

    if (selectedRowsNotFullyFilled.some(isFullyFiled => isFullyFiled)) {
      this.importLoading = false;
      return this._snackbarService.createErrorSnackBar(
        { message: `Please fill in address, city, state and country for all selected rows`, duration: 4000 }
      );
    }
    let locations = [];
    this.selectedRows.forEach((row, index, self) => {
      locations = [ ...locations, row ];
    });

    if (invalidAddresses.length) {
        const snackBarRef = this._snackBar.open(
          `Import successful, but ${invalidAddresses.length} addresses of a total of ${this.selectedRows.length} are not valid.`,
          'View Details',
          { duration: 1000, panelClass: 'snackbar-warning' }
        );

        snackBarRef.onAction().subscribe(() => {
          this._dialog.open(ImportDetailsComponent, { data: { invalidAddresses } });
        });
    } else {
        let statusMsg = this.accountInfo.user_information.settings.is_att_portal ? 'Import started - processing account locations' : `Import successful - processing account locations`;
        console.log(statusMsg);
        this._snackbarService.createSuccessSnackBar({
          message: statusMsg,
          duration: 4000
        });
    }
        this.onImportEnd.emit(this.selectedRows);
       this.importLoading = false;
   }
  }

  clearAddresses() {
    this.locationsForDeal = [];
    this.selectedRows = [];
    this.temp = [];
  }

  updateValue(value, column, rowIndex, row) {
    const locationsForDeal = [...this.locationsForDeal];
    this.addressLoading = true;

    if (value.trim() === '') {
      this.addressLoading = false;
      return this._snackbarService.createErrorSnackBar({message: `Cannot save empty value.`, duration: 4000});
    } else if (locationsForDeal[rowIndex][column] == value) {
      this.addressLoading = false;
      return this._snackbarService.createWarningSnackBar({message: `The new value is the same as previous.`, duration: 4000});
    } else if (value) {
      const addressToValidate = {
        address: row.address,
        city: row.city,
        state: row.state,
        zipcode: row.zipcode,
        country: row.country,
      };
      addressToValidate[column] = value;
      this.locationsForDeal[rowIndex][column] = value;

      const isNotFullyFilled = this.importRequiredFields.some(reqField => !this.locationsForDeal[rowIndex][reqField]);

      if (isNotFullyFilled) { return this.addressLoading = false; }

      this._addressService.standardizeAddresses([addressToValidate]).subscribe(
        res => {
          if (res) {
            res.forEach(address => {
              address['name'] = this.passedData.name,
              address['zip'] = address.zipcode;
            });
          }
          locationsForDeal[rowIndex] = res[0];
          this.locationsForDeal = locationsForDeal;
          this.addressLoading = false;

          if (this.locationsForDeal[rowIndex].found) {
            this._snackbarService.createSuccessSnackBar({message: `Address is updated and it's valid.`, duration: 3000});
          } else {
            this._snackbarService.createWarningSnackBar({message: `Address is updated, but it's not valid.`, duration: 3000});
          }
        }, () => {
          this.addressLoading = false;
          this._snackbarService.createErrorSnackBar({
            message: `Something went wrong! Please refresh the page and try again.`,
            duration: 4000
          });
        }
      );
    }
  }

  displayFn(event) {
    return (event) ? event.place_name : undefined;
  }
  parseMapbBoxResponse(event) {
    console.log(event);
    if (!event || !event.option) return;
    let standardAddress = ConnectedWorldUtil.formStandardAddressRequest(event.option.value);
    this.parsedSearchAddress = `${standardAddress.address}, ${standardAddress.city}, ${standardAddress.state}, ${standardAddress.country}`;
  }

  ngOnDestroy() {
    unsubscribe(this._subscribers);
    unsubscribe(this._accountDataSub);
  }
}
