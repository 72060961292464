import * as AdjacentBusinessesActions from './adjacentBusinesses.actions';

export interface State {
  adjacent_businesses: any;
}

const initialState: State = {
  adjacent_businesses: null,
};

export function adjacentBusinessesReducer(state = initialState, action: AdjacentBusinessesActions.AdjacentBusinessesActions) {
  switch (action.type) {
    case AdjacentBusinessesActions.SET_ADJACENT_BUSINESSES:
      return {
        ...state,
        adjacent_businesses: action.payload
      };
    default:
      return state;
  }
}
