import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

import { ErrorSnackbarComponent } from '../components/ui/error-snackbar/error-snackbar.component';

@Injectable()
export class SnackbarService {

  constructor(
    public snackBar: MatSnackBar
  ) { }

  createCustomSnackBar(snackSettings) {
    return this.snackBar.open(snackSettings.message, '', {
      duration: snackSettings.duration,
      panelClass: snackSettings.type
    });
  }

  createSuccessSnackBar(snackSettings) {
    return this.snackBar.open(snackSettings.message, '', {
      duration: snackSettings.duration,
      panelClass: 'snackbar-success'
    });
  }

  createWarningSnackBar(snackSettings) {
    return this.snackBar.open(snackSettings.message, '', {
      duration: snackSettings.duration,
      panelClass: 'snackbar-warning'
    });
  }

  openWarningSnackBar(snackSettings) {
    return this.snackBar.open(snackSettings.message, '', {
      panelClass: 'snackbar-warning'
    });
  }

  closeSnackBar() {
    return this.snackBar.dismiss();
  }

  activateCpqSnackbar(message: string, responseType: string) {
    /*  cpqPricingRes.data
        cpqPricingRes.data.message
        cpqPricingRes.data.response_type
        response_type: success, rejected, in-progress, error, not-found
        For now adding all other types to success snackbar since we don't have other snackbar types. */
    if (responseType === 'success') {
      this.createCustomSnackBar({message: `${message}`, duration: 5000, type: 'snackbar-success'});
    }
    if (responseType === 'rejected') {
      this.createCustomSnackBar({message: `${message}`, duration: 5000, type: 'snackbar-rejected'});
    }
    if (responseType === 'not-found') {
      this.createCustomSnackBar({message: `${message}`, duration: 5000, type: 'snackbar-not-found'});
    }
    if (responseType === 'in-progress') {
      this.createCustomSnackBar({message: `${message}`, duration: 5000, type: 'snackbar-in-progress'});
    }
    if (responseType === 'error') {
      this.createCustomSnackBar({message: `${message}`, duration: 5000,  type: 'snackbar-error'})
    }
  }

  createErrorSnackBar(snackSettings) {
    const data = snackSettings.message;
    const duration = snackSettings.duration;

    return this.snackBar.openFromComponent(ErrorSnackbarComponent, {
      data,
      duration,
      panelClass: 'snackbar-error'
    });
  }

}
