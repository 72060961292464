import { Injectable } from '@angular/core';
import { filter, find, isEmpty, includes, map } from 'lodash';
import { Router } from '@angular/router';

import { ApiCallService } from './../api-call.service';
import { environment } from './../../../environments/environment';
import { LookupIdentifiers } from './../../shared/lookup-identifiers';
import { TableUtil } from './../../shared/utils/table-util';

@Injectable({
  providedIn: 'root'
})
export class BuildingService {

  private directionOptions: string[] = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];

  constructor(public router: Router,
              public apiCallService: ApiCallService) { }

  private _buildingGridAllColumns = [];
  private _buildingGridUserColumns = [];

  private _configuredPricingZone = [];

  get buildingGridAllColumns() {
    return this._buildingGridAllColumns;
  }
  get buildingGridUserColumns() {
    return this._buildingGridUserColumns;
  }

  /**
   * Plot the building in map using the category,
   * network/connection status and coordinates
   * Making redirect link using the attributes
   * @param building
   */
  public locateBuilding(building, lookupOptions) {
    let categoryId = building.buildingCategory ? building.buildingCategory.map(e => e.id).join(',') : 0;
    let distanceBandId = !isEmpty(building.distanceBandValue) ? find(lookupOptions[LookupIdentifiers.DISTANCE_BAND], {distanceBandValue: building.distanceBandValue}) : 0;
    let supplierId = building.supplierManager ? building.supplierManager.supplierManagerId : 0;
    let competitiveRatingId = !isEmpty(building.buildingCompetitiveRating) ? (lookupOptions[LookupIdentifiers.COMPETITIVE_RATING].indexOf(building.buildingCompetitiveRating) + 1) : 0;
    let accessMediumIds = building.accessMediums && building.accessMediums.length > 0 ? building.accessMediums.map(e => e.id).join(',') : 0;

    let href = "/portal/" + building.location.latitude + "/" + building.location.longitude + "/"
                  + categoryId + "/"
                  + building.buildingConnectionStatus.id + "/"
                  + distanceBandId + "/"
                  + supplierId + "/"
                  + competitiveRatingId + "/"
                  + accessMediumIds + "/"
                  + building.networkBuildStatus.id;

    this.router.navigateByUrl(href);
  }

  /**
   * Retrieve all building columns
   * from api
   * @param callback
   */
  public retrieveAllBuildingColumns(callback) {
    let params = { module_id: 1 };
    this.apiCallService.processHttpRequest('GET', '/api/v1/options/grid_fields', params,
      undefined, (res => {
        this._buildingGridAllColumns = TableUtil.parseGridFieldResponse(res);
        this.retrieveUserColumns(callback);
      }), undefined);
  }

    /**
   * Check if a building has valid lat/lng
   * category so that it can be located in Map
   * using marker icon
   * @param building
   */
  public showMarker(building) {
    return (!isEmpty(building.location.latitude) && !isEmpty(building.location.latitude.trim()) &&
    !isEmpty(building.location.longitude) && !isEmpty(building.location.longitude.trim()) &&
    !isEmpty(building.buildingCategory));
  }

  public retrieveUserColumns(callback) {
    this.apiCallService.processHttpRequest('GET', '/api/v1/users/grid_fields', { module_id: 1 },
      undefined, (res => {
        if( isEmpty(res) ) { // User doesn't have saved columns
          this._buildingGridUserColumns = filter(this._buildingGridAllColumns, 'default');
        } else // User has Column history
          this._buildingGridUserColumns = filter(this._buildingGridAllColumns,
                    (e => res.includes(e.field_id)) );
        callback();
      }), undefined);
  }

  public trackUserColumns(userColumns) {
    this._buildingGridUserColumns = userColumns;
    let fieldIds = map(userColumns, 'field_id');
    let payload = { grid_field_ids: fieldIds, module_id: 1 };
    this.apiCallService.processHttpRequest('POST', '/api/v1/users/grid_fields', undefined,
      payload, undefined, undefined);
  }

  isValidEntry(building) {
    this.checkStateAndCity(building);

    return !isEmpty(building.location.street) &&
      !isEmpty(building.location.city) && !isEmpty(building.location.city.text) &&
      !isEmpty(building.location.state) && !isEmpty(building.location.state.text) &&
      !isEmpty(building.location.country) && !isEmpty(building.location.country.text);
  }

  getAddressObject(building) {
    const addressObj = {
      street: building.location.street.trim(),
      city: building.location.city.text.trim(),
      state: building.location.state.text.trim(),
      country: building.location.country.code
    };

    if(!isEmpty(building.location.addressKey))
      addressObj['addressKey'] = building.location.addressKey;

    if(!isEmpty(building.id))
      addressObj['buildingID'] = building.id;

    return addressObj;
  }

  checkStateAndCity(building) {
    if(building.location.state && !(building.location.state instanceof Object))
      building.location.state = { text:building.location.state };

    if(building.location.city && !(building.location.city instanceof Object))
      building.location.city = { text:building.location.city };
  }


  /**
   * Retrieve pricing zone by company id
   * from api
   * @param companyId
   */
  public pricingZoneByCompanyId(companyId) {
    this.apiCallService.processHttpRequest('GET', '/api/v1/zone/geography/'+companyId, null,
      undefined, (res => {
        this._configuredPricingZone = res
      }), undefined);
  }

  validate(building) {
    var message = "";
    if(isEmpty(building.buildingname) || isEmpty(building.buildingname.trim()))
      message = "Building name is empty";
    else if(building.buildingname.length > 250)
      message = "Building name cannot exceed 250 characters";
    else if(isEmpty(building.location.street) || isEmpty(building.location.street.trim()))
      message = "Street is empty";
    else if(isEmpty(building.location.country) || isEmpty(building.location.country.text))
      message = "Country is empty";
    else if(isEmpty(building.location.state) || isEmpty(building.location.state.text))
      message = "State is empty";
    else if(isEmpty(building.location.city) || isEmpty(building.location.city.text))
      message = "City is empty";
    else if(building.location.parsedState && building.location.parsedState.length != 2)
     message = "Parsed address state must be an abbrevation";
    else if(building.specialConstructionCost && building.specialConstructionCost < 0)
      message = "Special Construction Cost should not be a negative number";
    else if(building.buildingClli && (building.buildingClli.length < 8 || building.buildingClli.length > 11))
      message = "Access Building Clli Should be between 8 to 11 Characters";
    else if ((building.networkBuildStatus.id == environment.CONNECTED_ID && building.buildingConnectionStatus.id == environment.NEARNET.id) || (building.networkBuildStatus.id == environment.INPROGRESS_ID && building.buildingConnectionStatus.id == environment.NEARNET.id)
        || (building.networkBuildStatus.id == environment.NOT_CONNECTED.id && building.buildingConnectionStatus.id == environment.ONNET_ID) || (building.networkBuildStatus.id == environment.NOT_CONNECTED.id && building.buildingConnectionStatus.id == environment.ONNET_LIMITED_ACCESS_ID)){
      message = "Invalid combination - " + building.networkBuildStatus.text + " & " + building.buildingConnectionStatus.text;
    } else if(isEmpty(building.buildingCategory))
      message = "Building Category is empty";
    else if (building.location.plus4Code && building.location.plus4Code.length != 4)
      message = "Plus4Code length should be 4 digits";
    else if (building.location.elevation && isNaN(building.location.elevation))
      message = "Elevation should not be an Alphabet";
    else if (building.location.streetPreDirection && this.directionOptions.indexOf(building.location.streetPreDirection) == -1)
      message = "Street Predirection should be N, NE, E, SE, S, SW, W or NW";
    else if(building.horizontalCoordinate && isNaN(building.horizontalCoordinate))
      message = "Horizontal Coordinate should be a Number";
    else if(building.verticalCoordinate && isNaN(building.verticalCoordinate))
      message = "Vertical Coordinate should be a Number";
    else if (building.comcastPricingZone && building.comcastPricingZone.length > 3)
      message = "Comcast Pricing Zone cannot exceed 3 characters";
    else if (building.indatelPricingZone && building.indatelPricingZone.length > 3)
      message = "Indatel Pricing Zone cannot exceed 3 characters";
    else if (building.level3PricingZone && building.level3PricingZone.length > 3)
      message = "CenturyLink Pricing Zone cannot exceed 3 characters";
    else if (building.sprintPricingZone && building.sprintPricingZone.length > 100)
      message = "Sprint Pricing Zone cannot exceeds 100 characters";
    else if (building.verizonPricingZone && building.verizonPricingZone.length > 3)
      message = "Verizon Pricing Zone cannot exceeds 3 characters";
    else if (building.attPricingZone && building.attPricingZone.length > 3)
      message = "AT&T Pricing Zone cannot exceeds 3 characters";
    else if ((building.location.latitude != undefined && (isNaN(building.location.latitude) || building.location.latitude < -90 ||  building.location.latitude > 90))
          ||(building.location.longitude != undefined && (isNaN(building.location.longitude) || building.location.longitude < -180 ||  building.location.longitude > 180)))
      message = "Invalid Latitude/Longitude";
    else if(undefined != building.installIntervalValue && (building.installIntervalValue  < 1 || building.installIntervalValue  > 365)){
      message = "Install Interval should be between 1 to 365";
    }
    else if( !isEmpty(building.$original) && !isEmpty(building.$original.attUniqueKey) && isEmpty(building.attUniqueKey) )
      message = "AT&T Unique Key can't be Empty";
    else if (building.myNetworkRevenue && isNaN(building.myNetworkRevenue))
      message = "My Network Revenue should be a Number";
    else if (building.locationZoneId && isNaN(building.locationZoneId))
      message = "Pricing Zone should be a Number";
    else if (building.locationZoneId && isEmpty(this._configuredPricingZone))
      message = "No Pricing zone configuration";
    else if (building.locationZoneId && false === includes(this._configuredPricingZone, Number(building.locationZoneId)))
      message = "Invalid Pricing zone Id";
    else if (building.waveZone && isNaN(building.waveZone))
      message = "Wave Zone should be a Number";
    else if(undefined != building.waveInstallInterval && (building.waveInstallInterval  < 1 || building.waveInstallInterval  > 365))
      message = "Wave Install Interval should be between 1 to 365";

    else if(undefined != building.losDistance1Path && building.losDistance1Path < 1 )
      message = "LOS Distance 1 Path should be greater than 0";
    else if(undefined != building.losDistance2Path && building.losDistance2Path < 1 )
      message = "LOS Distance 2 Path should be greater than 0";
    else if(undefined != building.rowDistance1Path && building.rowDistance1Path < 1 )
      message = "ROW Distance 1 Path should be greater than 0";
    else if(undefined != building.rowDistance2Path && building.rowDistance2Path < 1 )
      message = "ROW Distance 2 Path should be greater than 0";
    else if(undefined != building.losDistance1AccessPoint && building.losDistance1AccessPoint < 1 )
      message = "LOS Distance 1 Access Point should be greater than 0";
    else if(undefined != building.losDistance2AccessPoint && building.losDistance2AccessPoint < 1 )
      message = "LOS Distance 2 Access Point should be greater than 0";
    else if(undefined != building.rowDistance1AccessPoint && building.rowDistance1AccessPoint < 1 )
      message = "ROW Distance 1 Access Point should be greater than 0";
    else if(undefined != building.rowDistance2AccessPoint && building.rowDistance2AccessPoint < 1 )
      message = "ROW Distance 2 Access Point should be greater than 0";
    return message;
  };

  setCustomFieldsToBuilding(customFields) {
    var customFieldObj = {};
    customFields.forEach(element => {
      var obj = {
        fieldId: element.fieldId,
        name: element.name.toLowerCase().replace(/\s+/g, '_').replace(/[`~!@#$%^&*()_|+\-=÷¿?;.:'",<>\{\}\[\]\\\/]/gi, '_'),
        label: element.label,
        value: '',
        valueFilter: '',
      };
      customFieldObj[obj.name] = obj;
    });
    return customFieldObj;
  }

  resetValues() {
    this._buildingGridAllColumns = [];
    this._buildingGridUserColumns = [];
  }
}
