import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from 'environments/environment';

@Injectable()
export class UnauthGuardService implements CanActivate {

  constructor(public auth: AuthenticationService, public router: Router) {}

  canActivate(): boolean {
    if (this.auth.isAuthenticated()) {
      if(environment.application !== 'PORTAL')
        this.router.navigate(['/']);
      return false;
    }
    return true;
  }

}
