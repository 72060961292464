<div class="forgot-password-wrapper" *ngIf="showForgotComp">
  <div *ngIf="displayUpperImage" class="upper-logo-image">
    <img src="../../../../../assets/connectbase.svg" alt="" width="150px" >
  </div>
  <div class="box-wrapper">
    <div class="card">
      <div class="title">{{title}}</div>
      <div class="login-form" fxLayout="column">
        <div *ngIf="!resetEmailProceed">
          <div class="send-email">
            <h4 class="message">Please enter the email used for signing up. We will send a link to reset your password.</h4>
            <form [formGroup]="myForm" (ngSubmit)="onSubmit()" class="form">
              <div class="form">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Email"
                    type="text"
                    id="email"
                    class="form-control"
                    formControlName="email">
                </mat-form-field>
              </div>
              <br>
              <div fxLayoutAlign="center center">
                <button
                  mat-raised-button
                  type="submit"
                  [disabled]="!myForm.valid">Send Email</button>
              </div>
            </form>
            <div fxLayoutAlign="center center">
              <a class="link" (click)="back()">Back to Login</a>
            </div>
          </div>
        </div>
        <div class="send-email-card" *ngIf="resetEmailProceed">
          <div class="send-email">
            <h4 class="send-email-message">If the email address exists in our system, we will send the password reset link.</h4>
            <div class="back-to-login-link" fxLayoutAlign="center center">
              <a class="link" (click)="back()">Back to Login</a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="company-logo-card">
      <div class="img-container"><img src="../../../../../assets/connectbase.svg" alt="" width="250px"></div>
    </div>
  </div>
</div>
