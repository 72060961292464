import { Component, OnInit, ViewChild, Inject, ChangeDetectorRef } from '@angular/core';
import { DataTable } from 'primeng/primeng';
import { MAT_BOTTOM_SHEET_DATA, MatDialog } from '@angular/material';
import { TableUtilityService } from 'app/services/table-utility/table-utility.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from 'app/app.reducers';
import { LookupIdentifiers } from 'app/shared/lookup-identifiers';
import { SharedDataService } from 'app/services/shared-data.service';
import { InfoService } from 'app/services/info.service';
import { QuickQuoteModalComponent } from 'app/components/connected-world/quick-quote-modal/quick-quote-modal.component';
import { CommonUtil } from 'app/shared/utils/common-util';
import { ApiCallService } from '../../../services/api-call.service';
import { CommonUtilityService } from '../../../services/common/common-utility.service';
import { SnackbarService } from 'app/modules/shared-components/services/snackbar.service';

@Component({
  selector: 'app-account-location',
  templateUrl: './account-location.component.html',
  styleUrls: ['./account-location.component.css']
})
export class AccountLocationComponent implements OnInit {

  @ViewChild('locationTable') locationTable: DataTable;
  
  accountInfo;
  buildingConnOptions = [];
  nwConnOptions = [];
  accessMediums = [];
  payload: any = {
    start: 0,
    pageSize: 25,
    filters: {},
  };
  rows: any[] = [];
  totalCount: number = 0;
  quickQuoteLoading: boolean = false;
  locationColumns = [{title: '', field: 'priceQuote'}, 
    {title: '', field: 'requestInfo'},
    {title: 'Address', field: 'address'},
    {title: 'City', field: 'city'},
    {title: 'State', field: 'state'},
    {title: 'Zipcode', field: 'zipcode'},
    {title: 'Country', field: 'country'},
    {title: 'Network Connection Status', field: 'networkConnectionStatus'},
    {title: 'Building Connection Status', field: 'buildingConnectionStatus'},
    {title: 'Media Type', field: 'mediaType'},
    {title: 'Validation Status', field: 'found'}
  ];

  attLocationColumns = [{title: '', field: 'priceQuote'}, 
    {title: '', field: 'requestInfo'},
    {title: 'Address', field: 'address'},
    {title: 'City', field: 'city'},
    {title: 'State', field: 'state'},
    {title: 'Zipcode', field: 'zipcode'},
    {title: 'Country', field: 'country'},
    {title: 'Building Connection Status', field: 'buildingConnectionStatus'},
    {title: 'Access Medium', field: 'mediaType'},
  ];

  filter = {
    address: '', city:'', state:'', zipcode:'', country:'', networkConnectionStatus:'',
    buildingConnectionStatus:'', mediaType:''
  };

  exportedFiles : any[]
  feExpanded: boolean = true
  disableCancel: boolean = false

  private exportSubscription: Subscription;
  private lookupSubscription: Subscription;
  private userSubscription: Subscription;
  
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: any,
              public tableUtilityService: TableUtilityService,
              private _changeDetectorRef: ChangeDetectorRef,
              public sharedDataService: SharedDataService,
              private store: Store<fromStore.AppState>,
              public dialog: MatDialog,
              private infoService: InfoService,
              private apiCallService: ApiCallService,
              private commonUtilityService: CommonUtilityService) { 
                this.userSubscription = store.select(userState => userState['userFeature']['userData']).subscribe(userData => {
                  this.accountInfo = userData.user_information ? userData.user_information.settings : {};
                });
                this.exportSubscription = this.sharedDataService.exportSource.subscribe(expFiles => this.exportedFiles = expFiles);
            }

  ngOnInit() {
    // Fetching lookup data need for grid filter
    if(this.accountInfo.is_att_portal)
      this.locationColumns = this.attLocationColumns;
    this.lookupSubscription = this.sharedDataService.lookupSource.subscribe(value => {
      this.nwConnOptions = value[LookupIdentifiers.NETWORK_STATUS];
      this.buildingConnOptions = value[LookupIdentifiers.CONNECTION_STATUS];
      this.accessMediums = value[LookupIdentifiers.ACCESS_MEDIUM];
    }); 
    if(this.bottomSheetData.locations) {
      this.rows = this.bottomSheetData.locations;
      this.totalCount = this.bottomSheetData.locations.length;
    }
    this._changeDetectorRef.markForCheck();
  }

  hideBottomSheet() {
    this.infoService.notifyOther(InfoService.HIDE_BOTTOM_SHEET);
  }

  showQuickQuote(building) {
    const dialogRef = this.dialog.open(QuickQuoteModalComponent, {
      data: {...building, isBottomSheet:true},
    });

    dialogRef.afterClosed().subscribe(data => {
      if( data ) {
        //this.fetchBuildingStandardAddresses(data);
      }
    });
  }

  concatItem(item, attr) {
    return CommonUtil.extractProperty(item, attr).join(', ');
  }
  clearAllFilters() {
    this.filter = {
      address: '', city:'', state:'', zipcode:'', country:'', networkConnectionStatus:'',
      buildingConnectionStatus:'', mediaType:''
    };
    this.locationTable.reset();
  }

  /**
   * Send Request info  email to the admin who created the user
   */
  sendRequestInfoMailFromImport(row) {
    let api = '/api/v1/portal/request/info';
    let payload = {};
    payload['id'] = this.accountInfo.id;
    payload['address'] = row.inputaddresskey ? row.inputaddresskey :
                        (row.address +", "+ row.city +", " + row.state+", "+ row.country);
                        payload['id'] = this.accountInfo.id;
    payload['companyId'] = this.accountInfo.company_id;
    payload['address'] = row.inputaddresskey ? row.inputaddresskey :
                          (row.address +", "+ row.city +", " + row.state+", "+ row.country)   ;
    payload['buildingId'] = undefined == row.buildingId ? 0 : row.buildingId;
    payload['standardAddress'] = this.getStandardAddress(row);

    if (this.accountInfo.is_att_portal) 
      payload['pricingTier'] =  row.pricingTier ? row.pricingTier  : "";
    
    payload['activityLog'] = {
      userEmail: this.accountInfo.email,
      userCompanyName: this.accountInfo.userCompanyName,
      companyName: this.accountInfo.company_name,
    }
    this.apiCallService.processHttpRequest('POST', api, undefined, payload,
      (response => {
        if(response)
          this.commonUtilityService.openSnackBar("Request mail sent");
        else
          this.commonUtilityService.openSnackBar("Failed to sent");
      }), undefined);
  }

  getImportStatus(row) {
    return this.accountInfo.is_att_portal ? ( row.pricingTier ? "Serviceable" : "Not Serviceable") : "";
  }

  calculateClasses(row){
    return{
      'disabled': this.accountInfo.is_att_portal && ( row.pricingTier ==  undefined || row.pricingTier == '' )
      }
  }

  getStandardAddress(row) {
    let standardAddress = {};
    standardAddress['address'] = row.address;
    standardAddress['city'] = row.city;
    standardAddress['state'] = row.state;
    standardAddress['country'] = row.country;
    standardAddress['zipcode'] = row.zipcode;
    standardAddress['latitude'] = row.latitude != undefined ? row.latitude : '';
    standardAddress['longitude'] = row.longitude != undefined ? row.longitude : '';
    standardAddress['buildingId'] = row.buildingId != undefined ? row.buildingId : 0;
    standardAddress['addresskey'] = row.addresskey != undefined ? row.addresskey : '';
    return standardAddress;
  }

  setMediaType(row, col) {
    if(row[col.field]) {
      let fieldValue = row[col.field].map((e)=>{return e}).join(',');
      return (fieldValue.length > 20) ? fieldValue.substr(0, 20)+'...' : fieldValue;
    } else 
      return "";
  }

  /**
   * Export bulk address
   * 
   */
  exportAddress() {
    this.disableCancel = true
    this.apiCallService.processHttpRequest(
      "GET",
      "/api/v1/user/" + this.accountInfo.id + "/export/limit",
      undefined,
      undefined,
      (response) => {
        let totalAddrssSize = this.bottomSheetData.locations.length;
        if (response) {
          if (totalAddrssSize > response)
            this.commonUtilityService.openCustomSnackBar({
              message: `You may possibly export ${response} addresses from total addresses of ${totalAddrssSize}.`,
              duration: 3000
            });
          else
            this.commonUtilityService.openCustomSnackBar({
              message: `You have remaining ${response - totalAddrssSize} addressess to export today.`,
              duration: 3000
            });        
            let reqBody = {
              userAccountId: this.accountInfo.id,
              limit: response,
              standardAddresses:
                totalAddrssSize > response
                  ? this.bottomSheetData.locations.slice(0, response)
                  : this.bottomSheetData.locations,
            }
            this.apiCallService.processHttpRequest(
              "POST",
              "/api/v1/address/export",
              undefined,
              reqBody,
              this.exportCallback.bind(this),
              undefined
            )
        } else 
          this.commonUtilityService.openCustomSnackBar({
            message : `You have exceeded your daily limit to export addresses.`,
            duration: 3000
          })
      },
      undefined
    );
    this.disableCancel = false
  }
  
  /**
   * Callback method after export
   * 
   * @param response
   */
  private exportCallback(response) {
    this.exportedFiles.push(response);
    this.sharedDataService.updateExportSource(this.exportedFiles);
    this.feExpanded = true;
    this._changeDetectorRef.detectChanges();
  }

  minimizeExportPopup() {
    this.feExpanded = false;
  }

  ngOnDestroy() {
    this.lookupSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
    this.exportSubscription.unsubscribe();
  }

}
