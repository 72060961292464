<div class="flex-btw">
  <h4 mat-dialog-title class="m-0">Feedback to Admin</h4>
  <button mat-icon-button [mat-dialog-close]="undefined"><i class="material-icons">close</i></button>
</div>

<div mat-dialog-content>
  <form name="feedbackform" #feedbackform="ngForm">
    <div class="feedback_content">
    <div fxLayout="row wrap" fxLayoutAlign=" end" fxLayoutGap="5px" class="m-t-b">
      <mat-form-field>
        <input id="report-fn" name="report-fn" [readonly]="true" matInput type="text"
          placeholder="First Name" [(ngModel)]="payload.firstName">
      </mat-form-field>

      <mat-form-field>
        <input id="report-ln" name="report-ln" [readonly]="true" matInput type="text"
          placeholder="Last Name" [(ngModel)]="payload.lastName">
      </mat-form-field>

      <mat-form-field>
        <input id="report-email" name="report-email" [readonly]="true" matInput type="text"
          placeholder="Email" [(ngModel)]="payload.email">
      </mat-form-field>

      <mat-form-field class="full-width">
        <input id="reportSubject" name="reportSubject" matInput type="text"
          placeholder="Subject" [(ngModel)]="payload.subject" required #reportSubject="ngModel" >

        <mat-error *ngIf="reportSubject.invalid && reportSubject.errors.required">
          Subject is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <textarea id="reportDesc" name="reportDesc" matInput placeholder="Description"
          [(ngModel)]="payload.message" required #reportDesc="ngModel"></textarea>

        <mat-error *ngIf="reportDesc.invalid && reportDesc.errors.required">
          Description is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="file_holder" id="feedbackbtn">
      <p-fileUpload #feedbackUpload name="files[]" multiple="multiple" auto="true"
        customUpload="true" (uploadHandler)="fileUploader($event)" id="feedback-file-upload">
        <ng-template pTemplate="toolbar">
          <span class="upload_note">
            (Each of your file(s) can be up to 20MB in size.)
          </span>
        </ng-template>

        <ng-template pTemplate="content">
          <div class="m-l-10">
            <div *ngFor="let file of payload.attachments; index as i" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign=" center">
              <div>{{file.actualFileName}}</div>
              <div>
                <button mat-icon-button color="warn" (click)="payload.attachments.splice(i, 1);"
                  matTooltip="Remove File" id="remove-file-{{i}}">
                  <mat-icon aria-label="delete">delete_outline</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </ng-template>
      </p-fileUpload>
    </div>
    </div>
  </form>
</div>

<div mat-dialog-actions class="flex-box-end">
  <i class="fa fa-spinner fa-spin" *ngIf="feedbackLoading"></i>
  <button class="text-uppercase" mat-raised-button color="primary" [disabled]="!feedbackform.form.valid || feedbackLoading" (click)="saveReport()">Submit</button>
  <button class="text-uppercase" mat-raised-button (click)="resetFeedback()">Reset</button>
</div>

