import { Injectable } from '@angular/core';
import { AuthApiCallService } from '../auth-api-call/auth-api-call.service';

@Injectable()
export class ForgotPasswordService {

  constructor(
    private apiCallService: AuthApiCallService
  ) {}

  sendEmail(email) {
    const body = { email };
    return this.apiCallService.makePostRequest(`/v1/reset-password-email`, body)
  }

  sendEmailPortal(email, portalUrl) {
    let url = ''+portalUrl;
    const body = { email:email, portalUrl:url.replace('/forgot-password','') };
    return this.apiCallService.makePostRequest(`/v1/reset-password-email`, body)
  }


}
