import { Component, OnInit, Inject, Input } from '@angular/core';
import { map } from 'rxjs/operators';
import { isEmpty, flatten, uniq } from 'lodash';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { CommonUtil } from '../../../shared/utils/common-util';
import { ApiCallService } from '../../../services/api-call.service';
import { BuildingLookupService } from '../../../services/lookup/building-lookup.service';
import { CWService } from '../../../services/cw.service';
import { PersistanceService } from './../../../services/persistence.service';

import * as fromStore from 'app/app.reducers';
import { CommonUtilityService } from 'app/services/common/common-utility.service';
import { environment } from 'environments/environment';
import { AppConstants } from 'app/app.constants';

@Component({
  selector: 'app-map-building-info',
  templateUrl: './map-building-info.component.html',
  styleUrls: ['./map-building-info.component.css']
})
export class MapBuildingInfoComponent implements OnInit {
  @Input() marketInfo: any;
  @Input() attPricingInfo: any;
  @Input() productSelected: string;
  inputId: string;

  accountInfo;
  buildingInfo: any = {};
  loadingInfo: boolean = false;
  markerSourceSubscription: Subscription;
  serviceNames = [];

  private userSubscription: Subscription;
  public attPricingTierInfo;

  constructor(public buildingLookupService: BuildingLookupService,
              public persistanceService: PersistanceService,
              private apiCallService: ApiCallService,
              private cwService: CWService,
              public commonUtilityService: CommonUtilityService,
              private store: Store<fromStore.AppState>) {
    this.userSubscription = store.select(userState => userState['userFeature']['userData']).subscribe(userData => {
      this.accountInfo = userData.user_information ? userData.user_information.settings : {};
    });
  }

  ngOnInit() {
    if(this.accountInfo.is_att_portal){
      this.loadingInfo = true;
      if((undefined == this.attPricingInfo.locationInfo &&  (!this.marketInfo.fromImport)) || (this.marketInfo.fromImport  && undefined == this.marketInfo.locationInfo)){ 
        //call IPTA API to get the location info 
        const iconIfo = this.marketInfo;
        this.callRemotePricingAPI(this.marketInfo.buildingid, iconIfo) 
      }else{
        this.loadingInfo = false;
        this.setExternalAPIBuildingResponse();
       
      }
    }else if(undefined != this.marketInfo.accuracy){
      let mapboxLocation = {'location': {}}
      let address = {};
      let city = {};
      let state ={};
      let country ={};
      address['street'] = this.marketInfo.address;
      city['text'] = this.marketInfo.city;
      address['city'] = city;
      state['text'] = this.marketInfo.state;
      address['state'] = state;
      country['text'] = this.marketInfo.country;
      address['country'] = country;
      address['postal'] = this.marketInfo.zipcode;
      address['latitude'] = this.marketInfo.latitude;
      address['longitude'] = this.marketInfo.longitude;
      mapboxLocation['location'] = address;
      this.buildingInfo = mapboxLocation;
      this.buildingInfo.buildingname = this.marketInfo.address;
    }else if(undefined == this.marketInfo.buildingid && !this.marketInfo.ismarketdata){
      let importedLocation = {'location': {}}
      let address = {};
      let city = {};
      let state ={};
      let country ={};
      address['street'] = this.marketInfo.title;
      city['text'] = this.marketInfo.city;
      address['city'] = city;
      state['text'] = this.marketInfo.state;
      address['state'] = state;
      country['text'] = this.marketInfo.country;
      address['country'] = country;
      address['latitude'] = this.marketInfo.latitude;
      address['longitude'] = this.marketInfo.longitude;
      importedLocation['location'] = address;
      this.buildingInfo = importedLocation;
      this.buildingInfo.buildingname = this.marketInfo.title;
    }
    else if(undefined == this.marketInfo.buildingid){
      this.retrieveMarketLocationDetails(this.marketInfo.addresskey);
    }else{
      this.markerSourceSubscription = this.cwService.markerSource.subscribe(val => {
        this.inputId = val.buildingid;
        if(this.inputId)
          this.retrieveBuildingDetails();
      });
    }
    // if( !this.buildingLookupService.companyProducts)
    //   this.buildingLookupService.retrieveCompanyProducts(Number(localStorage.selected_company_id), this.extractProducts.bind(this));
    // else{
    //   this.extractProducts(this.buildingLookupService.companyProducts);
    // }
  }

  isEmpty() {
    return isEmpty(this.buildingInfo);
  }

  retrieveBuildingDetails() {
    let payload = { id: this.inputId, view: false };
    this.loadingInfo = true;
    this.buildingInfo = undefined;
      this.apiCallService.processHttpRequest('POST', '/api/v1/data/builings/getbyid', undefined, payload,
        (response => {
          this.loadingInfo = false;
          this.buildingInfo = response;
        }),
        (error => this.loadingInfo = false)
      );
  }

  callRemotePricingAPI(buildingId, iconIfo) {
    this.attPricingTierInfo ={};
      let apiUrl = '/api/v1/att_products/remote_pricing';
      let id = buildingId == undefined ? 0 : buildingId
      let requestId = Date.now() + id;
      let payload = {
        entityId: this.accountInfo.company_id,
        locations: {},
        pricingData: {},
        suppliers: ["AT&T Wholesale"],
        forceFetch: environment.forceFetch,
        serviceable: true,
        requestId: requestId,
        activityLog: {
          userEmail: this.accountInfo.email,
          userCompanyName: this.accountInfo.userCompanyName,
          companyName: this.accountInfo.company_name,
        },
      };
      let address = {};
      address['address'] = iconIfo.address;
      address['city'] = iconIfo.city;
      address['state'] = iconIfo.state;
      address['postal'] = iconIfo.zipcode; 

      payload['locations'] = [address];
      payload['pricingData']['products'] = [AppConstants.DEDICATED_INTERNET];
      payload['pricingData']['accessMediums'] = [1,2,3];
      payload['pricingData']['speeds'] = [16, 31, 18, 32, 10, 19, 33, 11, 22, 34, 12, 27, 38, 13, 28, 14, 29, 15, 30];
      payload['pricingData']['terms'] = [12,24,36]

      this.apiCallService.processHttpRequest('POST', apiUrl, undefined, payload,
        (response => {
          if (response.status == "Subscriptions not found"){
            this.commonUtilityService.openSnackBar("Subscriptions not found");
          }else {
            const targetTime = new Date().valueOf() + environment.maxWaitTime;
            this.getPricingTierInfoFromCallBackAPIV2(requestId, targetTime, buildingId, undefined, undefined);
          }
        }
        ), undefined);
  }

  getPricingTierInfoFromCallBackAPIV2(requestId, targetTime, buildingId, importedLocations, callBack){
    let apiUrl = '/api/v1/att_products/pricing_tier?requestId='+requestId+'&productId='+6;
    this.apiCallService.processHttpRequest('GET', apiUrl, undefined, undefined,
    (
      response => {
        let id = buildingId == 0 ? undefined : buildingId;
        let currentTime = Date.now().valueOf(); 
        for (var i =0; i < response.length; i++){
          if (response[i].status == "Failed" && undefined == importedLocations){
            this.loadingInfo = false;
            this.commonUtilityService.openSnackBar("Failed");
          }else if(response[i].status == "Success" ){
            let obj = response[i].data;
            if (undefined != importedLocations ){
              importedLocations.find(p => p.addresskey == obj.addressKey)["fromImport"] = true;
              importedLocations.find(p => p.addresskey == obj.addressKey)["speeds"] = obj.speeds;
              importedLocations.find(p => p.addresskey == obj.addressKey)["locationInfo"] = obj.locationInfo;
            } 
            this.attPricingTierInfo= {addressKey : obj.addressKey, pricingTierInfo: obj.pricingTier, speeds:obj.speeds,
              locationInfo: obj.locationInfo};
              //this.marketInfo['locationInfo'] = obj.locationInfo;
            this.setExternalAPIBuildingResponse();
            this.loadingInfo = false;
          }else if (response[i].status == "In-Progress"  && currentTime <= targetTime){
            setTimeout(() => this.getPricingTierInfoFromCallBackAPIV2(requestId, targetTime, buildingId, importedLocations, callBack), 5000);
          }else{
            this.loadingInfo = false;
            this.commonUtilityService.openSnackBar("Location Info not found");
          }
        }
      }
    ), undefined);
  }


  setExternalAPIBuildingResponse(){
    
    let apiLocation = (undefined != this.marketInfo && this.marketInfo.fromImport && this.marketInfo.locationInfo) ? 
                      JSON.parse(this.marketInfo.locationInfo) : undefined == this.attPricingInfo.locationInfo ? 
                      this.attPricingTierInfo.locationInfo : this.attPricingInfo.locationInfo;
    
      let supplierName = (undefined != this.marketInfo && this.marketInfo.fromImport) ? 
                      this.marketInfo.pricingTier : 
                      undefined == this.attPricingInfo.pricingTierInfo ? this.attPricingTierInfo.pricingTierInfo : this.attPricingInfo.pricingTierInfo;
    let externalAPILocation = {'location': {}};
    let address = {};
    let city = {};
    let state ={};
    let country ={};
    apiLocation = (undefined != this.marketInfo && this.marketInfo.fromImport && this.marketInfo.locationInfo) ? JSON.parse(this.marketInfo.locationInfo) : apiLocation;
    apiLocation = this.marketInfo.fromImport && undefined == this.marketInfo.locationInfo && this.marketInfo.buildingid > 0 ? 
                  this.attPricingTierInfo.locationInfo : apiLocation;

    address['street'] = apiLocation.addressName;
    city['text'] = apiLocation.cityName;
    address['city'] = city;
    state['text'] = apiLocation.stateName;
    address['state'] = state;
    country['text'] = apiLocation.countryName;
    address['country'] = country;
    address['latitude'] = apiLocation.latitude;
    address['longitude'] = apiLocation.longitude;
    address['postal'] = apiLocation.postalCode;
    address['npa'] = apiLocation.npa;
    address['nxx'] = apiLocation.nxx;
    externalAPILocation['location'] = address;
    this.buildingInfo = externalAPILocation;
    this.buildingInfo.cllicode = apiLocation.swcClliCode;
    this.buildingInfo.buildingClli = apiLocation.buildingClli;
    this.buildingInfo.npa = apiLocation.npa;
    this.buildingInfo.nxx = apiLocation.nxx;
    this.buildingInfo.buildingname = apiLocation.addressName;
    this.buildingInfo["supplierName"] = undefined == supplierName ? '' : "AT&T";
    let accessMediums = [];
    let accessMediumObj ={};
    accessMediumObj['text'] = apiLocation.accessMedium;
    accessMediums.push(accessMediumObj);
    this.buildingInfo['accessMediums'] = accessMediums;
    let buildingConnectionStatus = this.productSelected == "6" ? apiLocation.adiBuildingConnectionStatus : apiLocation.aseBuildingConnectionStatus;
    this.buildingInfo['buildingConnectionStatus'] = {'text': buildingConnectionStatus};
  }

  retrieveMarketLocationDetails(addressKey) {
    var param ={"addressKey" : addressKey};
    this.apiCallService.processHttpRequest('GET', '/api/v1/location/addresskey', param, undefined,
    (response => {
      this.loadingInfo = false;
      let markerLocation = {'location': {}}
      let address = {};
      let city = {};
      let state ={};
      let country ={};
      address['street'] = response.address1;
      city['id'] = response.city.id;
      city['text'] = response.city.name;
      address['city'] = city;
      state['id']= response.state.stateId;
      state['text'] = response.state.stateName;
      address['state'] = state;
      country['id'] = response.country.countriesId;
      country['text'] = response.country.countriesName;
      address['country'] = country;
      address['postal'] = response.zip;
      address['latitude'] = response.latitude;
      address['longitude'] = response.longitude;
      address['msa'] = response.msa
      markerLocation['location'] = address;
      this.buildingInfo = markerLocation;
    }),
    (error => this.loadingInfo = false)
  );
  }
  extractProducts(companyProducts) {
    let defaultChannelSelData = companyProducts.selected_services[1]; // 1 refers to default channel
    if(defaultChannelSelData) {
      let selServices: number[] = uniq(flatten(Object.values(defaultChannelSelData)));
      let defaultChannelServices = companyProducts.pricing_data[1];
      let products: object = defaultChannelServices[Object.keys(defaultChannelServices)[0]];
      selServices.forEach(serviceId => {
        this.serviceNames.push(products[serviceId][0].serviceName)
      })
    }
  }
  
  concatItem(item, attr) {
    if(undefined == item || item.length == 0)
      return "";
    return CommonUtil.extractDistinctProperty(item, 'text').join(', ');
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    if(undefined !=  this.markerSourceSubscription)
      this.markerSourceSubscription.unsubscribe();
  }

}
