import { Action } from '@ngrx/store';

export enum SharedDataActionTypes {
  CATEGORY_ICONS = '[SharedData] CATEGORY_ICONS',
  LOOKUP_OPTIONS = '[SharedData] LOOKUP_OPTIONS',
}

export class GetCategoryIcons implements Action {
  readonly type = SharedDataActionTypes.CATEGORY_ICONS;
  constructor(public categoryIcons: any) {}
}

export class LookupOptions implements Action {
  readonly type = SharedDataActionTypes.LOOKUP_OPTIONS;
  constructor(public lookupOptions: any) {}
}

export type SharedDataActions = GetCategoryIcons | LookupOptions;
