<mat-form-field color="accent" class="full-width map-btn pad-10">
	<div *ngIf="inputSearchTypes[0].type === (inputSearchType$ | async).type">
		<input placeholder="{{(inputSearchType$ | async).placeholder}}" type="text" [(ngModel)]="address"
			(input)="checkIfEmpty($event)" aria-label="mapbox_loc" matInput [matAutocomplete]="autoLocation"
			[formControl]="locationCtrl" id="mpx_text" autoFocus (keyup.enter)="onLocationSearch($event)">
		<mat-autocomplete #autoLocation="matAutocomplete" [displayWith]="displayWrapper(inputSearchTypes[0].type)"
			(optionSelected)="optionSelected($event, inputSearchTypes[0].type)">
			<mat-option *ngFor="let location of (locationData$ | async)" [value]="location"
				title="{{ setOption(location, inputSearchTypes[0].type) }}">
				{{ setOption(location, inputSearchTypes[0].type) }}
			</mat-option>
		</mat-autocomplete>
	</div>
	<div *ngIf="inputSearchTypes[1].type === (inputSearchType$ | async).type">
		<input placeholder="{{(inputSearchType$ | async).placeholder}}" type="text" (input)="checkIfEmpty($event)"
			aria-label="mapbox_loc" matInput [matAutocomplete]="autoCoordinates" (keyup.enter)="onSearchEnter($event)"
			[formControl]="coordsCtrl" id="mpx_text" autoFocus>
		<mat-autocomplete #autoCoordinates="matAutocomplete" [displayWith]="displayWrapper(inputSearchTypes[1].type)"
			(optionSelected)="optionSelected($event, inputSearchTypes[1].type)">
			<mat-option *ngFor="let coordinates of (coordsData$ | async)" [value]="coordinates"
				title="{{ setOption(coordinates, inputSearchTypes[1].type) }}">
				{{ setOption(coordinates, inputSearchTypes[1].type) }}
			</mat-option>
		</mat-autocomplete>
	</div>
</mat-form-field>

