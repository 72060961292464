import { Action } from '@ngrx/store';
import { InputSearchType } from '../../../models/input-search-types.model';

export enum ConnectedWorldActionTypes {
  RENDER_MAP_DATA = '[ConnectedWorld] RENDER_MAP_DATA',
  RESET_CW = '[ConnectedWorld] RESET_CW',
  SEARCH_TYPE = '[ConnectedWorld] SEARCH_TYPE'
}

export class RenderMapData implements Action {
  readonly type = ConnectedWorldActionTypes.RENDER_MAP_DATA;
  constructor(public mapData: any) {}
}

export class ResetCW implements Action {
  readonly type = ConnectedWorldActionTypes.RESET_CW;
  constructor() {}
}

export class SetSearchType implements Action {
  readonly type = ConnectedWorldActionTypes.SEARCH_TYPE;
  constructor(public searchType: InputSearchType) {}
}

export type ConnectedWorldActions = RenderMapData | ResetCW | SetSearchType ;
