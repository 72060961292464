import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material.module';
import {TableModule} from 'primeng/table';
import {DropdownModule} from 'primeng/dropdown';
import {ColorPickerModule} from 'primeng/colorpicker';
import {FileUploadModule} from 'primeng/fileupload';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TreeModule } from 'ng2-tree';
import { MccColorPickerModule } from 'material-community-components';
import { Ng5SliderModule } from 'ng5-slider';

import { SharedModule } from '../../shared/components/shared.module';

import { ConnectedWorldComponent } from './connected-world.component';
import { ConnectedWorldBaseComponent } from './connected-world-base.component';
import { MapQuoteComponent } from './map-quote/map-quote.component';
import { MapBuildingInfoComponent } from './map-building-info/map-building-info.component';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from 'app/app.reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'environments/environment';
import { MapLocationInfoComponent } from './map-location-info/map-location-info.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { AccountModule } from '../account/account.module';
import { QuickQuoteModalComponent } from './quick-quote-modal/quick-quote-modal.component';
import { QuickQuoteSheetComponent } from './quick-quote-sheet/quick-quote-sheet.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    MaterialModule,
    TableModule,
    DropdownModule,
    ColorPickerModule,
    FileUploadModule,
    FlexLayoutModule,
    TreeModule,
    MccColorPickerModule,
    Ng5SliderModule,
    SharedModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    MultiSelectModule,
    AccountModule
  ],
  declarations: [
    ConnectedWorldComponent,
    ConnectedWorldBaseComponent,
    MapQuoteComponent,
    MapBuildingInfoComponent,
    MapLocationInfoComponent,
    QuickQuoteModalComponent,
    QuickQuoteSheetComponent
  ],
  entryComponents: [
    MapQuoteComponent,
    MapBuildingInfoComponent,
    QuickQuoteModalComponent,
    QuickQuoteSheetComponent
  ]
})
export class ConnectedWorldModule { }
