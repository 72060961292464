import { Injectable } from '@angular/core';

import { SharedApiCallService } from './shared-api-call.service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  constructor(
    private sharedApiCallService: SharedApiCallService
  ) { }

  getAdminUsers(): Observable<any> {
    return this.sharedApiCallService.makeGetRequest(`/v1/accounts/admin-users`)
  }

  getAdminUsersStats(): Observable<any> {
    return this.sharedApiCallService.makeGetRequest(`/v1/accounts/admin-users/stats`).pipe(
      map(adminUsers => {
        for (const user of Object.keys(adminUsers)) {
          adminUsers[user] = this._prepareTreeNode(adminUsers[user][0]);
        }
        return adminUsers;
      })
    )
  }

  checkAccess(accessRule) {
    return this.sharedApiCallService.makeGetRequest(`/v1/accounts/admin-users/access?accessRule=${accessRule}`).pipe(
      map(res => res.body)
    )
  }

  getCompanyUsers(): Observable<any> {
    return this.sharedApiCallService.makeGetRequest(`/v1/accounts/account-users`).pipe(
      map(res => res.data)
    )
  }

  addToHierarchy(parentAccountId: number, childAccountId: number): Observable<any> {
    return this.sharedApiCallService.makePostRequest(`/v1/accounts/admin-users`, { parentAccountId, childAccountId })
  }

  removeFromHierarchy(userAccountId: number): Observable<any> {
    return this.sharedApiCallService.makeDeleteRequest(`/v1/accounts/admin-users/${userAccountId}`)
  }

  private _prepareTreeNode(node) {
    if (node.children.length) {
      node.children.forEach(child => {
        node[child.username] = child;

        return child.children.length ? this._prepareTreeNode(child) : child;
      });
    }

    return node;
  }
}
