import { Component, OnInit, HostListener, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OktaAuthService } from '@okta/okta-angular';
import * as OktaSignIn from '@okta/okta-signin-widget';
import config from '../../okta.config';

import { AuthenticationService } from '../../services/authentication/authentication.service';
import { AuthApiCallService } from '../../services/auth-api-call/auth-api-call.service';

import { OktaAuthService as AuthService } from '../../services/authentication/okta.authentication.service';

const DEFAULT_ORIGINAL_URI = window.location.origin;

let USE_INTERACTION_CODE = false;
if (config.oidc.useInteractionCodeFlow === 'true') {
  USE_INTERACTION_CODE = true;
}
@Injectable()
@Component({
  selector: 'app-okta-callback',
  templateUrl: './okta-callback.component.html',
  styleUrls: ['./okta-login.component.scss']
})
export class OktaCallbackComponent implements OnInit {
  signIn: any;
  pageLoading: boolean = false;
  isC2fUser: boolean = true;
  unKnownUser: any;
  displayUpperImage: boolean;
  fromIdpLogin: any;
  isValidEmail: boolean = false
  constructor(private apiCallService: AuthApiCallService,
    private authenticationService: AuthenticationService,
    public authService: AuthService,
    public oktaAuth: OktaAuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.isC2fUser = true;
    this.signIn = new OktaSignIn({
      baseUrl: config.oidc.issuer.split('/oauth2')[0],
      clientId: config.oidc.clientId,
      redirectUri: config.oidc.redirectUri,
      authParams: {
        issuer: config.oidc.issuer,
        scopes: config.oidc.scopes,
      },
      useInteractionCodeFlow: USE_INTERACTION_CODE,
    });

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.displayUpperImage = event.target.innerWidth <= 960;
  }

  ngOnInit() {

    let that = this
    if (window.innerWidth <= 960) { this.displayUpperImage = true; }
    this.activatedRoute.queryParams.subscribe(params => {
      this.fromIdpLogin = params['fromLogin'];
    });
    // When navigating to a protected route, the route path will be saved as the `originalUri`
    // If no `originalUri` has been saved, then redirect back to the app root
    const originalUri = this.oktaAuth.getOriginalUri();
    if (!originalUri || originalUri === DEFAULT_ORIGINAL_URI) {
      this.oktaAuth.setOriginalUri('/');
    }

    // after successful authentication with external IdP
    if (this.fromIdpLogin != undefined) {
      this.signIn.authClient.session.get().then(response => {
        if (response.status == 'MFA_ENROLL' || response.status == 'MFA_REQUIRED') {
          const payload = { 'email': response.login };
          that.pageLoading = true;
          that.apiCallService.makePostRequest('/v1/user/ssoLogin/okta', payload).subscribe(apiRes => {
            that.authenticationService.updatAccountUser(apiRes);
          });
          that.apiCallService.makePostRequest('/v1/user/oktaAuthn', payload).subscribe(authResponse => {
            const oktaAuthInfo = { status: authResponse.status, data: authResponse, user: authResponse._embedded.user }
            localStorage.setItem('oktaAuthInfo', JSON.stringify(oktaAuthInfo));
            that.pageLoading = false;
            that.router.navigateByUrl('/mfa-authenticator');
          });
        }
        else if(response.status == 'INACTIVE'){
          that.pageLoading = false;
          that.router.navigate(['/okta-login'], { queryParams: { idp_error: 'resource_not_found' } });
        }
      }).catch(error => {
        that.pageLoading = false;
        that.router.navigate(['/okta-login'], { queryParams: { idp_error: 'resource_not_found' } });
      });

      this.signIn.authClient.session.exists().then(function (sessionExists) {
        if (sessionExists) {
          that.pageLoading = true;
          that.signIn.authClient.token.getWithoutPrompt().then(function (response) {
            that.signIn.authClient.tokenManager.setTokens(response.tokens);
            const idToken = response.tokens.idToken
            const body = { 'email': idToken.claims.email };
            that.apiCallService.makePostRequest('/v1/user/ssoLogin/okta', body).subscribe(apiRes => {
              let lastUsedAccount = apiRes.account_list.find(account => account.id === apiRes.user.last_used_account_id);
              apiRes.account_list = apiRes.account_list.filter(account => account.display_role_name !== "Portal User");
              const userCurrentAccountId = lastUsedAccount ? lastUsedAccount.company_id : apiRes.account_list[0].company_id;
              localStorage.setItem('token', apiRes['session_token']);
              localStorage.setItem('selected_company_id', `${userCurrentAccountId}`);
              const account = lastUsedAccount ? lastUsedAccount : apiRes.account_list[0];
              that.authenticationService.onAccountChange(apiRes, account);
              that.router.navigateByUrl('/');
            },
              error => {
                that.isC2fUser = false;
                that.pageLoading = false;
                that.authService.logout();
                that.unKnownUser = { 'email': idToken.claims.email, 'name': idToken.claims.name }
              }
            );
          }).catch(error => {
            that.pageLoading = false;
            that.isC2fUser = false;            
            that.router.navigate(['/okta-login'], { queryParams: { idp_error: 'user_not_assigned'} });
          });
        }
      }).catch(error => {
        that.router.navigateByUrl('/okta-login');
      });
    } else {
      that.router.navigateByUrl('/okta-login');
    }
  }

  back() {
    this.router.navigateByUrl('/login');
  }

  ngOnDestroy() {
    this.signIn.remove();
  }
}
