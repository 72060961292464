<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    [ngClass]="{hidden: !(isHandset$ | async)}"
    fixedInViewport="false"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="!(isHandset$ | async)">
    <mat-toolbar class="mob_nav_header" color="primary">
      <div mat-card-avatar class="example-header-image">
        <img alt="{{ accountInfo.company_name}}" *ngIf="!accountInfo.company_logo" title="{{ accountInfo.company_name}}" src="/assets/images/avatar.svg"/>
        <img alt="{{ accountInfo.company_name}}" *ngIf="accountInfo.company_logo" title="{{ accountInfo.company_name}}" src="{{ headerService.companyLogo(accountInfo) }}"/>
      </div>
      <div class="user_info">
        <span>{{ headerService.companyUsername(accountInfo) }}</span>
        <span (click)="drawer.close(); headerService.openProfileDialog(accountInfo)">Edit Profile</span>
      </div>
    </mat-toolbar>
    <mat-nav-list>
      <mat-divider></mat-divider>
      <a mat-list-item class="drop_down" (click)="showMyContainer=!showMyContainer"><mat-icon class="log_icn">keyboard_arrow_down</mat-icon>Settings</a>
      <div (click)="drawer.close()" *ngIf="showMyContainer">
          <a mat-list-item (click)="headerService.openProfileDialog(accountInfo)"><mat-icon class="log_icn">face</mat-icon>Profile</a>
          <a mat-list-item (click)="headerService.showPanel()"><mat-icon class="log_icn">help</mat-icon>Help</a>
          <a mat-list-item (click)="headerService.showVersion()"><mat-icon class="log_icn">info</mat-icon>About</a>
          <a mat-list-item (click)="headerService.feedback()"><mat-icon class="log_icn">library_books</mat-icon>Feedback</a>
          <a mat-list-item (click)="headerService.openResetView()"><mat-icon class="log_icn">lock_open</mat-icon>Reset Password</a>
          <a mat-list-item (click)="headerService.changeCompany()"><mat-icon class="log_icn">input</mat-icon>Change Company</a>
      </div>
      <a mat-list-item (click)="headerService.logout()"><mat-icon class="log_icn">lock</mat-icon>Logout</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary" class="flex-btw">
      <img class="logo-header" title="cb_logo" src="{{ headerService.companyLogo(accountInfo) }}"/>
      <div class="flex">
      <div class="searchbar flx" id="mob_search">
        <span class="search_icon icon_click" [ngClass]="status ? 'active' : 'danger'" (click)="searchClickFunction()"> <mat-icon>{{status ? 'close' : 'search'}}</mat-icon></span>
        <app-address-autocomplete *ngIf="status" placeholder="Search Location" class="search_input" (selectItem)="setMapboxAddress($event)"></app-address-autocomplete>
      </div>
      <div><img class="header-icon m-t-10" (click)="headerService.showFilterSideNav();" src="/assets/images/filter.svg" matTooltip="Filter"/></div>
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      </div>
    </mat-toolbar>
    <!-- Add Content Here -->
  </mat-sidenav-content>
</mat-sidenav-container>
