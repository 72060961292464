import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/* Routing Components */
import { ConnectedWorldComponent } from './components/connected-world/connected-world.component';

/* Auth Guard Service */
import { AuthGuardService } from './modules/authentication/services/auth-guard/auth-guard.service';
import { TermsConditionComponent } from './components/terms-condition/terms-condition.component';

const routes: Routes = [
  {
    path: ':company',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        redirectTo: 'portal',
        pathMatch: 'full',
        canActivate: [
          AuthGuardService
        ]
      },
      {
        path: 'portal' ,
        component: ConnectedWorldComponent,
        canActivate: [
          AuthGuardService
        ]
      },
      {
        path: 'terms-condition',
        component: TermsConditionComponent,
        canActivate: [AuthGuardService],
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }


