import {Action} from '@ngrx/store';

export const ADDRESS_BACKROUTE = '[UI] ADDRESS BACK ROUTE';

export class AddressBackroute implements Action {
  readonly type = ADDRESS_BACKROUTE;
  constructor(public payload: boolean) {}
}

export const SHOW_TOPNAV_ICON = '[UI] SHOW TOPNAV ICON';

export class ShowTopnavIcon implements Action {
  readonly type = SHOW_TOPNAV_ICON;
  constructor(public payload: boolean) {}
}

export const DISPLAY_METADATA = '[UI] DISPLAY METADATA';

export class DisplayMetaData implements Action {
  readonly type = DISPLAY_METADATA;
  constructor(public payload: boolean) {}
}

export const SET_STEP_INDEX = '[UI] SET CURRENT STEP INDEX';

export class SetStepIndex implements Action {
  readonly type = SET_STEP_INDEX;
  constructor(public payload: number) {}
}

export const CPQ_DISABLE = '[UI] CPQ DISABLE';

export class CpqDisable implements Action {
  readonly type = CPQ_DISABLE;
  constructor(public payload: boolean) {}
}

export const CPQ_SPINNER = '[UI] CPQ SPINNER';

export class CpqSpinner implements Action {
  readonly type = CPQ_SPINNER;
  constructor(public payload: boolean) {}
}

export type UiActions =
  AddressBackroute|
  DisplayMetaData |
  ShowTopnavIcon |
  SetStepIndex |
  CpqDisable |
  CpqSpinner;
