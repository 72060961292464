<mat-form-field>
  <mat-select
    placeholder="Page Limit"
    [(ngModel)]="limit"
    (selectionChange)="onChangePageLimit($event)">
    <mat-option *ngFor="let option of pageLimitOptions" [value]="option">
      {{ option }}
    </mat-option>
  </mat-select>
</mat-form-field>
