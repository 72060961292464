import {Action} from '@ngrx/store';

export const FETCH_ADJACENT_BUSINESSES = 'FETCH_ADJACENT_BUSINESSES';

export class FetchAdjacentBusinesses implements Action {
  readonly type = FETCH_ADJACENT_BUSINESSES;
  constructor(public payload: any) {}
}

export const SET_ADJACENT_BUSINESSES = 'SET_ADJACENT_BUSINESSES';

export class SetAdjacentBusinesses implements Action {
  readonly type = SET_ADJACENT_BUSINESSES;
  constructor(public payload: any[]) {}
}

export type AdjacentBusinessesActions = FetchAdjacentBusinesses | SetAdjacentBusinesses;
