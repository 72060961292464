import { ForgotPasswordService } from '../../services/forgot-password/forgot-password.service';
import {Component, HostListener, OnInit} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

/* Services Start */
import { AuthenticationService } from '../../services/authentication/authentication.service';
/* Services End */

// Other
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  showForgotComp = false;
  myForm: FormGroup;
  title: string;
  displayUpperImage: boolean;
  resetEmailProceed: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private forgotPasswordService: ForgotPasswordService,
    private router: Router,
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.displayUpperImage = event.target.innerWidth <= 960;
  }

  ngOnInit() {
    if (window.innerWidth <= 960) { this.displayUpperImage = true; }
    this.title = environment.applicationTitle;
    this.checkLocalStorage();
    this.myForm = new FormGroup({
      email: new FormControl(null, [
        Validators.required,
        Validators.email
      ])
    });
  }

  back(){
    (environment.application === 'PORTAL')
    ? this.router.navigateByUrl(`/${this.authenticationService.activeCompany}/login`)
    : this.router.navigateByUrl('/login');
  }

  onSubmit() {
    if (environment.application === 'PORTAL') {
      this.forgotPasswordService.sendEmailPortal(this.myForm.value.email, window.location.href)
        .subscribe(data => {
          if (data.message === 'Success') {
            this.resetEmailProceed = true;
          }
        }, () => this.resetEmailProceed = true);
    } else {
      this.forgotPasswordService.sendEmail(this.myForm.value.email)
        .subscribe(data => {
          if (data.message === 'Success') {
            this.resetEmailProceed = true;
          }
        }, () => this.resetEmailProceed = true);
    }
  }

  checkLocalStorage() {
    const token = localStorage.getItem('token');
    if (token) {
      this.validateRoute();
    } else {
      this.showForgotComp = true;
    }
  }

  validateRoute() {
    this.authenticationService.isValidUser().subscribe(
      (res) => {
        this.showForgotComp = false;
        this.router.navigateByUrl('/');
      },
      (error) => {
        this.showForgotComp = true;
      });
  }

}
