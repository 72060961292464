import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { ApiCallService } from './api-call.service';

@Injectable()
export class CWService {
  private _circuitPopupStr: string;
  private _locationPopupStr: string;
  private _searchPopupStr: string;
  private _attLocationPopupStr: String;

  markerObject: string;
  private _markerSource: BehaviorSubject<any> = new BehaviorSubject(this.markerObject);
  get markerSource() {
    return this._markerSource;
  }
  updateMarkerSource(newValue) {
    this._markerSource.next(newValue);
  }
  resetMarkerSource() {
    this.markerObject = undefined;
    this._markerSource = new BehaviorSubject(undefined);
  }

  private _reportTypes: any[] = [];
  get reportTypes() {
    return this._reportTypes;
  }
  set reportTypes(values) {
    this._reportTypes = values;
  }

  private _locationData: any;
  get locationData() {
    return this._locationData;
  }
  set locationData(value) {
    this._locationData = value;
  }

  private defaultBuilding: any;
  private _defaultBuildingSource: BehaviorSubject<any> = new BehaviorSubject(this.defaultBuilding);
  get defaultBuildingSource() {
    return this._defaultBuildingSource;
  }
  updateDefaultBuildingSource(newValue) {
    this._defaultBuildingSource.next(newValue);
  }
  resetDefaultBuildingSource() {
    this.defaultBuilding = undefined;
    this._defaultBuildingSource = new BehaviorSubject(undefined);
  }

  private defaultViewCoordinates: any[];
  private _defaultMapViewSource: BehaviorSubject<any> = new BehaviorSubject(this.defaultViewCoordinates);
  public get defaultMapViewSource() {
    return this._defaultMapViewSource;
  }
  public updateDefaultMapViewSource(newVal) {
    this._defaultMapViewSource.next(newVal);
  }
  public resetDefaultMapViewSource() {
    this.defaultViewCoordinates = undefined;
    this._defaultMapViewSource = new BehaviorSubject(undefined);
  }

  constructor(private apiCallService: ApiCallService) {

  }

  get circuitPopupStr() {
    return this._circuitPopupStr;
  }
  get locationPopupStr() {
    return this._locationPopupStr;
  }
  get searchPopupStr() {
    return this._locationPopupStr;
  }

  get attLocationPopupStr() {
    return this._attLocationPopupStr;
  }

  readPopupStr(flag) {
    let fileUrl = flag == 'location' ? '/assets/map_resources/html/location-popup.html' : '/assets/map_resources/html/search-popup.html';
    this.apiCallService.readFile(fileUrl, { responseType: 'text' }).subscribe(res => {
      if(flag == 'location') this._locationPopupStr = res.toString();
      else if(flag == 'search') this._searchPopupStr = res.toString();
      else this._circuitPopupStr = res.toString();
    })
  }

  readAttPopupStr(flag) {
    let fileUrl = flag == 'location' ? '/assets/map_resources/html/att-location-popup.html' : '/assets/map_resources/html/search-popup.html';
    this.apiCallService.readFile(fileUrl, { responseType: 'text' }).subscribe(res => {
      if(flag == 'location') this._attLocationPopupStr = res.toString();
      else if(flag == 'search') this._searchPopupStr = res.toString();
      else this._circuitPopupStr = res.toString();
    })
  }


  saveFilters(payload) {
    return this.apiCallService.processHttpRequest('POST', '/api/v1/connectedworld/users/filters', undefined, payload,
      undefined, undefined);
  }

  retrieveFavoriteBuildings(callback) {
    this.apiCallService.processHttpRequest('GET', '/api/v2/connectedworld/buildings/favorite', undefined, undefined,
      (response => callback(response)), undefined);
  }

  private _routeInfo = undefined;
  private _routeInfoSource: BehaviorSubject<any> = new BehaviorSubject(this._routeInfo);
  get routeInfoSource() {
    return this._routeInfoSource;
  }
  updateRouteInfoSource(newValue) {
    this._routeInfoSource.next(newValue);
  }
  resetRouteInfoSource() {
    this._routeInfo = undefined;
    this._routeInfoSource = new BehaviorSubject(undefined);
  }

  private _mxZoomFeatures = [];
  set mxZoomFeatures(zoomFeatures) {
    this._mxZoomFeatures = zoomFeatures;
  }
  get mxZoomFeatures() {
    return this._mxZoomFeatures;
  }

  private _selectedLayerIds = [];
  set selectedLayerIds(layerIds) {
    this._selectedLayerIds = layerIds;
  }
  get selectedLayerIds() {
    return this._selectedLayerIds;
  }

  private _attProductInfo = undefined;

  private _attProduct: BehaviorSubject<any> = new BehaviorSubject(this._attProductInfo);
  get attProduct() {
    return this._attProduct;
  }
  updateAttProduct(newValue) {
    this._attProduct.next(newValue);
  }

  resetValues() {
    this.resetMarkerSource();
    this._locationData = undefined;
    this.resetDefaultBuildingSource();
    this.resetDefaultMapViewSource();
    this.resetRouteInfoSource();
    this._mxZoomFeatures = [];
  }
}
