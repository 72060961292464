import { Action } from '@ngrx/store';

export enum ConnectedWorldDataActionTypes {
  CW_SEARCH_PAYLOAD = '[ConnectedWorldData] CW_SEARCH_PAYLOAD',
}

export class CWSearchPayload implements Action {
  readonly type = ConnectedWorldDataActionTypes.CW_SEARCH_PAYLOAD;
  constructor(public payload: any) {}
}

export type ConnectedWorldDataActions = CWSearchPayload ;
