import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {isEmpty} from 'lodash';
import {Observable, of} from 'rxjs';

import {catchError, debounceTime, distinctUntilChanged, map, mergeMap} from 'rxjs/operators';
import {environment} from 'environments/environment';
import {LookupService} from 'app/services/lookup/lookup.service';
import {ApiCallService} from 'app/services/api-call.service';
import {AddressService} from '../../../modules/shared-components/services/address.service';
import {select, Store} from '@ngrx/store';
import * as fromStore from '../../../app.reducers';
import { InputSearchType } from '../../../models/input-search-types.model';
import { inputSearchTypes } from '../../../models/input-search-types.initial';
import { AutocompleteService } from 'app/modules/shared-components/services/autocomplete/autocomplete.service';
import { CommonUtilityService } from 'app/services/common/common-utility.service';
import { HttpErrorResponse } from '@angular/common/http';
import { RenderMapData } from 'app/components/connected-world/store/connected-world.actions';

@Component({
  selector: 'app-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.scss']
})
export class AddressAutocompleteComponent implements OnInit {

  @Input()
  address;

  @Input()
  type;

  @Input()
  placeholder;

  @Output()
  selectItem: EventEmitter<any> = new EventEmitter();

  @Output()
  searchEnter: EventEmitter<any> = new EventEmitter();

  locationCtrl = new FormControl();
  locationData$: Observable<any[]>;
  coordsCtrl = new FormControl();
  coordsData$: Observable<any[]>;
  enterpriseCtrl = new FormControl();
  enterpriseData$: Observable<any[]>;

  inputSearchType: string = inputSearchTypes[0].type;
  inputSearchType$: Observable<InputSearchType>;
  inputSearchTypes: InputSearchType[] = inputSearchTypes;

  constructor(
    private lookupService: LookupService,
    private apiCallService: ApiCallService,
    private addressService: AddressService,
    private store: Store<fromStore.AppState>,
    private _autocompleteService: AutocompleteService,
    private commonUtilityService: CommonUtilityService,
  ) {}

  ngOnInit() {
    this.inputSearchType$ = this.store.pipe(select(state => state['connectedWorldSearch']['searchType']));
    this.locationData$ = this.combineOperators(this.locationCtrl.valueChanges, 0);
    this.coordsData$ = this.combineOperators(this.coordsCtrl.valueChanges, 1);
    this.enterpriseData$ = this.combineOperators(this.enterpriseCtrl.valueChanges, 2);
  }

  combineOperators = (controller, id) => controller.pipe(
    debounceTime(400),
    distinctUntilChanged(),
    mergeMap((query: string) => (2 < query.length) ? this.byCommandType(query, this.inputSearchTypes[id].type) : []),
    catchError(() => [])
  )

  byCommandType(query, type) {
    this.inputSearchType = type;
    switch (type) {
      case this.inputSearchTypes[0].type: return this.lSearchType(query);
      case this.inputSearchTypes[1].type: return this.cSearchType(query);
      // case this.inputSearchTypes[2].type: return this.eSearchType(query);
    }
  }

  cSearchType(query: string) {
    return this.searchCoordinates(query);
  }

  // eSearchType(query: string) {
  //   return null;
  // }

  lSearchType(query: string) {
    // turn this into a function before you insert a query into a method.
    const cleanQuery: string = ''.concat(query).replace('/l', '');
    return (this.type === 'address') ? this.searchMapboxLocations(cleanQuery) : this.searchAllLocations(query);
  }

  setOption(location, inputSearchType) {
    switch (inputSearchType) {
      case inputSearchTypes[0].type: return location.properties.buildingid ? location.properties.title : location.place_name;
      case inputSearchTypes[1].type: return location.full_address;
      // case inputSearchTypes[2].type: return null;
      default: return location.properties.buildingid ? location.properties.title : location.place_name;
    }
  }

  displayPlaceName = (item, inputSearchType) => {
    switch (inputSearchType) {
      case inputSearchTypes[0].type: return item ? (item.place_name ? item.place_name : item.properties.title) : undefined;
      case inputSearchTypes[1].type: return item ? item.full_address : undefined;
      // case inputSearchTypes[2].type: return null;
      default: return item ? (item.place_name ? item.place_name : item.properties.title) : undefined;
    }
  }

  displayWrapper(inputSearchType) {
    return (item) => this.displayPlaceName(item, inputSearchType);
  }

  optionSelected($event, inputSearchType) {
    switch (inputSearchType) {
      case inputSearchTypes[0].type: return this.selectItem.emit($event.option.value);
      case inputSearchTypes[1].type: return this.searchAllLocationsByCoordinates($event.option.value.full_address);
      // case inputSearchTypes[2].type: return null;
      default: return this.selectItem.emit($event.option.value);
    }
  }

  checkIfEmpty(event) {
    if (this.placeholder === 'Enter Address' && (!event.target.value || event.target.value.trim() === '')) {
      this.selectItem.emit(undefined);
    }
  }

  clear(){
    this.locationCtrl.setValue('')
    this.coordsCtrl.setValue('')
  }
  
  /**
   * Search location by coordinates and hit enter in search bar,
   * @param event
   */
  onSearchEnter(event){
      let latLong = event.target.value.trim();
      let latLongArray = latLong.split(",");
      if(latLongArray.length == 2 && !isNaN(latLongArray[0]) && !isNaN(latLongArray[1])){
        this.getGLIDByLatAndLong(latLongArray[0].trim(), latLongArray[1].trim()).subscribe(
          result => {
            let title = latLongArray[0].trim() + ', ' + latLongArray[1].trim();
            let obj = {};
            obj['type'] = "Feature";
            let geometry = {};
            let coordinates = [latLongArray[1], latLongArray[0]];
            geometry['coordinates'] = coordinates;
            geometry['type'] = "Point";
            obj['geometry'] = geometry;
            obj['text'] = "";
            obj['properties'] = {'title' : title , 'glid' : (result && result.glid) ? result.glid : ""};
            this.searchEnter.emit(obj);
        }, (error =>{ 
          this.commonUtilityService.openSnackBar(error.error)
          }))
      }
  }

  /**
   * Search coordinates
   * and fetch results
   */
  private searchCoordinates(query) {
    const coordinates = query.split(',').map(item => item.trim());
    if (coordinates.length === 2 && coordinates[0].length !== 0 && coordinates[1].length !== 0) {
      const coords = {'lat': coordinates[0], 'lon': coordinates[1]};
      return this.addressService.standardAddressCoords(coords).pipe(
        map(apiResponse => apiResponse['addresses']),
        map((addresses: [any]) => addresses.map(address => this.createFullAddress(address))),
        catchError(() => []));
     }
    return [];
  }

  private searchAllLocationsByCoordinates(full_address) {
    return this.searchAllLocations(full_address).subscribe(apiRes => {
      if (!apiRes || !apiRes[0]) return this.selectItem.emit(null);
      return this.selectItem.emit(apiRes[0]);
    });
  }

  // todo: add interface
  createFullAddress(addressObj: any): string {
    const address = addressObj.address + ', ';
    const city = addressObj.city + ', ';
    const state = (addressObj.state_abbreviation) ? addressObj.state_abbreviation + ', ' : '';
    const country = addressObj.country;
    addressObj.full_address = address + city + state + country;
    return addressObj;
  }

  /**
   * Search internal building of instance,
   * if not found, then fetch external resources
   * @param query
   */
  private searchAllLocations(query) {
    const params = { query: query };
    return this.apiCallService.makeHttpRequest('GET', '/api/v2/search/locations', params, undefined).pipe(
      catchError((error: HttpErrorResponse ) => {
        this.commonUtilityService.openSnackBar(error.error);
        return of(null);
    }),
      map(res => (res ? res : [])))
  }

  /**
   * Search mapbox geocode api
   * and fetch results
   * @param query
   */
  private searchMapboxLocations(query) {
    let url = environment.mapboxGeocodeUrl + encodeURIComponent(query) + '.json?access_token=' + environment.mapboxKey + '&language=en';
    if (this.type === 'address')
      url = url + '&types=address';
    return this.apiCallService.makeExternalGetRequest(url).pipe(
      map(response => {
          return (response && !isEmpty(response.features)) ? response.features : [];
        }
      ));
  }

  private getGLIDByLatAndLong(latitude, longitude){
    const params =  {
      latitude: latitude,
      longitude: longitude
    }
    return this.apiCallService.makeHttpRequest('GET', '/api/v2/locationUtility/getGlid', params, undefined);
  }

  onLocationSearch(event) {
    let addressKeyword = event.target.value.trim();
    this.store.dispatch(new RenderMapData({ type: 'stringSearch', data: addressKeyword }));
    return this.selectItem.emit(addressKeyword);
  }
}