import { Injectable, EventEmitter } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SharedApiCallService } from './shared-api-call.service';
import { FormatAddress } from '../utils/formatAddress.func';

@Injectable()
export class AddressService {

  public address: string;
  public name: string;
  public type: string;
  public location: string;
  public address_id: number;
  public addressEmitter = new EventEmitter<any>();
  public nameEmitter = new EventEmitter<any>();
  public typeEmitter = new EventEmitter<any>();

  constructor(
    private sharedApiCallService: SharedApiCallService
  ) {}

  saveAddress(address: string, companyName: string, type: string = '', location: string, address_id: number) {
    this.address = address;
    this.name = companyName;
    this.type = type;
    this.location = location;
    this.address_id = address_id;
    setTimeout(() => {
      this.addressEmitter.emit(this.address);
      this.nameEmitter.emit(this.name);
      this.typeEmitter.emit(this.type);
    }, 1)
  }

  standardizeAddresses(addresses: object): Observable<any> {
    return this.sharedApiCallService.makePostRequest(`/v1/standard-address`, { addresses }).pipe(
      map(res => FormatAddress.iconAddressValidate(FormatAddress.fullAddress(res['data'])))
    );
  }

  standardizeAddressesAsStrings(addresses: string[]): Observable<any> {
    return this.sharedApiCallService.makePostRequest(`/v1/standard-address/strings`, { addresses }).pipe(
      map(res => FormatAddress.iconAddressValidate(FormatAddress.fullAddress(res['data'])))
    );
  }

  getAddressesByCoords(coords: object[]): Observable<any> {
    return this.sharedApiCallService.makePostRequest(`/v1/standard-address/coords`, { coords }).pipe(
      map(res => FormatAddress.iconAddressValidate(FormatAddress.fullAddress(res)))
    );
  }

  standardAddressCoords(coords: {lat: string, lon: string}): Observable<any> {
    return this.sharedApiCallService.makeGetRequest(`/v1/standard-address/coords`, null, {...coords});
  }

  getAddress() {
    return this.address;
  }

  getLocation() {
    return this.location;
  }

  getAddressId() {
    return this.address_id;
  }
}
