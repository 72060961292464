import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-common-confirmation',
  templateUrl: './common-confirmation.component.html',
  styleUrls: ['./common-confirmation.component.scss']
})
export class CommonConfirmationComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CommonConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public confirmationData: any) { }

  ngOnInit() {
  }

}
