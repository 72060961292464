import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { isEmpty } from 'lodash';

import { AuthenticationService } from '../authentication/authentication.service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthGuardService implements CanActivate {

  private hasNetworkRadar: boolean;
  private adminAccess: boolean;
  private isListManagerEnabled: number;
  private allowNetworkFinder: boolean;
  private epAccessLevel: number;
  private allowMyRoutes: number;
  private allowMyAccount: boolean;
  private allowCPQ: number;

  constructor(
    public auth: AuthenticationService,
    public router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    // PORTAL
    this.auth
    .isAuthenticated$()
    .subscribe(user_information => {
      if (next.routeConfig.path == 'portal' && user_information !== null && !user_information) {
        this.auth.logout();
        return true
      }
    });
 
    if (!this.auth.isAuthenticated(next.queryParams.token)) {
      const company: string = this.auth.activeCompany;
      ( !isEmpty(company) ) ? this.router.navigate(['/' + company + '/login']) : this.router.navigate(['/login']);
      return false;
    }

    if (next.queryParams.token) {
      this.auth.initialUserValidation();
    }

    return this.auth.accountInfo$().pipe(
      map(account_information => {
        this.hasNetworkRadar = account_information['has_network_radar'];
        this.adminAccess = (account_information['isAdmin'] == true || account_information['isBillingAdmin'] == true || account_information['role_name'] == 'Carrier Relations Admin') ? true : false;
        this.isListManagerEnabled = account_information['is_list_manager_enabled'];
        this.allowNetworkFinder = account_information['allow_network_Finder'];
        this.epAccessLevel = account_information['ep_access_level'];
        this.allowMyRoutes = account_information['allow_my_routes'];
        this.allowMyAccount = account_information['allow_accounts'];
        this.allowCPQ = account_information['allow_cpq'];
        if (((next.routeConfig.path == 'networkradar') && (this.adminAccess == false || this.hasNetworkRadar == false)) ||
          (next.routeConfig.path == 'list_manager' && this.isListManagerEnabled == 0) ||
          (next.routeConfig.path == 'search-by-company' && !(this.epAccessLevel >= 0 && this.epAccessLevel <= 3)) ||
          (next.routeConfig.path == 'networkfinder' && this.allowNetworkFinder == false) ||
          (next.routeConfig.path == 'myAccounts' && this.allowMyAccount == false) ||
          (next.routeConfig.path == 'myRoutes' && this.allowMyRoutes == 0) ||
          (next.routeConfig.path == 'favorites' && this.allowCPQ == 0)) {
          this.router.navigate(['/connected_world']);
          return true;
        }
        return true;
      })
    );
  }
}
