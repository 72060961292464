import { AppConstants } from 'app/app.constants';
import { flatten, isEmpty } from 'lodash';
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import {map} from 'rxjs/operators';
import { LookupIdentifiers } from '../../shared/lookup-identifiers';
import { CompanyLookupService } from './company-lookup.service';
import { ApiCallService } from 'app/services/api-call.service';
import { SharedDataService } from './../shared-data.service';

import * as fromStore from 'app/app.reducers';
import { GetCategoryIcons } from 'app/shared/components/store/shared-data.actions';


@Injectable()
export class BuildingLookupService extends CompanyLookupService {

  constructor(apiCallService: ApiCallService,
      sharedDataService: SharedDataService,
      private store: Store<fromStore.AppState>) {
    super(apiCallService, sharedDataService);
  }

  public getBuildingStatusOptions(lookupType) {
    this.retriveLookupOptions(lookupType, this.buildingOptionsCallback.bind(this));
  }
  private buildingOptionsCallback(lookupType, response) {
    let result = {};
    result[lookupType] = response;
    this.sharedDataService.updateLookupSource(result);
  }

  public getCategoryOptions() {
    this.retriveLookupOptions(LookupIdentifiers.BUILDING_CATEGORY, this.categoryOptionsCallback.bind(this))
  }
  private categoryOptionsCallback(lookupType, response) {
    let categoryIcons = {};
    response.forEach((item: any) => {
      categoryIcons[item.id] = item.mapiconurl;
    });
    this.store.dispatch(new GetCategoryIcons(categoryIcons));
    this.sharedDataService.updateLookupSource({locationTypeRef: response});
  }

  public getBuildingOptions(api, lookupType) {
    this.retrieveBuildingOptions(api, lookupType, this.buildingOptionsCallback.bind(this));
  }
  public retrieveBuildingOptions(api, lookupType, callback) {
    this.apiCallService.processHttpRequest('GET', api, undefined, undefined,
      (response => callback(lookupType, response)), undefined);
  }

  public getCustomFields() {
    this.apiCallService.processHttpRequest('GET', '/api/v1/modules/1/fields', undefined, undefined,
      (response => {
        this.sharedDataService.updateLookupSource( { CUSTOM_FIELDS: this.filterCustomFields(response) } )
      }), undefined);
  }
  private filterCustomFields(response) {
    let customFields = [];
    response.forEach(item => {
      if(item.name && item.name.trim().length > 0) {
        item.name = item.name.toLowerCase();
        customFields.push(item);
      }
    });
    return customFields;
  }

  private _defaultProducts: object = undefined;
  /**
   * Retrieve all products and
   * their speed
   */
  public retrieveDefaultProducts() {
    this.apiCallService.processHttpRequest('POST', '/api/v1/data/service/speed', undefined,
                      undefined, (response => this._defaultProducts = response), undefined);
  }
  get defaultProducts() {
    return this._defaultProducts;
  }

  private _pricingGeographyOptions = [];
  get pricingGeographyOptions() {
    return this._pricingGeographyOptions;
  }
  fetchGeographyOptions(callback) {
    this.apiCallService.processHttpRequest('GET', '/api/v1/pricing/geography', undefined,
        undefined, (response => {
          this._pricingGeographyOptions = response.filter(e => e.geographyId <= 5);
          if(callback) callback();
        }),  undefined);
  }

  retrievePricingGeographies(type, companyId, nrCallback) {
    if( !isEmpty(this._pricingGeographyOptions) )
      this.fetchGeographies(type, companyId, nrCallback);
    else {
      this.fetchGeographyOptions(this.fetchGeographies.bind(this, type, companyId, nrCallback));
      /* this.apiCallService.processHttpRequest('GET', '/api/v1/pricing/geography', undefined,
        undefined, (response => {
          this._pricingGeographyOptions = response;
          this.fetchGeographies(type, companyId, nrCallback);
        }),  undefined); */

    }
  }

  fetchGeographies(type, companyId, nrCallback) {
    if( type == 'company' )
      this.retrieveCompanyGeographies(companyId, nrCallback);
    else
      this.retrieveSupplierGeographies(companyId, nrCallback);
  }

  private _companyGeographies = undefined;
  get companyGeographies() {
    return this._companyGeographies;
  }
  retrieveCompanyGeographies(companyId, nrCallback) {
    let api = '/api/v1/companies/' + companyId + '/geographies';
    this.apiCallService.processHttpRequest('GET', api, undefined,
      undefined, (response => {
        let comGeographies = response ? this.formatPricingGeographyOptions(response,
          'companyGeographyRuleId', false) : [];
        if(nrCallback)  nrCallback(comGeographies);
        else
          this._companyGeographies = comGeographies;

      }), undefined);
  }

  private _supplierGeographies = undefined;
  get supplierGeographies() {
    return this._supplierGeographies;
  }
  private _offnetGeographies = undefined;
  get offnetGeographies() {
    return this._offnetGeographies;
  }
  public retrieveSupplierGeographies(companyId, nrCallback) {
    let api = '/api/v1/companies/'+companyId+'/suppliergeographies';
    this.apiCallService.processHttpRequest('GET', api, undefined,
      undefined, (response => {
        this._supplierGeographies = response ? this.formatPricingGeographyOptions(response,
          'supplierGeographyRuleId', true) : [];

        this._offnetGeographies = response ? this.formatPricingGeographyOptions(response,
          'senderGeographyRuleId', true) : [];

        if(nrCallback)  nrCallback(this._supplierGeographies);
      }), undefined);
  }

  public formatPricingGeographyOptions(geographies, idAttr, supplier) {
    let geographyArr = [];
    geographies.forEach(item => {
      let geo = this.buildGeography(item, idAttr, supplier);
      if(geo) geographyArr.push(geo);
    });
    return geographyArr;
  }

  private buildGeography(geography, idAttr, supplier) {
    let values = [];
    if (geography.pricingGeographyRef.geographyId != AppConstants.CUSTOM_PRICING_ZONE_GEOGRAPHY
      && geography.pricingGeographyRef.geographyId != AppConstants.BOUNDARY_GEOGRAPHY)
      values = geography.geographyValues.map(e => e.text ? e.text : e);
    else
      values.push(geography.customZone);
      if( isEmpty(values) ) return ;
    let geo = {
      id: geography[idAttr],
      text: geography.ruleName,
      option_id: geography.pricingGeographyRef.geographyId,
      option_type: geography.pricingGeographyRef.geographyName,
      option_val: values, ruleOrder: geography.ruleOrder,
      label: (geography.ruleName + ' (' + geography.ruleOrder + ')'),
      spatial_id: geography.spatialBoundaryId
    };
    if(supplier) {
      geo['receiverCompanyId'] = geography.receiverCompanyId;
      geo['senderCompanyId'] = geography.senderCompanyId;
    }
    return geo;
  }


  private _pricingEntities = undefined;
  get pricingEntities() {
    return this._pricingEntities;
  }
  retrievePricingEntities(companyId) {
    let api = '/api/v1/companies/' + companyId + '/entities';
    this.apiCallService.processHttpRequest('GET', api, undefined,
          undefined, (response => this._pricingEntities = !response ? [] : response), undefined);
  }

  private _defaultUniServices = [];
  get defaultUniServices() {
    return this._defaultUniServices;
  }
  retrieveUniServices(callback) {
    this.apiCallService.processHttpRequest('GET', '/api/v1/data/uniservice/speed', undefined,
      undefined, (response => {
        this._defaultUniServices = response;
        if(callback)
          callback(response);
      }), undefined);
  }


  private _defaultNniServices = [];
  get defaultNniServices() {
    return this._defaultNniServices;
  }
  retrieveNniServices(callback) {
    this.apiCallService.processHttpRequest('GET', '/api/v1/data/nniservice/speed', undefined,
      undefined, (response => {
        this._defaultNniServices = response;
        if(callback)
          callback(response);
      }), undefined);
  }

  private _companyProducts;
  get companyProducts() {
    return this._companyProducts;
  }
  retrieveCompanyProducts(companyId, callback) {
    let params = { companyId: companyId };
    this.apiCallService.processHttpRequest('GET', '/api/v1/company/services', params, undefined,
      (res =>  {
        this._companyProducts = res;
        if(callback)
          callback(res);
      }),
      undefined);
  }

  private _companyUniProducts = undefined;
  get companyUniProducts() {
    return this._companyUniProducts;
  }
  retrieveCompanyUniProducts(callback) {
    this.apiCallService.processHttpRequest('GET', '/api/v1/company/uniService', undefined, undefined,
      (res =>  {
        let uniProducts = flatten(Object.values(res));
        this._companyUniProducts = uniProducts;
        if(callback)
          callback(uniProducts);
      }),
      undefined);
  }

  private _companyNniProducts = undefined;
  get companyNniProducts() {
    return this._companyNniProducts;
  }
  retrieveCompanyNniProducts(callback) {
    this.apiCallService.processHttpRequest('GET', '/api/v1/company/nniService', undefined, undefined,
      (res =>  {
        let nniProducts = flatten(Object.values(res));
        this._companyNniProducts = nniProducts;
        if(callback)
          callback(nniProducts);
      }),
      undefined);
  }

  resetValues() {
    this._companyGeographies = undefined;
    this._supplierGeographies = undefined;
    this._offnetGeographies = undefined;
    this._pricingEntities = undefined;
    this._companyProducts = undefined;
    this._companyUniProducts = undefined;
    this._companyNniProducts = undefined;
    this._defaultProducts = undefined;
  }


}
