import { Address } from '../models/address.model';
const parser = require('parse-address');
const val = require('validator');

export class FormatAddress {

  static parser(address, addressesInputHasValue) {
    const parsed = parser.parseLocation(address);

    return {
      fullAddress: `${this.isUndefined(parsed.number)} ${this.isUndefined(parsed.prefix)} ${this.isUndefined(parsed.street)} ${this.isUndefined(parsed.type)} ${this.isUndefined(parsed.suffix)} ${this.isUndefined(parsed.city)} ${this.isUndefined(parsed.state)}`.replace(/  +/g, ' ').trim(),
      address: `${this.isUndefined(parsed.number)} ${this.isUndefined(parsed.prefix)} ${this.isUndefined(parsed.street)} ${this.isUndefined(parsed.type)} ${this.isUndefined(parsed.suffix)}`.replace(/  +/g, ' ').trim(),
      city: this.isUndefined(parsed.city).trim(),
      state: this.isUndefined(parsed.state).trim(),
      zipcode: this.isUndefined(parsed.zip).trim(),
      country: addressesInputHasValue ? '' : address.split(',').slice(-1)[0]
    };
  }

  static isUndefined(v) {
    if (v === undefined) {
      return '';
    }
    return v;
  }

  static isEmpty(addressObj) {
    return (addressObj.address.length !== 0) && (addressObj.city.length !== 0) && (addressObj.state.length !== 0)
  }

  static formatKeys(passData) {
    const array = [];
    passData.forEach(e => {
      array.push(Object.keys(e).reduce((c, k) => (c[k.toLowerCase().replace(/ /g, '')] = e[k], c), {}));
    });
    return array;
  }

  static iconAddressValidate(passData) {
    if (!passData.message) {
      passData.forEach(e => {
        if (e.found) {
          e['valid'] = 'check_circle_outline';
        } else {
          e['valid'] = 'highlight_off';
        }
      });
    }
    return passData;
  }

  static fullAddress(passData) {
    if (!passData.message) {
      passData.forEach(e => {
        e['full_address'] = e.address;
        e['imported'] = this.getDate();
      });
    }
    return passData;
  }

  static getDate() {
    const currentdate = new Date();
    const datetime = currentdate.getDate() + '/'
      + (currentdate.getMonth() + 1)  + '/'
      + currentdate.getFullYear();
    return datetime;
  }

  static removeSampleKeys(passData) {
    return passData.map(element => ({
      'Address': element.address,
      'City': element.city,
      'State': element.state,
      'Zip Code': element.zipcode,
      'Country': element.country,
      'Latitude': element.latitude,
      'Longitude': element.longitude,
      'Address Z': element.addressZ,
      'City Z': element.cityZ,
      'State Z': element.stateZ,
      'Zip Code Z': element.zipcodeZ,
      'Country Z': element.countryZ,
      'Latitude Z': element.latitudeZ,
      'Longitude Z': element.longitudeZ,
    }));
  }
  static defaultSample(): Address[] {
    return [
      {
        address: '7505 John W. Carpenter Freeway',
        city: 'Lone Tree',
        state: 'TX',
        zipcode: '75247',
        country: 'USA'
      },
      {
        address: '10055 Library Way',
        city: 'Lone Tree',
        state: 'CO',
        zipcode: '80124',
        country: 'USA',
        addressZ: '755 Lee St',
        cityZ: 'Alexander City',
        stateZ: 'AL',
        zipcodeZ: '35010',
        countryZ: 'USA'
      },
      {
        address: '14810 Grasslands Dr',
        city: 'Englewood',
        state: 'CO',
        zipcode: '80112',
        country: 'USA',
        latitudeZ: '42.645343',
        longitudeZ: '-71.300342'
      },
      {
        address: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        latitude: '42.645343',
        longitude: '-71.300342',
        addressZ: '1 Infinite Loop',
        cityZ: 'Cupertino',
        stateZ: 'CA',
        zipcodeZ: '11111',
        countryZ: 'USA'
      },
    ];
  };

}
