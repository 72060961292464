import { includes } from 'lodash';
import { CommonUtil } from 'app/shared/utils/common-util';

export class MapboxUtil {
  public static parseMapboxAddress(mapboxFeature) {
    const location = {};

    location['street'] = !CommonUtil.isEmpty(mapboxFeature.address) ? (mapboxFeature.address + ' ' + mapboxFeature.text) :
            (!CommonUtil.isEmpty(mapboxFeature.properties.address) ? mapboxFeature.properties.address : mapboxFeature.text) ;
    location['latitude'] = mapboxFeature.geometry.coordinates[1];
    location['longitude'] = mapboxFeature.geometry.coordinates[0];
    if(mapboxFeature.context != undefined && mapboxFeature.context.length > 0)
      this.parseContext(location, mapboxFeature.context);
    return location;
  }

  private static parseContext(location, context) {
    context.forEach(function(item, index) {
			if(item.id.indexOf('locality.')>-1)
				location.street = location.street + ' ' + item.text;
			else if(item.id.indexOf('place.')>-1)
				location.city = item.text;
			else if(item.id.indexOf('postcode.')>-1)
				location.postalCode = item.text;
			else if(item.id.indexOf('region.')>-1)
				location.stateName = item.text;
			else if(item.id.indexOf('country.')>-1)
        location.countryName = (item.text == "United States of America") ? "United States" : item.text;
		});
  }

  public static findLocationZoom(item) {
		let zoom = 10;
		if(item.id.includes('place')) {
			zoom = 13;
			let placeArray = item.place_name.split(',');
			if(placeArray.length > 4)
				zoom = 17;
			else if(placeArray.length > 3 && placeArray <= 4)
				zoom = 15;
			else if(placeArray.length >= 0 && placeArray.length <= 3)
				zoom = (item.relevance > 0.89) ? 13 : 11
		}
		else if(item.id.includes('region')){
			let distance = item.bbox[3]-item.bbox[1];
			if(distance >= 20)
				zoom = 5;
			else if(distance >= 10)
				zoom = 6;
			else if(distance > 5 && distance < 10)
				zoom = 7;
			else if(distance >= 4 && distance <= 5)
				zoom = 8;
			else if(distance >= 1 && distance < 4)
				zoom = 9;
			else
				zoom = 12;
		}
		else if(item.id.includes('country')){
			zoom = 3;
			let distance = item.bbox[3]-item.bbox[1];
			if(distance >= 10)
				zoom = 6;
			else if(distance >= 8 && distance < 10)
				zoom = 7;
			else if(distance > 4 && distance < 8)
				zoom = 8;
			else if(distance > 0 && distance <= 4)
				zoom = 9;
		}
		else {
			zoom = 10;
		}
		return zoom;
	}

  public static parseMDLocation(mapmarketdata, markerdetails) {
    const location = {};
    location['buildingname'] = mapmarketdata.locationName;
    location['street'] = mapmarketdata.address1;
    location['latitude'] = mapmarketdata.latitude;
    location['longitude'] = mapmarketdata.longitude;
    location['city'] = mapmarketdata.city.text;
    location['postalCode'] = mapmarketdata.zip;
    location['stateName'] = mapmarketdata.state.text;
    location['countryName'] = mapmarketdata.country.text;
    location['countryId'] = mapmarketdata.country.id;

    location['msaname'] = mapmarketdata.csa;
    if (mapmarketdata.lotSize)
      location['lotsize'] = mapmarketdata.lotSize + '';
    if (mapmarketdata.yearBuilt)
      location['yearbuilt'] = (mapmarketdata.yearBuilt + '').length == 4 ? (mapmarketdata.yearBuilt + '') : '';
    location['zoningdescription'] = (mapmarketdata.zoning != null && mapmarketdata.zoning != undefined) ? mapmarketdata.zoning.text : '';
    if (mapmarketdata.stories)
      location['floors'] = mapmarketdata.stories + '';
    if (mapmarketdata.buildingSize)
      location['buildingsize'] = mapmarketdata.buildingSize + '';

    var buildingCategories = [];
    if(markerdetails.locationTypeIds && markerdetails.locationTypeIds != 'null') {
      let locationCategories = undefined;
      if(typeof markerdetails.locationTypeIds == 'string')
       locationCategories= JSON.parse(markerdetails.locationTypeIds);
      else
       locationCategories = markerdetails.locationTypeIds;
      locationCategories.forEach(item => buildingCategories.push({id: item}))
    }
    location['buildingCategory'] = buildingCategories;
    return location;
  }

  public static parseSearchedMDAddress(item) {
    const location = {};
    location['latitude'] = item.geometry.coordinates[1];
    location['longitude'] = item.geometry.coordinates[0];
    let addressData = item.properties.title.split(', ');
    location['street'] = addressData[0];
    location['city'] = addressData[1];
    if(addressData.length === 4) {
      location['stateName'] = addressData[2];
      location['countryName'] = addressData[3];
    }
    else {
      location['countryName'] = addressData[4];
      if( /\d/.test(addressData[2]) ) {
        location['postalCode'] = addressData[2];
        location['stateName'] = addressData[3];
      } else {
        location['stateName'] = addressData[2];
        location['postalCode'] = addressData[3];
      }
    }
    return location;
  }
}
