import {map, mergeMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import * as AdjacentBusinessesActions from './adjacentBusinesses.actions';

@Injectable()
export class AdjacentBusinessesEffects {

  @Effect()
  adjacentBusinessesFetch = this.actions$.pipe(
    ofType<AdjacentBusinessesActions.FetchAdjacentBusinesses>(AdjacentBusinessesActions.FETCH_ADJACENT_BUSINESSES),
    map((apiResponse) => {
      return {
        type: AdjacentBusinessesActions.SET_ADJACENT_BUSINESSES,
        payload: apiResponse
      };
    }));

  constructor(
    private actions$: Actions
  ) {}
}
