import { Action } from '@ngrx/store';
import { ConnectedWorldActions, ConnectedWorldActionTypes } from './connected-world.actions';
import { InputSearchType } from '../../../models/input-search-types.model';
import { inputSearchTypes } from '../../../models/input-search-types.initial';

export interface State {
  mapData: any,
  searchType: InputSearchType
}

export const initialState: State = {
  mapData: null,
  searchType: inputSearchTypes[0]
};

export function reducer(state = initialState, action: ConnectedWorldActions): State {
  switch (action.type) {

    case ConnectedWorldActionTypes.RENDER_MAP_DATA:
      return {
        ...state,
        mapData: action.mapData
      };
    case ConnectedWorldActionTypes.RESET_CW:
      return {
        ...state,
        mapData: null
      };
    case ConnectedWorldActionTypes.SEARCH_TYPE:
      return{
        ...state,
        searchType: action.searchType
      };
    default:
      return state;
  }
}
