import * as AlertActions from './alert.actions';

export interface State {
  message: any,
  alert_style: any,
  is_open: boolean
}

const initialState: State = {
  message: null,
  alert_style: null,
  is_open: false
};

export function alertReducer(state = initialState, action: AlertActions.AlertActions) {
  switch (action.type) {
    case AlertActions.SET_ALERT:
      return {
        ...state,
        message: action.payload
      };
    case AlertActions.SET_ALERT_STYLE:
      return {
        ...state,
        alert_style: action.payload
      };
    case AlertActions.OPEN_ALERT:
      return {
        ...state,
        is_open: action.payload
      };
    default:
      return state;
  }
}
