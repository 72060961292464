<div class="login-page-wrapper">
  <div *ngIf="displayUpperImage" class="upper-logo-image">
    <img src="../../../../../assets/connectbase.svg" alt="" width="150px" >
  </div>
  <div class="box-wrapper">
    <div class="login-card">
      <div class="login-form">
        <div class="title">{{title}}</div>
        <form [formGroup]="myForm" (ngSubmit)="onSubmit()" *ngIf="!showVzSSO">
          <mat-form-field class="full-width">
            <label for="email"></label>
            <input
              matInput
              placeholder="Email"
              type="text"
              id="email"
              formControlName="email">
          </mat-form-field>
          <mat-form-field class="full-width">
            <label for="password"></label>
            <input
              matInput
              placeholder="Password"
              type="password"
              id="password"
              autocomplete="on"
              formControlName="password">
          </mat-form-field>
          <div class="error" *ngIf="loginError">
            <mat-error>{{loginError}}</mat-error>
          </div>
          <div>
            <button
              mat-raised-button
              color="primary"
              type="submit"
              class="login-button"
              [disabled]="!myForm.valid">Login</button>
          </div>
        </form>
        <div *ngIf="showVzSSO">
          <button
            (click)="ssoLogin('verizon')"
            mat-raised-button
            color="verizon"
            type="submit"
            class="login-button verizon">Verizon SSO Login</button>
        </div>
        <div *ngIf="oktaSSO">
          <div class="third-party-join__container">
            <p class="third-party-join__reg-option"><span class="third-party-join__line-wrapper"><span
                  class="third-party-join__line"></span></span><span class="third-party-join__content"><span
                  class="third-party-join__or-span">or</span></span></p>
            <button (click)="oktaLoginWrapper()" type="button" class="sso-login-button">SSO Login</button>
          </div>
        </div>
      </div>
      <div class="extra-links" [ngClass]="extraLinkPosition">
        <div>
          <a class="link"
             (click)="forgotPassword()">
            Forgot Password?
          </a>
          </div>
        <div class="signup-link-wrapper" *ngIf="showSignupDiv">
          <div class="link link-space">Don't have an account?</div>
          <a class="link" routerLink="/signup">Sign Up</a>
        </div>
      </div>
    </div>
    <div class="company-logo-card">
      <div class="img-container"><img src="../../../../../assets/connectbase.svg" alt="" width="250px"></div>
    </div>
  </div>
  <div *ngIf="pageLoading" class="backdrop"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>

  <!--<div class="two-factor-card" *ngIf="twoFactor">-->
    <!--<app-two-factor-->
      <!--[email]="email"-->
      <!--[password]="password">-->
    <!--</app-two-factor>-->
  <!--</div>-->
</div>
