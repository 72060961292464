import { Injectable } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef, MatBottomSheetConfig } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { InfoService } from './info.service';
import { AppConstants } from 'app/app.constants';

@Injectable()
export class BottomSheetService {
  private bottomSheetRef: MatBottomSheetRef ;

  private intnData: any;
  private _intnDataSource: BehaviorSubject<any> = new BehaviorSubject(this.intnData);
  get intnDataSource() {
    return this._intnDataSource;
  }
  updateIntnDataSource(newValue) {
    this._intnDataSource.next(newValue);
  }


  constructor(private bottomSheet: MatBottomSheet, private infoService: InfoService) {}

  public getBottomSheetRef() {
    return this.bottomSheetRef;
  }
  public setBottomSheetRef(bottomSheetRef) {
    this.bottomSheetRef = bottomSheetRef;
  }

  public openMxBottomSheet(data, component, event) {
    if(!this.bottomSheetRef) {
      let config: MatBottomSheetConfig = { data: data, disableClose: true, hasBackdrop: false, panelClass: 'mx-btm-sheet-panel'};
      const ref = this.bottomSheet.open(component, config);
      this.setBottomSheetRef(ref);
    } else {
      this.updateIntnDataSource(data);
      if(event == AppConstants.CLICK_EVENT)
        this.infoService.notifyOther(InfoService.SHOW_TENANT_OVERLAY);
    }
  }

  public openBottomSheet(component, config) {
    const ref = this.bottomSheet.open(component, config);
    ref.afterDismissed().subscribe(() => this.setBottomSheetRef(undefined));
  }

  public removeBottomSheet() {
    this.bottomSheet.dismiss();
    this.bottomSheetRef = undefined ;
    this.resetValues();
  }

  resetValues() {
    this.bottomSheetRef = undefined ;
    this.intnData = undefined;
    this._intnDataSource = new BehaviorSubject(this.intnData);
  }

}
