import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { Router, RouterEvent, NavigationStart } from '@angular/router';

import { AuthenticationService } from '../modules/authentication/services/authentication/authentication.service';
import { SnackbarService } from '../modules/shared-components/services/snackbar.service';
import * as fromStore from 'app/app.reducers';
// tslint:disable-next-line:import-blacklist
import {Subscription} from 'rxjs/Rx';

import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {unsubscribe} from '../shared/utils/unsubscribe-util';
import { CompanyService } from '../services/company/company.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  /* Subscribers */
  private socketListen$ = new Subject();
  private userInformationSub: Subscription;
  private cpqPricingListen$ = new Subject();

  title = 'portal';
  public head = false;
  accountInfo;
  private userID: number;
  private room = '-room:';

  /* Observables */
  isAuthenticated$: Observable<boolean>;

  private _activeCompany: string;

  get activeCompany() { return this._activeCompany }
  set activeCompany(activeCompany: string) { this._activeCompany = activeCompany }

  constructor(
    public router: Router,
    private location: Location,
    private authenticationService: AuthenticationService,
    private store: Store<fromStore.AppState>,
    private _snackbarService: SnackbarService,
    private companyService: CompanyService
  ) {
    /* External Dependency: Authentication Module */
    this.authenticationService.initialUserValidation();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
  }

  ngOnInit() {

    this.router.events
      // filter for NavigationStart events only
      .filter((event: RouterEvent) => event instanceof NavigationStart)
      .subscribe((event: NavigationStart) => {
        const url = event.url === '/' ? '' : event.url;
        const pathMap = url.split('/');
        const firstPathPart = pathMap[1];
        // when refresh from terms-condition page, will navigate to login page
        const isTermsCond = pathMap.length > 2 ? pathMap[2] !== 'terms-condition' : true; 
        if (firstPathPart && isTermsCond){
          if (firstPathPart !== this.activeCompany) {
            this.activeCompany = firstPathPart;
          }
          this.authenticationService.activeCompany = this.activeCompany ? this.activeCompany : '';
        }
      });

    this.userInformationSub = this.authenticationService
      .userInformation$()
      .subscribe(user_information => {
        this.accountInfo = user_information.settings;
        this.userID = user_information['settings']['user_id'];
      });

    /// For multi-tab functionality
    window.onstorage = (e) => this.authenticationService.multiTabValidation(e);

    /* External Dependency: Authentication Module */
    this.isAuthenticated$ = this.authenticationService.isAuthenticated$();
  }

  ngOnDestroy() {
    unsubscribe(this.userInformationSub);
    this.socketListen$.unsubscribe();
    this.cpqPricingListen$.unsubscribe();
  }
}
