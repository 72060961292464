<div class="container" fxLayout fxLayoutAlign="center" fxLayoutGap.xs="4">
  <!-- <div class="item header" *ngIf="topBarColor" [style.background]="topBarColor"  fxFlex="100%" fxLayoutAlign="space-between center"> -->
    <div class="item header" fxFlex="100%" [style.background]="topBarColor" fxLayoutAlign="space-between center">
    <div class="logo_sec">
      <img
        style="float: left;"
        class="logo-header"
        alt="{{ accountInfo.company_name }}"
        *ngIf="!accountInfo.company_logo"
        title="{{ accountInfo.company_name }}"
        src="/assets/images/logo-white.png"
      />
      <img
        style="float: left;"
        class="logo-header"
        alt="{{ accountInfo.company_name }}"
        *ngIf="accountInfo.company_logo"
        title="{{ accountInfo.company_name }}"
        src="{{ headerService.companyLogo(accountInfo) }}"
      />
    </div>
    <div class="menus">
      <div *ngIf="accountInfo.is_att_portal && undefined !=att_products && att_products.length > 0" class="row">
        <div class="col prod-label">Product: </div>
        <div class="col">
        <form [formGroup]="attProductForm">
          <select class="att-prod-drop" formControlName="attProductControl" [(ngModel)]="currentAttProduct" (change)="selectAttProduct()"> 
            <option [ngValue]="att" *ngFor="let att of att_products">{{att.productName}}</option>
          </select>
        </form>
      </div>
      </div>

      <div *ngIf="router.url.includes('/portal')" style="display: flex; flex-direction: row;">
        <div *ngIf="status">
          <button mat-button [matMenuTriggerFor]="menuSearch">{{(inputSearchType$ | async).name}}</button>
          <mat-menu #menuSearch="matMenu">
            <button mat-menu-item *ngFor="let searchType of inputSearchTypes" (click)="onSearchType(searchType)">{{searchType.name}}</button>
          </mat-menu>
        </div>
        <div class="searchbar flx">
          <span
            class="search_icon icon_click"
            [ngClass]="status ? 'active' : 'danger'"
            (click)="searchClickFunction()">
            <mat-icon>{{ status ? "close" : "search" }}</mat-icon></span>
          <app-address-autocomplete
            *ngIf="status"
            #autocomplete
            placeholder="Search Location" 
            class="search_input"
            (selectItem)="setMapboxAddress($event)"
            (searchEnter)="setMapboxLatLong($event)"
          ></app-address-autocomplete>
        </div>
      </div>

      <!-- <div *ngIf="router.url.includes('/portal')"><img class="header-icon" (click)="headerService.showFilterSideNav();" src="/assets/images/filter.svg" matTooltip="Filter"/></div> -->
      <div><i class="material-icons header-icon" matTooltip="Upload" (click)="headerService.upload();">file_upload</i></div>
      <div (click)="inlineService.showPanel();"><i class="material-icons header-icon" matTooltip="Help">help</i></div>
      
      <div>
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          class="flex-box user">
          <div class="login-icon">{{ firstInitial }}</div>
        </button>
        <mat-menu #menu="matMenu">
          <!-- <button
            mat-menu-item
            (click)="headerService.navigateToAdmin(accountInfo)"
            *ngIf="adminAccess"
          >
            <mat-icon>person</mat-icon>
            <span>Admin</span>
          </button> -->
          <button
            mat-menu-item
            (click)="headerService.openProfileDialog(accountInfo)">
            <mat-icon>face</mat-icon>
            <span>Profile</span>
          </button>
          <button mat-menu-item (click)="headerService.showPanel()">
            <mat-icon>help</mat-icon>
            <span>Help</span>
          </button>
          <button mat-menu-item (click)="headerService.showVersion()">
            <mat-icon>info</mat-icon>
            <span>About</span>
          </button>
          <button mat-menu-item (click)="headerService.feedback()">
            <mat-icon>library_books</mat-icon>
            <span>Feedback</span>
          </button>
          <button mat-menu-item (click)="headerService.openResetView()">
            <mat-icon>lock_open</mat-icon>
            <span>Reset Password</span>
          </button>
          <!-- <button mat-menu-item (click)="headerService.changeCompany()">
            <mat-icon>input</mat-icon>
            <span>Change Company</span>
          </button> -->
          <button mat-menu-item (click)="headerService.logout()">
            <mat-icon>lock</mat-icon>
            <span>Logout</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
<app-main-nav></app-main-nav>
