<div class="relative-el">
  <div class="address-import-modal">
    <div>
      <div
        *ngIf="validationSpinner"
        class="spinner-wrap d-flex justify-content-center w-100 h-100">
        <mat-progress-spinner
          color="primary"
          diameter="50"
          mode="indeterminate">
        </mat-progress-spinner>
      </div>
      <h2 class="m-0 mb-1">Step 1: Add Addresses</h2>
      <!-- <mat-divider></mat-divider> -->
      <form #f="ngForm" class="mt-1">
        <div class="d-flex align-items-center" *ngIf="isP2PMode">
          <mat-form-field class="flex-1">
            <input
              matInput
              name="locationA"
              [matAutocomplete]="locationA"
              [formControl]="searchControlA"
              placeholder="Address A Autocomplete">
            <mat-icon
              matSuffix
              matTooltip="When entering your address: Enter the complete and exact street address for your location, 5 digit zip code is optional. For example: 1600 Amphitheatre Parkway, Mountain View, CA, 94043 or 1600 Amphitheatre Parkway, Mountain View, CA"
              class="vert-align-middle cursor-pointer color-gray">
              help
            </mat-icon>
          </mat-form-field>
          <mat-autocomplete #locationA="matAutocomplete">
            <mat-option
              *ngFor="let option of locationAOptions"
              [value]="option.place_name">
              {{ option.place_name }}
            </mat-option>
          </mat-autocomplete>
          <h3 class="color-gray mx-1">&ndash;</h3>
          <mat-form-field class="flex-1">
            <input
              matInput
              name="locationZ"
              [matAutocomplete]="locationZ"
              [formControl]="searchControlZ"
              placeholder="Address Z Autocomplete">
            <mat-icon
              matSuffix
              matTooltip="When entering your address: Enter the complete and exact street address for your location, 5 digit zip code is optional. For example: 1600 Amphitheatre Parkway, Mountain View, CA, 94043 or 1600 Amphitheatre Parkway, Mountain View, CA"
              class="vert-align-middle cursor-pointer color-gray">
              help
            </mat-icon>
          </mat-form-field>
          <mat-autocomplete #locationZ="matAutocomplete">
            <mat-option
              *ngFor="let option of locationZOptions"
              [value]="option.place_name">
              {{ option.place_name }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <mat-form-field class="w-100" *ngIf="!isP2PMode">
          <input
            matInput
            cdkFocusInitial
            name="location"
            [matAutocomplete]="location"
            [formControl]="searchControl"
            placeholder="Single Address Autocomplete">
          <mat-icon
            matSuffix
            matTooltip="When entering your address: Enter the complete and exact street address for your location, 5 digit zip code is optional. For example: 1600 Amphitheatre Parkway, Mountain View, CA, 94043 or 1600 Amphitheatre Parkway, Mountain View, CA"
            class="vert-align-middle cursor-pointer color-gray">
            help
          </mat-icon>
        </mat-form-field>
        <mat-autocomplete #location="matAutocomplete" 
        [displayWith]="displayFn" 
        (optionSelected)="parseMapbBoxResponse($event)">
          <mat-option
            *ngFor="let option of locationOptions"
            [value]="option">
            {{ option.place_name }}
          </mat-option>
        </mat-autocomplete>
        <mat-form-field class="w-100 mb-1">
          <textarea
            matInput
            matTextareaAutosize
            matAutosizeMinRows="15"
            matAutosizeMaxRows="40"
            placeholder="Enter Multiple Addresses Here"
            [formControl]="addressesInput">
          </textarea>
        </mat-form-field>
        <div class="d-flex justify-content-between">
          <button
            mat-flat-button
            color="primary"
            [disabled]="
              (!searchControl.value && !addressesInput.value) &&
              (!searchControlA.value || !searchControlZ.value)"
            (click)="validateAddresses()">
            Validate
          </button>
          <div class="d-flex align-items-center">
            <button
              mat-stroked-button
              color="primary"
              matTooltip="Please download sample csv template."
              class="mr-1"
              (click)="downloadSample()">
              <span>Download Upload Sample</span>
            </button>
            <button
              mat-stroked-button
              color="primary"
              class="mr-1"
              (click)="fileInput.click()">
              <span>Upload CSV File</span>
              <input
                #fileInput
                type="file"
                (change)="onFileChange($event)"
                multiple="true"
                style="display:none;"/>
            </button>
            <mat-icon
              matTooltip="When uploading address locations in csv format please add header columns to first row in the following order 'Address' 'City' 'State' 'Zip Code' 'Country' or 'Latitude' 'Longitude'. Zip Code and Country columns are optional. If you want to add Z locations then add 'Address Z' 'City Z' 'State Z' 'Zip Code Z' 'Country Z' or 'Latitude Z' 'Longitude Z' to the header columns"
              class="cursor-pointer color-gray">
              help
            </mat-icon>
          </div>
        </div>
      </form>
    </div>
    <div class="mt-2" *ngIf="locationsForDeal.length || invalidAddresses.length">
      <h2 class="m-0 mb-1">Step 2: Select and Import Addresses</h2>
      <!-- <mat-divider></mat-divider> -->
      <div class="d-flex justify-content-end align-items-base my-1">
        <mat-checkbox
          class="mr-1"
          color="primary"
          [(ngModel)]="excludeInvalidAddresses"
          (ngModelChange)="onExcludeInvalidAddresses()">
          Exclude Invalid Addresses
        </mat-checkbox>
        <page-size (pageLimitChanged)="onPageLimitChanged($event)"></page-size>
        <button mat-icon-button (click)="clearAddresses()" matTooltip="Clear Addresses">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <ngx-datatable
        class="material striped data-table styled-data-table checkboxes"
        [rows]="locationsForDeal"
        [rowClass]="getRowClass"
        [columnMode]="'force'"
        [headerHeight]="75"
        [footerHeight]="50"
        [rowHeight]="40"
        [limit]="limit"
        [selectionType]="'checkbox'"
        [selectAllRowsOnPage]="false"
        [scrollbarH]="true"
        [reorderable]="true"
        [selected]="selectedRows"
        [offset]="pageNumber"
        [loadingIndicator]="addressLoading"
        (select)="onSelect($event)"
        (page)="setPage($event)">
        <ngx-datatable-column
          headerClass="text-center"
          cellClass="relative-el overflow-visible text-center"
          [width]="90"
          [sortable]="false"
          [canAutoResize]="false"
          [draggable]="false"
          [resizeable]="false">
          <ng-template
            ngx-datatable-header-template
            let-value="value"
            let-allRowsSelected="allRowsSelected"
            let-selectFn="selectFn">
            <div class="checkboxable-header">
              <span class="header-cell-wrapper">Select All</span>
            </div>
            <mat-checkbox
              color="primary"
              [disabled]="!locationsForDeal.length"
              [checked]="allRowsSelected"
              (change)="selectFn(!allRowsSelected)">
            </mat-checkbox>
          </ng-template>
          <ng-template
            ngx-datatable-cell-template
            let-value="value"
            let-row="row"
            let-rowIndex="rowIndex"
            let-isSelected="isSelected"
            let-onCheckboxChangeFn="onCheckboxChangeFn">
            <mat-icon class="z-location-icon" *ngIf="row.zLocation">link</mat-icon>
            <mat-checkbox
              color="primary"
              *ngIf="!row.zLocation"
              [checked]="isSelected"
              (change)="onCheckboxChangeFn($event); onSelectRow($event, rowIndex)">
            </mat-checkbox>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column prop="address" name="Address" [width]="300">
          <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
            <span class="header-cell-wrapper">
              <div class="d-flex align-items-center cursor-pointer" (click)="sort()">
                Address
                <mat-icon class="arrow-up">keyboard_arrow_up</mat-icon>
                <mat-icon class="arrow-down">keyboard_arrow_down</mat-icon>
              </div>
              <input
                placeholder="Filter"
                (keyup)="filterColumn($event, column.prop)"
                [(ngModel)]="address_col_filter"
                class="w-100 no-outline">
            </span>
          </ng-template>
          <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
            <span *ngIf="row.found">{{ value }}</span>
            <input
              *ngIf="!row.found"
              matTooltip="Click to edit"
              (keyup.enter)="updateValue($event.target.value, 'address', rowIndex, row)"
              (focusout)="updateValue($event.target.value, 'address', rowIndex, row)"
              class="input-cell"
              [value]="value"/>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column prop="city" name="City" [width]="100">
          <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
            <span class="header-cell-wrapper">
              <div class="d-flex align-items-center cursor-pointer" (click)="sort()">
                City
                <mat-icon class="arrow-up">keyboard_arrow_up</mat-icon>
                <mat-icon class="arrow-down">keyboard_arrow_down</mat-icon>
              </div>
              <input
                placeholder="Filter"
                (keyup)="filterColumn($event, column.prop)"
                [(ngModel)]="city_col_filter"
                class="w-100 no-outline">
            </span>
          </ng-template>
          <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
            <span *ngIf="row.found">{{ value }}</span>
            <input
              *ngIf="!row.found"
              matTooltip="Click to edit"
              (keyup.enter)="updateValue($event.target.value, 'city', rowIndex, row)"
              (focusout)="updateValue($event.target.value, 'city', rowIndex, row)"
              class="input-cell"
              [value]="value"/>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column prop="state" name="State" [width]="90">
          <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
            <span class="header-cell-wrapper">
              <div class="d-flex align-items-center cursor-pointer" (click)="sort()">
                State
                <mat-icon class="arrow-up">keyboard_arrow_up</mat-icon>
                <mat-icon class="arrow-down">keyboard_arrow_down</mat-icon>
              </div>
              <input
                placeholder="Filter"
                (keyup)="filterColumn($event, column.prop)"
                [(ngModel)]="state_col_filter"
                class="w-100 no-outline">
            </span>
          </ng-template>
          <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
            <span *ngIf="row.found">{{ value }}</span>
            <input
              *ngIf="!row.found"
              matTooltip="Click to edit"
              (keyup.enter)="updateValue($event.target.value, 'state', rowIndex, row)"
              (focusout)="updateValue($event.target.value, 'state', rowIndex, row)"
              class="input-cell"
              [value]="value"/>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column prop="zipcode" name="Zipcode" [width]="90">
          <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
            <span class="header-cell-wrapper">
              <div class="d-flex align-items-center cursor-pointer" (click)="sort()">
                Zipcode
                <mat-icon class="arrow-up">keyboard_arrow_up</mat-icon>
                <mat-icon class="arrow-down">keyboard_arrow_down</mat-icon>
              </div>
              <input
                placeholder="Filter"
                (keyup)="filterColumn($event, column.prop)"
                [(ngModel)]="zipcode_col_filter"
                class="w-100 no-outline">
            </span>
          </ng-template>
          <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
            <span *ngIf="row.found">{{ value }}</span>
            <input
              *ngIf="!row.found"
              matTooltip="Click to edit"
              (keyup.enter)="updateValue($event.target.value, 'zipcode', rowIndex, row)"
              (focusout)="updateValue($event.target.value, 'zipcode', rowIndex, row)"
              class="input-cell"
              [value]="value"/>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column prop="country" name="Country" [width]="90">
          <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
            <span class="header-cell-wrapper">
              <div class="d-flex align-items-center cursor-pointer" (click)="sort()">
                Country
                <mat-icon class="arrow-up">keyboard_arrow_up</mat-icon>
                <mat-icon class="arrow-down">keyboard_arrow_down</mat-icon>
              </div>
              <input
                placeholder="Filter"
                (keyup)="filterColumn($event, column.prop)"
                [(ngModel)]="country_col_filter"
                class="w-100 no-outline">
            </span>
          </ng-template>
          <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
            <span *ngIf="row.found">{{ value }}</span>
            <input
              *ngIf="!row.found"
              matTooltip="Click to edit"
              (keyup.enter)="updateValue($event.target.value, 'country', rowIndex, row)"
              (focusout)="updateValue($event.target.value, 'country', rowIndex, row)"
              class="input-cell"
              [value]="value"/>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column prop="valid" name="Valid Address" [width]="120">
          <ng-template ngx-datatable-header-template>
            <span class="header-cell-wrapper">Valid Address</span>
          </ng-template>
          <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
            <div class="text-center">
              <mat-icon
                class="status-icon"
                [ngClass]="{'green': row.found, 'red': row.found === 0}">
                {{ value }}
              </mat-icon>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
      <div class="d-flex justify-content-center my-1">
        <button
          mat-flat-button
          color="primary"
          class="mx-1"
          [disabled]="!selectedRows.length || importLoading"
          (click)="importAddresses()">
          Import
          <mat-progress-bar
            class="button-progress-bar"
            mode="indeterminate"
            *ngIf="importLoading">
          </mat-progress-bar>
        </button>
      </div>
    </div>
  </div>
</div>
