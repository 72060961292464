
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { unsubscribe } from 'app/modules/shared-components/utils/unsubscribe.func';
import { PersistanceService } from 'app/services/persistence.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import * as fromApp from '../../app.reducers';
import { AuthenticationService } from 'app/modules/authentication/services/authentication/authentication.service';
import * as userActions from 'app/modules/authentication/store/user.actions';
import { ApiCallService } from 'app/services/api-call.service';

@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.scss']
})
export class TermsConditionComponent implements OnInit {

  termsconditionTemplate: any;
  termsconditionTemplateId: any;
  private termsconditionObj: Subscription;
  decline: boolean = false;
  termsAccepted: boolean = false;
  hideAcceptDecline: boolean = false;
  constructor(
    private store: Store<fromApp.AppState>,
    private persistanceService: PersistanceService,
    private authenticationService: AuthenticationService,
    private router: Router,
    public  apiCallService: ApiCallService,
    ) { }

  ngOnInit() {
    this.termsconditionObj = this.store.pipe(
      select(state => state['userFeature']['userData']['terms_condition'])
    ).subscribe(accountData => {
      if(accountData) {
        this.termsconditionTemplate = accountData['termsConditionTemplate'];
        this.termsconditionTemplateId = accountData['termsConditionId'];
      }
    });

  }

  declineoraccpet () {
    if(this.decline){
      this.termsAccepted = false
      this.onCancel()
    }
    else if(this.termsAccepted){
      this.decline = false
      this.hideAcceptDecline = true
    }
  }

  onAgreeTermsCond () {
    this.store.dispatch(new userActions.SetAuthentication(true));
    let payload = { portal_terms_condition_id : this.termsconditionTemplateId, is_accepted: true}
    this.apiCallService.processHttpRequest('POST', '/api/v1/company/savecccepttermsandcondition', undefined, payload,
    (response => {
    }), undefined);
    return this.router.navigateByUrl('/' + this.authenticationService.activeCompany);
  }

  onCancel () {
    let payload = { portal_terms_condition_id : this.termsconditionTemplateId, is_accepted: false}
    this.apiCallService.processHttpRequest('POST', '/api/v1/company/savecccepttermsandcondition', undefined, payload,
    (response => {
      this.persistanceService.logout();
    }), undefined);
  }

  ngOnDestroy() {
    unsubscribe(this.termsconditionObj);
  }
}
