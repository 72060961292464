<div>
  <h2 class="m-0 mb-1">Are you sure want to remove {{data.factorName}} enrollment?</h2>
  <div>&nbsp;</div>
  <mat-dialog-actions class="d-flex justify-content-center">
    <button
      mat-stroked-button
      mat-dialog-close
      color="primary"
      class="mx-1">
      Cancel
    </button>
    <button
      mat-flat-button
      class="mx-1"
      color="warn"
      (click)="removeFactor()">
      Delete
    </button>
  </mat-dialog-actions>
</div>
