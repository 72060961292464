<div class="mfa-wrapper">
  <div class="box-wrapper">
    <div class="box-container">
      <div class="login-form">
          <div class="mfa-factor">
                <div class="form">
                  <form [formGroup]="factorForm" (ngSubmit)="tokenVerify()" class="mfa-factor-form">
                    <div *ngIf="(oktaStatus=='MFA_REQUIRED' || oktaStatus=='PENDING_ACTIVATION') || (currentWizard == 2 && oktaStatus=='MFA_ENROLL' && mfaFactor?.factorType == 'sms')" class="beacon-wrapper">
                        <div class="beacon-container">                                           
                          <div class="auth-beacon auth-beacon-factor">
                              <div class="auth-beacon-border" *ngIf="mfaFactor?.factorType == 'token:software:totp' && mfaFactor?.provider == 'GOOGLE'"><img src="../../../../../assets/icons/okta-google.png" alt="Google Authenticator"> </div>
                              <div class="auth-beacon-factor" *ngIf="mfaFactor?.factorType == 'token:software:totp' && mfaFactor?.provider == 'OKTA'"><img src="../../../../../assets/icons/okta-verify.png" alt="Okta Verify"></div>
                              <div class="auth-beacon-factor" *ngIf="mfaFactor?.factorType == 'sms'"><img src="../../../../../assets/icons/okta-sms.png" alt="SMS Authenticator"></div>
                          </div>
                          <div class="factors-dropdown-wrap" *ngIf="currentWizard==1">
                              <select formControlName="mfa"  [(ngModel)]="mfaFactor" (ngModelChange)="selectFactor($event)">
                                <option *ngFor="let item of activeFactorList" [ngValue]="item">{{ item.factorType === 'sms' || item.factorType === 'email' ? item.factorType : item.vendorName }}</option>
                              </select>
                          </div>
                        </div>
                    </div>
                    <div>&nbsp;</div>
                    <div class="full-width" *ngIf="oktaStatus=='MFA_REQUIRED'">
                      <div class="factor-title factor-title-wrap" *ngIf="mfaFactor?.factorType == 'token:software:totp'">
                        {{mfaFactor.vendorName}} Authenticator
                      </div>
                      <div>&nbsp;</div>
                    </div>
                    <div *ngIf="currentWizard==1 && (oktaStatus=='MFA_REQUIRED' || oktaStatus=='MFA_CHALLENGE')">
                      <div class="full-width">
                        <span *ngIf="mfaFactor?.factorType == 'token:software:totp' && mfaFactor?.provider == 'GOOGLE'">Enter your Google Authenticator passcode</span>
                        <span *ngIf="mfaFactor?.factorType == 'token:software:totp' && mfaFactor?.provider == 'OKTA'">Enter your Okta Verify passcode</span>
                        <div *ngIf="mfaFactor?.factorType == 'sms'" class="factor-title-wrap">
                          <div class="factor-title">SMS Authentication</div>
                          <div class="factor-subtitle">({{mfaFactor.profile.phoneNumber}})</div>
                        </div>                          
                      </div>
                      <div>&nbsp;</div>
                      <div class="full-width">
                        <div class="row">
                          <div class="col-10">
                            <mat-form-field>
                              <label for="code"></label>
                              <input
                              matInput
                              placeholder="Enter Code"
                              type="text"
                              id="code"
                              class="form-control"
                              required
                              autocomplete="off"
                              formControlName="code"
                              [(ngModel)]="passCode"
                              >
                            </mat-form-field>
                          </div>
                          <div class="col-2">
                            <button
                            *ngIf="mfaFactor?.factorType == 'sms'"
                            mat-raised-button
                            type="button"
                            (click)="resendCode()"
                            >Send Code</button>
  
                          </div>
                        </div>  
                      </div>
                      <div class="error" *ngIf="verifyError">
                        <mat-error>{{verifyError}}</mat-error>
                      </div>
                      <div class="error" *ngIf="errorMessage">
                        <mat-error>{{errorMessage}}</mat-error>
                      </div>
                      <div>&nbsp;</div>
                      <div class="full-width">
                        <mat-card-actions>
                          <button
                          mat-raised-button
                          type="submit"
                          [disabled]="!factorForm.valid"
                          >Verify Token</button>      
                        </mat-card-actions>
                      </div>

                    </div>
                  </form>

<!-- Start MFA Setup -->
                  <div class="row mfa-setup" *ngIf="currentWizard==1 && oktaStatus=='MFA_ENROLL'">
                    <h4 class="factor-title">Set up multifactor authentication.</h4>                  
                    <div *ngIf="setupStage == 'basic'">Your account requires multifactor authentication to add an additional layer of
                      security when signing in.</div>
                    <div *ngIf="setupStage == 'additional'">You can configure any additional factor.</div>
                    <div class="factor-list" *ngFor="let factor of factorList">
                      <div class="row factor-setup">
                        <div class="col-2 badge">
                          <span [ngClass]="(factor.factorType == 'sms') ? 'sms' : factor.provider.toLowerCase()">&nbsp;
                          </span>
                        </div>
                        <div class="col-10">
                          <div class="factor-name">
                            <span *ngIf="factor.factorType == 'token:software:totp' && factor.provider == 'GOOGLE'"> Google
                              Authenticator</span>
                            <span *ngIf="factor.factorType == 'token:software:totp' && factor.provider == 'OKTA'"> Okta Verify</span>
                            <span *ngIf="factor.factorType == 'sms'"> SMS Authentication</span>
                            <span *ngIf="factor.factorType == 'email'"> Email Authentication</span>
                          </div>
                          <div class="factor-desc">
                            <span *ngIf="factor.factorType == 'token:software:totp' && factor.provider == 'GOOGLE'"> Enter single-use code
                              from the mobile app.</span>
                            <span *ngIf="factor.factorType == 'token:software:totp' && factor.provider == 'OKTA'"> Use a push notification
                              sent to the mobile app.</span>
                            <span *ngIf="factor.factorType == 'sms' && factor.provider == 'OKTA'"> Enter a single-use code sent to your
                              mobile phone.</span>
                            <span *ngIf="factor.factorType == 'email' && factor.provider == 'OKTA'"> Enter a verfication code sent to your
                              email.</span>
                          </div>
                          <div>
                            <button *ngIf="(setupStage=='basic') || (setupStage=='additional' && factor.status != 'ACTIVE')"
                              mat-raised-button type="button" (click)="enrollFactor(factor)">SetUp</button>
                            <div *ngIf="setupStage=='additional' && factor.status == 'ACTIVE'">
                              <div class="row">
                                <div class="col-2">
                                  <button mat-raised-button type="button" (click)="removeFactor(factor)">Delete</button>
                                </div>
                                <div class="col-10">
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Enrolled
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
<!-- End MFA Setup --> 

<!-- Start MFA Enroll -->
                  <div class="mfa-enroll" *ngIf="currentWizard==2">
                    <div class="factor-title row" *ngIf="oktaStatus=='MFA_ENROLL' && mfaFactor?.factorType == 'sms'">
                      Setup SMS Authenticator
                    </div>
                    <div class="full-width" *ngIf="(oktaStatus=='MFA_ENROLL_ACTIVATE' || oktaStatus=='PENDING_ACTIVATION') && mfaFactor?.factorType == 'token:software:totp'" class="token-totp">
                      <div class="factor-title row">
                        Setup {{mfaFactor.vendorName}} Authenticator
                      </div>
                      <div class="row factor-enroll">             
                        <div class="column inner-text">
                          <span *ngIf="mfaFactor?.vendorName == 'OKTA'">
                            Launch Okta Verify application on your mobile device and select Add an account.
                          </span>
                          <span *ngIf="mfaFactor?.vendorName == 'GOOGLE'">
                            Launch Google Authenticator application on your mobile device and select Add an account.
                          </span>
                        </div>
                        <div class="column"> <img [src]="qrCodeUrl" *ngIf="qrCodeUrl" ></div>
                      </div>
                      <div class="row">
                        <div class="column">&nbsp;</div>
                        <div class="column">
                          <button
                          mat-raised-button
                          type="button"
                          (click) = "goTokenVerify(mfaFactor)"
                          >Next</button>
                        </div>
                      </div>
                    </div>
                    <div class="sms-factor">
                      <form [formGroup]="smsForm" (ngSubmit)="tokenVerify()" class="mfa-factor-form"  *ngIf="mfaFactor?.factorType == 'sms'">
                      <div class="full-width factor-enroll" *ngIf="(oktaStatus=='MFA_ENROLL' || oktaStatus=='MFA_ENROLL_ACTIVATE' || oktaStatus=='PENDING_ACTIVATION')" >
                      <div class="row">
                        <div>Receive a code via SMS to authenticate</div>
                      </div>
                      <div class="row ph-row">
                        <div class="col-10">
                          <label for="phone">Phone Number</label>
                          <input type="tel" class="phone"
                          ng2TelInput
                          [ng2TelInputOptions]="{initialCountry: 'us'}"
                          (hasError)="phoneError($event)"
                          (ng2TelOutput)="getNumber($event)"
                          (intlTelInputObject)="telInputObject($event)"
                          formControlName="phone"
                          (countryChange)="onCountryChange($event)" />
                         
                          <div class="error" *ngIf="!hasError">
                            <mat-error>Invalid phone number</mat-error>
                          </div>

                        </div>
                        <div class="col-2">
                          <label>&nbsp;</label>

                          <button
                          class="send-btn"
                          mat-raised-button
                          *ngIf="!resendUrl"
                          type="button"
                          (click)="smsPhoneVerify()"
                          [disabled]="!hasError"
                          >Send Code</button>

                          <button
                          class="send-btn"
                          mat-raised-button
                          *ngIf="resendUrl"
                          type="button"
                          (click)="resendCode()"
                          [disabled]="!hasError"
                          >{{sendCodeButtonText}}</button>


                        </div>
                      </div>
                    </div>
                    <!-- <div class="full-width"> -->
                    <div class="full-width" *ngIf="mfaFactor?.factorType == 'sms' && (oktaStatus=='MFA_ENROLL_ACTIVATE' || oktaStatus=='PENDING_ACTIVATION')" >

                      <mat-form-field>
                        <label for="code"></label>
                        <input
                        matInput
                        placeholder="Enter Code"
                        type="text"
                        id="code"
                        class="form-control"
                        required
                        autocomplete="off"
                        formControlName="code"
                        [(ngModel)]="passCode"
                        >
                      </mat-form-field>
                      <div class="error" *ngIf="errorMessage">
                        <mat-error>{{errorMessage}}</mat-error>
                      </div>
                      <div>&nbsp;</div>
                      <div class="full-width">
                        <mat-card-actions>
                          <button
                          mat-raised-button
                          type="submit"
                          [disabled]="!smsForm.valid"
                          >Verify Token</button>      
                        </mat-card-actions>
                      </div>

                      </div>

                      </form>
                    </div>

                </div>
<!-- End MFA Enroll -->

                </div>
            <div class="full-width">
              <div class="row">
                <div class="col-2">&nbsp;</div>
                <div class="col-2">
                  <div *ngIf="factorStage=='tokenVerify' || factorStage=='enrollFactor'">
                    <a class="link" (click)="backToFactors()">Back to factors</a>
                  </div>
                  <div *ngIf="factorStage=='setupFactor'">
                    <a class="link" (click)="backToVerify()">Back to Verify</a>
                  </div>
                </div>
                <div class="col-2">&nbsp;</div>
                <div class="col-2"><a class="link" (click)="back()">Back to Login</a></div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
  <div *ngIf="pageLoading" class="backdrop"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
</div>
