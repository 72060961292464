<div class="flex-btw">
  <h4 mat-dialog-title class="m-0">{{ data.title }}</h4>
  <button id="user_dialog_close" mat-icon-button [mat-dialog-close]="undefined"><i class="material-icons">close</i></button>
</div>
<div mat-dialog-content class="user_profile_sec">
  <form #userDetailForm="ngForm" fxLayout="row wrap">
    <mat-form-field class="inf_form_field" >
      <input matInput type="text" placeholder="Email" name="userEmail" id="userEmail" required email #userEmail="ngModel" [(ngModel)]="userInfo.email">
      <mat-error *ngIf="userEmail.invalid && userEmail.errors.email">
        Please enter a valid email address
      </mat-error>
      <mat-error *ngIf="userEmail.invalid && userEmail.errors.required">
        Email is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="inf_form_field" *ngIf="!userInfo.id"> <!-- User Add-->
      <input matInput type="password" placeholder="Password" name="userPwd" id="userPwd" required #userPwd="ngModel"
        [(ngModel)]="userInfo.password" [pattern]="passwordPattern">
      <mat-error *ngIf="userPwd.invalid && userPwd.errors.pattern">
        Password should contain uppercase, lowercase, number, special character and  at least 8 characters.
      </mat-error>
      <mat-error *ngIf="userPwd.invalid && userPwd.errors.required">
        Email is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="inf_form_field" *ngIf="userInfo.id"> <!-- User Edit - Not showing existing password-->
      <input matInput type="password" placeholder="Password" name="userPwd" id="userPwd" #userPwd="ngModel"
        [(ngModel)]="userInfo.password" [pattern]="passwordPattern">

      <mat-error *ngIf="userPwd.invalid && userPwd.errors.pattern">
        Password should contain uppercase, lowercase, number, special character and  at least 8 characters.
      </mat-error>
    </mat-form-field>

    <mat-form-field class="inf_form_field" >
      <input matInput type="text" placeholder="First Name" name="userFirstName" id="userFirstName" required #userFirstName="ngModel" [(ngModel)]="userInfo.firstname">
      <mat-error *ngIf="userFirstName.invalid && userFirstName.errors.required">
        First Name is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="inf_form_field" >
      <input matInput type="text" placeholder="Last Name" name="userLastName" id="userLastName" required #userLastName="ngModel" [(ngModel)]="userInfo.lastname">
      <mat-error *ngIf="userLastName.invalid && userLastName.errors.required">
        Last Name is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="inf_form_field">
      <mat-select [disabled]="data.title == 'Edit Profile'" placeholder="Role" [(ngModel)]="userInfo.roleid" required name="userRole" id="userRole" #userRole="ngModel">
        <mat-option *ngFor="let role of buildingLookupService.roles | callback: filterRole: [billingAdminExist, userInfo.roleid]" [value]="role.Id">{{ role.displayName }}</mat-option>
      </mat-select>
      <mat-error *ngIf="userRole.invalid && userRole.errors.required">
        Role is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="inf_form_field" >
      <input matInput type="text" placeholder="Phone" name="userPhone" id="userPhone" [(ngModel)]="userInfo.workphone">
    </mat-form-field>

  </form>
</div>
<div mat-dialog-actions class="flex-box-end">
  <button mat-raised-button color="primary" (click)="saveUser()" id="user_save" [disabled]="!userDetailForm.valid">Save</button>
  <button mat-raised-button mat-dialog-close  id="user_cancel">Cancel</button>
</div>
