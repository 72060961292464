import {Action} from '@ngrx/store';

export const SET_ALERT = '[ALERT] SET_ALERT';

export class SetAlert implements Action {
  readonly type = SET_ALERT;
  constructor(public payload: any) {}
}

export const OPEN_ALERT = '[ALERT] OPEN_ALERT';

export class OpenAlert implements Action {
  readonly type = OPEN_ALERT;
  constructor(public payload: boolean) {}
}

export const SET_ALERT_STYLE = '[ALERT] SET_ALERT_STYLE';

export class SetAlertStyle implements Action {
  readonly type = SET_ALERT_STYLE;
  constructor(public payload: any) {}
}

export type AlertActions = SetAlert | OpenAlert | SetAlertStyle;
