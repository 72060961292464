import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable()
export class SharedApiCallService {

  private url: string = this.getUrl();

  private defaultHeader = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE'
  };

  constructor(
    private http: HttpClient
  ) {}

  private defaultAuthHeader() {
    return {'Authorization': 'Bearer ' + localStorage.getItem('token')}
  }

  private defaultCompanyIdHeader() {
    return {'Company-ID': localStorage.getItem('selected_company_id') ? localStorage.getItem('selected_company_id') : ''}
  }

  private createDefaultHeader() {
    return new HttpHeaders({
      ...this.defaultHeader,
      ...this.defaultAuthHeader(),
      ...this.defaultCompanyIdHeader()
    });
  }

  private createCustomHeader(customHeaderObject) {
    return new HttpHeaders({
      ...this.defaultHeader,
      ...this.defaultAuthHeader(),
      ...this.defaultCompanyIdHeader(),
      ...customHeaderObject
    });
  }

  private checkForCustomHeader(customHeaderObject) {
    if (customHeaderObject) {
      return this.createCustomHeader(customHeaderObject);
    }
    return this.createDefaultHeader();
  }

  private checkForCustomParams(customParamsObject) {
    if (customParamsObject) {
      return new HttpParams({ fromObject: customParamsObject })
    }
    return {};
  }

  makeGetRequest(route, customHeaderObject?, customParamsObject?): Observable<any> {
    const headers = this.checkForCustomHeader(customHeaderObject);
    const params = this.checkForCustomParams(customParamsObject);
    const options = { headers , params }

    return this.http.get(`${this.url}${route}`, options).pipe(
      catchError((error: Response) => {
        return observableThrowError(error);
      }));
  }

  makePostRequest(route, body): Observable<any> {
    return this.http.post(`${this.url}${route}`, body, { headers: this.createDefaultHeader() }).pipe(
      catchError((error: Response) => {
        return observableThrowError(error);
      }));
  }

  makeUploadRequest(route, body): Observable<any> {
    return this.http.post(`${this.url}${route}`, body, {
      headers: new HttpHeaders({
        ...this.defaultAuthHeader(),
        ...this.defaultCompanyIdHeader()
      }),
      reportProgress: true,
    }).pipe(catchError((error: Response) => {
      return observableThrowError(error);
    }));
  }

  makeDownloadRequest(route): Observable<any> {
    return this.http.get(`${this.url}${route}`, {
      headers: new HttpHeaders({
        ...this.defaultAuthHeader(),
        ...this.defaultCompanyIdHeader()
      }),
      responseType: 'blob',
    }).pipe(catchError((error: Response) => {
      return observableThrowError(error);
    }));
  }

  makePatchRequest(route, body): Observable<any> {
    return this.http.patch(`${this.url}${route}`, body, { headers: new HttpHeaders({
      ...this.defaultAuthHeader(),
      ...this.defaultCompanyIdHeader()
    })}).pipe(
      catchError((error: Response) => {
        return observableThrowError(error);
      }));
  }

  makePutRequest(route, body): Observable<any> {
    return this.http.put(`${this.url}${route}`, body, { headers: new HttpHeaders({
      ...this.defaultAuthHeader(),
      ...this.defaultCompanyIdHeader()
    })}).pipe(
      catchError((error: Response) => {
        return observableThrowError(error);
      }));
  }

  makeDeleteRequest(route): Observable<any> {
    return this.http.delete(`${this.url}${route}`, { headers: this.createDefaultHeader() }).pipe(
      catchError((error: Response) => {
        return observableThrowError(error);
      }));
  }

  getUrl(): string {
    if (environment.application === 'CW' || environment.application === 'PORTAL') {
      return `${environment.enterpriseProfilerUrl}`;
    }
    return '';
  }

}
