import { Action } from '@ngrx/store';
import { ConnectedWorldDataActions, ConnectedWorldDataActionTypes } from './connected-world-data.actions';

export interface State {
  searchPayload: any
}

export const initialState: State = {
  searchPayload: null,
};

export function reducer(state = initialState, action: ConnectedWorldDataActions): State {
  switch (action.type) {

    case ConnectedWorldDataActionTypes.CW_SEARCH_PAYLOAD:
      return {
        ...state,
        searchPayload: action.payload
      };


    default:
      return state;
  }
}
