import {Component, HostListener, OnInit} from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl} from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';

/* Services Start */
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { ResetPasswordService } from '../../services/reset-password/reset-password.service';
/* Services End */
import jwt_decode from 'jwt-decode';

// Other
const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  showResetComp: boolean = false;
  showTokenExpired: boolean = false;
  resetPasswordForm: FormGroup;
  formSubmitted: boolean = false;
  title: string;
  displayUpperImage: boolean;
  errorMessage: string;

  constructor(
    private authenticationService: AuthenticationService,
    private resetPasswordService: ResetPasswordService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.displayUpperImage = event.target.innerWidth <= 960;
  }

  ngOnInit() {
    if (window.innerWidth <= 960) { this.displayUpperImage = true; }
    
    let token_string;
    let token;
    this.activatedRoute.queryParams
    .subscribe((params: Params) => {
      token_string = params['token'];
    });

    token = jwt_decode(token_string);

    if( token && token['exp'] && Date.now() > token['exp'] * 1000 && token['data'] && token['data'].forget_password) {
      this.showResetComp = false;
      this.showTokenExpired = true;
    }
    else{
      this.title = environment.applicationTitle;
      this.checkLocalStorage();
      this.createSignupForm();
    }
  }

  createSignupForm() {
    this.resetPasswordForm = this.fb.group({
      password: [null, [
        Validators.required,
        Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
      ]],
      confirmPassword: [null, [
        Validators.required
      ]],
    }, {
      validator: this.MatchPassword
    });
  }

  checkLocalStorage() {
    const token = localStorage.getItem('token');
    if (token) {
      this.validateRoute();
    } else {
      this.showResetComp = true;
    }
  }

  MatchPassword(AC: AbstractControl) {
    const password = AC.get('password').value;
    const confirmPassword = AC.get('confirmPassword').value;
    if(password !== confirmPassword) {
      AC.get('confirmPassword').setErrors( {MatchPassword: true} )
    } else {
      return null
    }
  }

  submitForm() {
    let token;
    let newPassword;
    this.errorMessage = null;

    this.activatedRoute.queryParams
    .subscribe((params: Params) => {
      token = params['token'];
      newPassword = this.resetPasswordForm.value.password;
      this.resetPasswordService.resetPassword(newPassword, token)
      .subscribe( (apiRes) => {
        console.log(apiRes);
        if (undefined != params.portalUrl && params.portalUrl.length > 0)
          window.location.href = params.portalUrl;
        else
          this.router.navigate(['/']);
      }, (err) => {
        this.errorMessage = err.error.message;
      });
    });

    this.resetPasswordForm.reset();
    this.formSubmitted = true;
  }

  validateRoute() {
    this.authenticationService.isValidUser().subscribe(
      (res) => {
        this.showResetComp = false;
        this.router.navigateByUrl('/');
      },
      (error) => {
        this.showResetComp = true;
      });
  }

}
