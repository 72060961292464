import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { StoreModule } from '@ngrx/store';
import {Ng2TelInputModule} from 'ng2-tel-input';


// Components
import { LoginComponent } from './components/login/login.component';
import { OktaLoginComponent } from './components/okta-login/okta-login.component';
import { OktaCallbackComponent } from './components/okta-login/okta-callback.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { TwoFactorComponent } from './components/two-factor/two-factor.component';
import { SignupComponent } from './components/signup/signup.component';
import { OktaMfaComponent } from './components/mfa/okta-mfa.component';
import { OktaDeleteFactorComponent } from './components/mfa/delete-factor/delete-factor.component';

// Services
import { AuthenticationService } from './services/authentication/authentication.service';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { UnauthGuardService } from './services/unauth-guard/unauth-guard.service';
import { ForgotPasswordService } from './services/forgot-password/forgot-password.service';
import { ResetPasswordService } from './services/reset-password/reset-password.service';
import { AuthApiCallService } from './services/auth-api-call/auth-api-call.service';
import { OAuthService, UrlHelperService } from 'angular-oauth2-oidc';

// Reducers
import { userReducers } from './authentication.reducers';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AuthenticationRoutingModule,
    StoreModule.forFeature('userFeature', userReducers),
    Ng2TelInputModule
  ],
  exports: [
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    TwoFactorComponent,
    SignupComponent,
    OktaMfaComponent,
    OktaDeleteFactorComponent,
    OktaLoginComponent,
    OktaCallbackComponent
  ],
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    TwoFactorComponent,
    SignupComponent,
    OktaMfaComponent,
    OktaDeleteFactorComponent,
    OktaLoginComponent,
    OktaCallbackComponent
  ]
})

export class AuthenticationModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AuthenticationModule,
      providers: [
        AuthenticationService,
        AuthGuardService,
        UnauthGuardService,
        ForgotPasswordService,
        ResetPasswordService,
        AuthApiCallService,
        OAuthService,
        UrlHelperService ]
    };
  }
}
