import * as UiActions from './ui.actions';

export interface State {
  address_backroute: boolean;
  show_topnav_icon: boolean;
  show_metadata: boolean;
  company_locations_spinner: boolean;
  stepIndex: number;
  cpq_disable: boolean;
  cpq_spinner: boolean;
}

const initialState: State = {
  address_backroute: false,
  show_topnav_icon: true,
  show_metadata: false,
  company_locations_spinner: false,
  stepIndex: 0,
  cpq_disable: null,
  cpq_spinner: false
};

export function uiReducer(state = initialState, action: UiActions.UiActions) {
  switch (action.type) {
    case UiActions.ADDRESS_BACKROUTE:
      return {
        ...state,
        address_backroute: action.payload
      };
    case UiActions.SHOW_TOPNAV_ICON:
      return {
        ...state,
        show_topnav_icon: action.payload
      };
    case UiActions.DISPLAY_METADATA:
      return {
        ...state,
        show_metadata: action.payload
      };
    case UiActions.SET_STEP_INDEX:
      return {
        ...state,
        stepIndex: action.payload
      };
    case UiActions.CPQ_DISABLE:
      return {
        ...state,
        cpq_disable: action.payload
      };
    case UiActions.CPQ_SPINNER:
      return {
        ...state,
        cpq_spinner: action.payload
      };
    default:
      return state;
  }
}
