import { Action } from '@ngrx/store';
import { SharedDataActions, SharedDataActionTypes } from './shared-data.actions';

export interface State {
  categoryIcons: any,
  lookupOptions: any
}

export const initialState: State = {
  categoryIcons: { default: 'asterisk.svg', invalid: 'invalid.svg' },
  lookupOptions: {
    FiberConnected: [],
    BuildingConnectivity: [],
    FiberOwnership: [],
    locationTypeRef: [],
    AccessMedium: [],
    competitiveRating: ['Green', 'Yellow', 'Red'],
    INSTALL_INTERVAL: [],
    HANDOFF_TYPE: [],
    WAVE_HANDOFF_TYPE: [],
    PROTECTION_TYPE: [],
    DISTANCE_BAND: [],
    DIVERSITY_TYPE: [],
    locationTypeGroup: []
  }
};

export function reducer(state = initialState, action: SharedDataActions): State {
  switch (action.type) {

    case SharedDataActionTypes.CATEGORY_ICONS:
      return {
        ...state,
        categoryIcons: Object.assign(state.categoryIcons, action.categoryIcons)
      };

    case SharedDataActionTypes.LOOKUP_OPTIONS:
      return {
        ...state,
        lookupOptions: Object.assign(state.lookupOptions, action.lookupOptions)
      };


    default:
      return state;
  }
}
