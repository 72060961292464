import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { OktaAuthService } from '../../../services/authentication/okta.authentication.service';

@Component({
  selector: 'app-delete-factor',
  templateUrl: './delete-factor.component.html',
})
export class OktaDeleteFactorComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<OktaDeleteFactorComponent>,
    private oktaAuthService: OktaAuthService,
  ) { }

  ngOnInit() {
  }

  removeFactor() {
    this.oktaAuthService.factorPostRequest(this.data.deleteFactorApiUrl, this.data.deleteFactorReq).subscribe(response => {
      this._dialogRef.close(true);
    })
  }
}
