import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from './../../environments/environment';
import { AuthErrorService } from 'app/services/auth-error/auth-error.service';

@Injectable()
export class ApiCallService {
  public serverLink: string;

  constructor(
    private http: HttpClient,
    private authErrorService: AuthErrorService,
  ) {}

  /* makeGetRequest(route, queryParams): Observable<any> {
    return this.http.get(this.formUrl(route), this.formArguments(queryParams)).pipe(
      catchError((error: Response) => {
        // This would pass all errors to a user error modal, need to refine to only send auth errors
        // this.authErrorService.handleError(error);
        return observableThrowError(error)
      }),
      map(res => res),);
  } */

  /* makePostRequest(route, queryParams, body): Observable<any> {
    return this.http.post(this.formUrl(route), body, this.formArguments(queryParams)).pipe(
      catchError((error: Response) => {
        // This would pass all errors to a user error modal, need to refine to only send auth errors
        // this.authErrorService.handleError(error);
        return observableThrowError(error)
      }),
      map(res => res),);
  } */

  /* makePatchRequest(route, queryParams, body): Observable<any> {
    return this.http.patch(this.formUrl(route), body, this.formArguments(queryParams)).pipe(
      catchError((error: Response) => {
        // This would pass all errors to a user error modal, need to refine to only send auth errors
        // this.authErrorService.handleError(error);
        return observableThrowError(error)
      }),
      map(res => res),);
  } */

  /* makeDeleteRequest(route, queryParams): Observable<any> {
    return this.http.delete(this.formUrl(route), this.formArguments(queryParams)).pipe(
      catchError((error: Response) => {
        // This would pass all errors to a user error modal, need to refine to only send auth errors
        // this.authErrorService.handleError(error);
        return observableThrowError(error)
      }),
      map(res => res),);
  } */

  /* makePutRequest(route, queryParams, body): Observable<any> {
    return this.http.put(this.formUrl(route), body, this.formArguments(queryParams)).pipe(
      catchError((error: Response) => {
        // This would pass all errors to a user error modal, need to refine to only send auth errors
        // this.authErrorService.handleError(error);
        return observableThrowError(error)
      }),
      map(res => res),);
  } */

  formUrl(route) {
    let paramDelimiter = route.indexOf('?') > -1 ? '&' : '?';
    if (route.includes('connectbase.com') || route.includes('/change-password'))
        return route;
    else
      return `${environment.apiUrl}${route}`;
  }

  formArguments(queryParams) {
    return {
      params: queryParams
    }
  }

  makeExternalGetRequest(route): Observable<any> {
    return this.http.get(route, {headers: this.getHeaders()}).pipe(
      catchError((error: Response) => {
        // This would pass all errors to a user error modal, need to refine to only send auth errors
        // this.authErrorService.handleError(error);
        return observableThrowError(error)
      }),
      map(res => res),);
  }

  readFile(fileUrl, headers) {
    return this.http.get(fileUrl, headers);
  }

  /* async makeAwaitGetRequest(route, queryParams) {
    return await this.http.get(this.formUrl(route), this.formArguments(queryParams)).toPromise();
  } */

  /* async makeAwaitPostRequest(route, queryParams, body) {
    return this.http.post(this.formUrl(route), body, this.formArguments(queryParams)).toPromise();
  } */

  getHeaders() {
    const headersConfig = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,PATCH,DELETE,OPTIONS'/* ,
      'Authorization': 'Bearer ' + localStorage.getItem('token') */
    };
    return new HttpHeaders(headersConfig);
  }

/**
 * Make HTTP request without Authentication Header
 * @param method
 * @param api
 * @param body
 * @param options
 * @param successCallback
 * @param errorCallback
 */
  makeRequestWithoutHeader(method, api, body, options, successCallback, errorCallback) {
    let result = this.callRequest(method, api, body, options, undefined);
    let httpObserve = result.subscribe(
      response => {
        if(successCallback)
          successCallback(response);
      },
      error => {
        if(errorCallback)
          errorCallback(error);
      }
    );
  return httpObserve;
  }

  makeLoginRequest(api, body, headers) {
    return this.http.post(api, body, { headers: headers }).pipe(catchError((error: Response) => {
        // This would pass all errors to a user error modal, need to refine to only send auth errors
        // this.authErrorService.handleError(error);
        return observableThrowError(error)
      }),
      map(res => res),);
  }

  makeResetRequest(api, body, headers) {
    return this.http.patch(api, body, { headers: headers }).pipe(catchError((error: Response) => {
        // This would pass all errors to a user error modal, need to refine to only send auth errors
        // this.authErrorService.handleError(error);
        return observableThrowError(error)
      }),
      map(res => res),);
  }

  makeHttpRequest(method, route, queryParams, body): Observable<any> {
    let result: Observable<any>;
    let api = this.formUrl(route);

    let headers = new HttpHeaders({
      Authorization: localStorage.getItem("token")
    });

    let options = { params: queryParams, headers: headers };
    return this.callRequest(method, api, body, options, headers);
  }

  makeUnAuthHttpRequest(method, route, queryParams, body): Observable<any> {
    let result: Observable<any>;
    let api = this.formUrl(route);
    let options = { params: queryParams };
    return this.callRequest(method, api, body, options, undefined);
  }

  private callRequest(method, api, body, options, headers) {
    let result: Observable<any>;
    switch(method) {
      case 'GET':
        result = this.http.get(api, options);
        break;
      case 'POST':
        result = this.http.post(api, body, options);
        break;
      case 'PUT':
        result = this.http.put(api, body, options);
        break;
      case 'PATCH':
        result = this.http.patch(api, body, options);
        break;
      case 'DELETE':
        result = this.http.delete(api, options);
        break;
      case 'UPLOAD':
        headers.set('Content-Type', null);
        headers.set('Accept', "multipart/form-data");

        result = this.http.post(api, body, { headers } );
        break;
    }
    return result;
  }

  processHttpRequest(method, route, queryParams, body, successCallback, errorCallback) {
    let httpReq = this.makeHttpRequest(method, route, queryParams, body);
    let httpObserve = httpReq.subscribe(
        response => {
          if(successCallback)
            successCallback(response);
        },
        error => {
          if(errorCallback)
            errorCallback(error);
        }
      );
    return httpObserve;
  }

}
