import { NgModule } from "@angular/core";
import { AccountLocationComponent } from "./account-location/account-location.component";
import { AddressImportModalComponent } from "./address-import-modal/address-import-modal.component";
import { AddressImportComponent } from "./address-import/address-import.component";
import { MaterialModule } from '../../material.module';
import { CommonModule } from "@angular/common";
import { TableModule } from "primeng/table";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "app/modules/shared/shared.module";
import { QuickQuoteModalComponent } from "../connected-world/quick-quote-modal/quick-quote-modal.component";
import { MultiSelectModule } from "primeng/components/multiselect/multiselect";
import { SharedModule as SharedComponentModule } from "app/shared/components/shared.module";

@NgModule({
    imports: [
      BrowserAnimationsModule,
      MaterialModule,
      CommonModule,
      TableModule,
      SharedModule,
      MultiSelectModule,
      SharedComponentModule
    ],
    declarations: [
      AccountLocationComponent,
      AddressImportModalComponent,
      AddressImportComponent
    ],
    entryComponents: [
      AccountLocationComponent,
      AddressImportModalComponent,
      AddressImportComponent,
      QuickQuoteModalComponent
    ]
  })

export class AccountModule { }

