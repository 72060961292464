import { Observable, Observer, Subscription, BehaviorSubject, throwError  } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthApiCallService } from '../auth-api-call/auth-api-call.service';
import { Injectable } from '@angular/core';
import { OktaAuth } from '@okta/okta-auth-js';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OktaAuthService {

  public authClient: any;
  public authResponse: Subscription;
  public tokenResponse: Subscription;

  $isAuthenticated: Observable<boolean>;
  constructor(private oauthService: OAuthService, private apiCallService: AuthApiCallService) {
    this.authClient = new OktaAuth({
      issuer: environment.oktaIssuer,
      clientId: environment.oktaClientId,
      redirectUri: environment.oktaRedirectURL,
      pkce: true
    });    
  } 

  login(username: string, password: string): Promise<any>{
    return this.oauthService.createAndSaveNonce().then(nonce => {
      return this.authClient.signIn({
        username: username,
        password: password
      }).then((response) => {
        this.authResponse = response
        return response;
      })
    });
  }

  async logout() {
    await this.authClient.signOut({
      postLogoutRedirectUri: location.origin
    });
  }

  enrollOktaFactor(factor, stateToken): Observable<any>{
    let url = environment.oktaHostURL + '/api/v1/authn/factors';
    const body = {"provider": factor.provider, "factorType": factor.factorType, "stateToken": stateToken}
    return this.apiCallService.makePostRequest(url, body)

  }
  enrollOktaSMS(factor, phoneNumber, stateToken): Observable<any>{
    let url = environment.oktaHostURL + '/api/v1/authn/factors';
    const body = {'provider': factor.provider, 'factorType': factor.factorType, 'stateToken': stateToken, 'profile': {'phoneNumber': phoneNumber}}
    return this.apiCallService.makePostRequest(url, body)
  }

  verifySMS(apiUrl, passCode, stateToken){
    const body = {'passCode': passCode, 'stateToken': stateToken}
    return this.apiCallService.makePostRequest(apiUrl,body)
  }

  resendCodeSMS(apiUrl, stateToken){
    const body = {'stateToken': stateToken}
    return this.apiCallService.makePostRequest(apiUrl,body)
  }

  pollingFactorEnroll(factor): Observable<any>{
    let url = environment.oktaHostURL + '/api/v1/authn/factors/' + factor.factorId + '/lifecycle/activate/poll';
    const body = {"provider": factor.provider, "factorType": factor.factorType, "stateToken": factor.stateToken}
    return this.apiCallService.makePostRequest(url, body)   
  }

  verifyToken(apiUrl, passCode, stateToken): Observable<any>{
    const body = {"passCode": passCode, "stateToken": stateToken}
    return this.apiCallService.makePostRequest(apiUrl, body)   
  }

  activateTotp(apiUrl, factor): Observable<any>{
    const body = {"passCode": factor.passCode, "stateToken": factor.stateToken}
    return this.apiCallService.makePostRequest(apiUrl, body)   
  }

  cancelAuth(apiUrl, factor): Observable<any>{
    const body = {"stateToken": factor.stateToken}
    return this.apiCallService.makePostRequest(apiUrl, body)   
  }

  factorPostRequest(apiUrl, factorReq): Observable<any>{
    return this.apiCallService.makePostRequest(apiUrl, factorReq)   
  }

  getUserByToken(token): Observable<any> {
    const body = {'access_token': token}
    return this.apiCallService.makePostRequest(`/v1/user/oktaClaimByToken`, body);
  }
}