import {Component, HostListener, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable, Subscription  } from 'rxjs';
import { MatDialog } from '@angular/material';


import { Router } from '@angular/router';

/* Services Start */
import { AuthenticationService } from '../../services/authentication/authentication.service';

import { OktaAuthService } from '../../services/authentication/okta.authentication.service';
import { OktaDeleteFactorComponent } from './delete-factor/delete-factor.component';


/* Services End */

// Other
import { environment } from '../../../../../environments/environment';
import {capitalize} from 'lodash';

@Component({
  selector: 'app-mfa',
  templateUrl: './mfa.component.html',
  styleUrls: ['./mfa.component.scss']
})
export class OktaMfaComponent implements OnInit, OnDestroy {

  showForgotComp = false;
  factorForm: FormGroup;
  smsForm: FormGroup;

  emailSent: boolean = false;
  title: string;
  displayUpperImage: boolean;
  errorMessage: string;
  oktaAuthInfo: any;
  factorList: any;
  activeFactorList: any;
  stateToken: string;
  mfaFactor: any;
  passCode: string = '';
  phoneNumber: string;
  oktaStatus: string;
  qrCodeUrl: string = null;
  verifyUrl: string = null;
  resendUrl: string = null;
  currentWizard: number = 1;
  tokenActivateUrl: string = null;
  cancelAuthUrl: string = null;
  pageLoading: boolean = false;
  oktaUserId: string = null;
  hasError: boolean = true;
  sentSms: boolean = false;
  sendCodeButtonText: string;
  setupStage: string;
  factorStage: string
  existFactors: any;
  countries: any;
  country: any;

  verifyError: string;

  private userSubscription: Subscription;
  selectedFactor: any;

  constructor(
    private authService: AuthenticationService,
    private oktaAuthService: OktaAuthService,
    private _changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private _dialog: MatDialog,
  ) {

    // this.oktaAuthInfo = JSON.parse(localStorage.getItem('oktaAuthInfo'));
    this.existFactors = ['token:software:totp', 'sms', 'email'];

   }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.displayUpperImage = event.target.innerWidth <= 960;
  }

  ngOnInit() {
    if (window.innerWidth <= 960) { this.displayUpperImage = true; }
    this.title = environment.applicationTitle;
    this.checkLocalStorage();
    this.factorForm = new FormGroup({
      mfa: new FormControl(null, [
        // Validators.required,
      ]),
      code: new FormControl(null, [
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(6)
      ]),
    });
    this.factorForm.valueChanges.subscribe(() => this.verifyError = null);
    this.errorMessage = null;
    this.checkUserAccount();
    this.setupStage = 'basic';
  }

 // passcode token verify though mobile devise
  tokenVerify(){
    this.pageLoading = true;
    if(this.verifyUrl) {
      this.activateToken();
    } else {  

    if(this.setupStage == 'additional'){
      this.additionalTokenVerify(this.verifyUrl);
    } else {    
    this.oktaAuthService.verifyToken(this.verifyUrl, this.passCode, this.stateToken)
    .subscribe(data => {
      this.pageLoading = false;

      if (data.status === 'MFA_CHALLENGE') {
        this.cancelAuthUrl = data._links.cancel.href;
        if(data.factorResult == 'WAITING'){
          setTimeout(() => {
            this.tokenVerify(), 10000
          });
        } else if(data.factorResult == 'REJECTED') {
          this.router.navigateByUrl('/');
        }
      } else if(data.status == 'SUCCESS') {
        this.activateUserSession();    
        this.router.navigateByUrl('/');
      }
    }, (err) => {
      this.pageLoading = false;
      if(err.error instanceof Error || err.error instanceof ProgressEvent) { 
        this.errorMessage = 'Something went wrong';
      } else {
        if(err.error.errorCauses.length > 0){
          this.errorMessage = err.error.errorCauses[0].errorSummary;
        } else {
          this.errorMessage = err.error.errorSummary;
        }
      }
     });
    }
  }
  }

  goTokenVerify(factor){
    this.pageLoading = true
    this.oktaStatus = 'MFA_REQUIRED';
    this.currentWizard = 1;
    this.mfaFactor = factor;
    this._changeDetectorRef.markForCheck();
    this.pageLoading = false
  }

 // to active token at the first time after account mapped into mobile devise through qrcode.
  activateToken(){    
    if(this.setupStage == 'additional'){
      this.additionalTokenVerify(this.verifyUrl);
    } else {    
    const factorReq = {"passCode": this.passCode, "stateToken": this.oktaAuthInfo.data.stateToken};
    return this.oktaAuthService.activateTotp(this.verifyUrl, factorReq ).subscribe(response=>{
      if(response.status == 'SUCCESS' || response.status == 'MFA_ENROLL'){
        this.currentWizard = 1;
        this.activateUserSession();
       this.router.navigateByUrl('/');
      }
    },
    (err) => {
      this.pageLoading = false;
      if(err.error instanceof Error || err.error instanceof ProgressEvent) { 
        this.errorMessage = 'Something went wrong';
      } else {
        if(err.error.errorCauses.length > 0){
          this.errorMessage = err.error.errorCauses[0].errorSummary;
        } else {
          this.errorMessage = err.error.errorSummary;
        }
      }
    })
  }
  }

  // Remove the okta auth session and take back
  back(){
    const factorReq = {"stateToken": this.oktaAuthInfo.data.stateToken};
    return this.oktaAuthService.cancelAuth(this.cancelAuthUrl, factorReq ).subscribe(response=>{
        this.router.navigateByUrl('/login');
    },
    (err) => {
      this.pageLoading = false;
      if(err.error instanceof Error || err.error instanceof ProgressEvent) { 
        this.errorMessage = 'Something went wrong';
      } else {
        if(err.error.errorCauses.length > 0){
          this.errorMessage = err.error.errorCauses[0].errorSummary;
        } else {
          this.errorMessage = err.error.errorSummary;
        }
      }
   })
  }

  // Get QR code image to scan by mobile devise at the first time
  enrollFactor(factor){
    this.pageLoading = true;
    this.mfaFactor = factor;
    if(factor.factorType == 'sms'){  
      this.smsForm = new FormGroup({
        country: new FormControl(null, [
          // Validators.required,
        ]),
        phone: new FormControl(null, [
          Validators.required,
          // Validators.pattern("[0-9 ]{11}")
        ]),
        code: new FormControl(null, [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(6)
        ]),
      });
      this.smsForm.valueChanges.subscribe(() => this.verifyError = null);
      this.currentWizard = 2;
      this.factorStage = 'enrollFactor';
      this.pageLoading = false;

    } else {
  
      if(this.setupStage == 'additional'){
        this.additionalEnrollFactor(factor);
      } else {      
        return this.oktaAuthService.enrollOktaFactor(factor, this.oktaAuthInfo.data.stateToken).subscribe(response=>{
          this.oktaStatus = response.status;
          if(response.status == 'MFA_ENROLL_ACTIVATE'){
            // if(response.factorResult == 'WAITING'){              
              this.currentWizard = 2;
              this.qrCodeUrl = response._embedded.factor._embedded.activation._links.qrcode.href;
              this.verifyUrl = response._links.next.href;
              this.factorStage = 'enrollFactor'
              if(response._links.cancel)
                this.cancelAuthUrl = response._links.cancel.href;
              this.pageLoading = false;
              // this.pollingEnroll(response._embedded.factor.id);
            // }
          }

        })
      }
      
    }
  }

  backToFactors() {
    let apiUrl = environment.nodeApiUrl + '/v1/user/mfa/factorList'
    let oktaApiUrl = environment.oktaHostURL + '/api/v1/users/' + this.oktaUserId + '/factors/catalog'
    const factorReq = { 'apiName': 'factorList', 'apiUrl': oktaApiUrl };
    let activeFactors = []
    return this.oktaAuthService.factorPostRequest(apiUrl, factorReq).subscribe(userFactors => {
      this.currentWizard = 1;
      this.setupStage = 'additional'
      this.oktaStatus = 'MFA_ENROLL'
      this.factorList = userFactors;
      userFactors.forEach(factor => {
        if (factor.status == 'ACTIVE') {
          activeFactors.push(factor)
        }
      });
      this.activeFactorList = activeFactors;
      this.factorStage = (activeFactors.length > 0) ? 'setupFactor' : ''
    }, (err) => {
      console.log('err', err)
    });
  }

  backToVerify(){
    this.oktaStatus = 'MFA_REQUIRED'
    this.setupStage = 'basic'
    this.currentWizard = 1
    this.factorStage = 'tokenVerify'
    this.pageLoading = true
    this.activeFactorList = []
    this.getFactorInfo().then(userFactorList => {
      if(userFactorList.length > 0){
        // this.activeFactorList = userFactorList
      userFactorList.forEach(userFactor => {
        if(userFactor.status  == 'ACTIVE'){
          this.activeFactorList.push(userFactor)
        }
      });
      this.pageLoading = false
    }
    this.mfaFactor = this.activeFactorList[0]

    });
  } 

  additionalEnrollFactor(factor){
    let apiUrl = environment.nodeApiUrl + '/v1/user/mfa/enrollFactor'
    let oktaApiUrl = (factor.factorType == 'sms') ? environment.oktaHostURL + '/api/v1/users/'+this.oktaUserId+'/factors?updatePhone=true' :  environment.oktaHostURL + '/api/v1/users/'+this.oktaUserId+'/factors'
    let factorReq = {
      "factor": {
        "factorType": factor.factorType,
        "provider": factor.provider,
      },
      "apiUrl": oktaApiUrl,
      };   
    if(factor.factorType == 'sms'){
      factorReq['factor']['profile'] = { 'phoneNumber': this.phoneNumber }   
      factorReq['apiName'] = 'enrollSMSFactor'
    } else {
      factorReq['apiName'] = 'enrollFactor'
    }
    return this.oktaAuthService.factorPostRequest(apiUrl, factorReq).subscribe(response =>{
       this.mfaFactor = response;
       this.oktaStatus = response.status;
       if(response.factorType == 'sms'){
        if(response._links.verify !== undefined)
          this.verifyUrl = `${environment.oktaHostURL}/api/v1/authn/factors/${response.id}/verify`
        if(response._links.activate !== undefined)
          this.verifyUrl = response._links.activate.href
       } else {
        this.verifyUrl = (response !== undefined && response._links.verify !== undefined) ? `${environment.oktaHostURL}/api/v1/authn/factors/${response.id}/verify` : ''
       }
       this.currentWizard = 2;
       this.pageLoading = false; 
       this.factorStage = 'enrollFactor'
       if(factor.factorType == 'sms'){
        this.sendCodeButtonText = 'Sent';
        this.sentSms = true;
        this.currentWizard = 2;
        if(response._links.resend){
          let resendLinks = response._links.resend;
          resendLinks.filter(link => {
            if(link.name == 'sms'){
              this.resendUrl = link.href;
            }
            if(response._links.cancel){
            this.cancelAuthUrl = response._links.cancel.href;
            }
            this.pageLoading = false;
          });
          setTimeout(() => {
            this.sendCodeButtonText = 'Resend', 50000
          });
        } else {
          this.backToVerify()
          this.activeFactorList.forEach(factor => {
            if(factor.factorType == 'sms'){
              this.mfaFactor = factor
            }
          });
        }
       } else {
        this.qrCodeUrl = response._embedded.activation._links.qrcode.href;
       }

     }, (err) => { 
      this.errorMessage = 'Something went wrong';
      this.pageLoading = false;
     });
   }

   additionalTokenVerify(oktaApiUrl){
    let apiUrl = environment.nodeApiUrl + '/v1/user/mfa/verifyToken'    
    const factorReq = {
      "factor": {"passCode": this.passCode},
      "apiUrl": oktaApiUrl,
      "apiName": "verifyToken"
      };   
    return this.oktaAuthService.factorPostRequest(apiUrl, factorReq).subscribe(response =>{
       this.oktaStatus = response.status;
       if( response.status == 'ACTIVE'){
        this.activateUserSession();
        this.router.navigateByUrl('/');
       }
       this.pageLoading = false; 
     }, (err) => { 
      this.errorMessage = 'Your passcode does not match our records. Please try again.';
      this.pageLoading = false;
     });
   }


  telInputObject(obj) {
    obj.setCountry('us');
  }

  getNumber(phoneNumber){
    this.phoneNumber = phoneNumber
  }

  onCountryChange(obj){
    console.log(obj);
  }

  phoneError(obj){
    this.hasError = obj;
    console.log(obj);
  }

  smsCodeVerify(){
     this.oktaAuthService.verifySMS(this.verifyUrl, this.passCode, this.stateToken).subscribe(response=>{
      this.oktaStatus = response.status;
      this.pageLoading = false;
      if(response.status == 'MFA_CHALLENGE'){
        if(response.factorResult == 'CHALLENGE'){              
          let resendLinks = response._links.resend;

          resendLinks.filter(link => {
            if(link.name == 'sms'){
              this.resendUrl = link.href;
            }
          })
        }
      }

    })
  }
  resendCode(){
    if(this.setupStage == 'additional'){
      this.additionalTokenVerify(this.resendUrl)
    } else {
    this.oktaAuthService.resendCodeSMS(this.verifyUrl, this.stateToken).subscribe(response=>{
      this.oktaStatus = response.status;
      this.pageLoading = false;
    })
  }
  }

  smsPhoneVerify(){
    if(this.setupStage == 'additional'){
      this.additionalEnrollFactor(this.mfaFactor)
    } else {
    return this.oktaAuthService.enrollOktaSMS(this.mfaFactor, this.phoneNumber, this.oktaAuthInfo.data.stateToken).subscribe(response=>{
      this.oktaStatus = response.status;
      if(response.status == 'MFA_ENROLL_ACTIVATE'){
        this.sendCodeButtonText = 'Sent';
        this.sentSms = true;
        // if(response.factorResult == 'WAITING'){              
          this.currentWizard = 2;
          // this.qrCodeUrl = response._embedded.factor._embedded.activation._links.qrcode.href;
          this.verifyUrl = response._links.next.href;

          let resendLinks = response._links.resend;
          resendLinks.filter(link => {
            if(link.name == 'sms'){
              this.resendUrl = link.href;
            }
            if(response._links.cancel){
            this.cancelAuthUrl = response._links.cancel.href;
            }
            this.pageLoading = false;
            // this.pollingEnroll(response._embedded.factor.id);
          });
          setTimeout(() => {
            this.sendCodeButtonText = 'Resend', 50000
          });

      }

      })
    }
  }

// Check token activation status using polling api
  pollingEnroll(factorId){
    //let factorType = (this.mfaFactor.provider == 'OKTA') ? 'push' : this.mfaFactor.factorType;
    const factorReq = {  "factorId": factorId, "provider": this.mfaFactor.provider,  "stateToken": this.oktaAuthInfo.data.stateToken, "factorType": this.mfaFactor.factorType}
    this.oktaAuthService.pollingFactorEnroll(factorReq).subscribe(response=>{
      if(response.status == 'MFA_ENROLL_ACTIVATE' && response.factorResult == 'WAITING'){
        setTimeout(() => {
          this.pollingEnroll(factorId), 50000
        });
      } else if(response.status == 'SUCCESS'){
        this.currentWizard = 1;
      }
    });

  }
  
  // Check c2f login account exists
  checkUserAccount(){
    this.authService.accountUser.subscribe(apiRes => {
      if(apiRes === undefined){
        const factorReq = {"stateToken": this.oktaAuthInfo.data.stateToken};
        return this.oktaAuthService.cancelAuth(this.cancelAuthUrl, factorReq ).subscribe(response=>{
          this.router.navigateByUrl('/login');
        });
      } 
    })
  }
  // Set user session after verified token successfully.
  activateUserSession(){
    this.userSubscription = this.authService.accountUser.subscribe(apiRes => {
      if(apiRes !== undefined){
        let lastUsedAccount = apiRes.account_list.find(account => account.id === apiRes.user.last_used_account_id);
        const account = lastUsedAccount ? lastUsedAccount : apiRes.account_list[0];
        const userCurrentAccountId = lastUsedAccount ? lastUsedAccount.company_id : apiRes.account_list[0].company_id;
        localStorage.setItem('token', apiRes['session_token']);      
        localStorage.setItem('selected_company_id', `${userCurrentAccountId}`);
        this.authService.onAccountChange(apiRes, account);
      } else {
        this.router.navigateByUrl('/');
      }
    })
  }

  // Chnage to another factor
  selectFactor(factor) {
    if (factor._links.verify)
      this.verifyUrl = `${environment.oktaHostURL}/api/v1/authn/factors/${factor.id}/verify`
    if (factor._links.activate)
      this.verifyUrl = factor._links.activate.href
  }

  // Extract factors from localstorage
  checkLocalStorage() {
    this.pageLoading = true
    this.oktaAuthInfo = JSON.parse(localStorage.getItem('oktaAuthInfo'));
    this.cancelAuthUrl = this.oktaAuthInfo.data._links.cancel.href;
    let factors = [];
    this.oktaAuthInfo.data._embedded.factors.filter(factor => {
      if (this.existFactors.includes(factor.factorType)) {
        factor['status'] == 'NOT_SETUP';
        factors.push(factor);
      }
    });
    this.factorList = factors;
    this.activeFactorList = factors;
    if (this.oktaAuthInfo.status !== 'MFA_ENROLL' || this.oktaAuthInfo.status !== 'MFA_REQUIRED') {
      this.mfaFactor = this.factorList[0];
      if (this.mfaFactor._links.verify !== undefined) {
        this.verifyUrl = this.mfaFactor._links.verify.href;
      }
    }
    // this.cancelAuthUrl = environment.oktaHostURL + '/api/v1/authn/cancel';
    this.oktaStatus = this.oktaAuthInfo.status;
    this.stateToken = this.oktaAuthInfo.data.stateToken;
    this.oktaUserId = this.oktaAuthInfo.user.id;
    let activeFactors = []
    this.getFactorInfo().then(userFactorList => {
      if (userFactorList.length > 0) {
        userFactorList.forEach(userFactor => {
          if (userFactor.status == 'ACTIVE') {
            activeFactors.push(userFactor)
          }
        });
        this.activeFactorList = activeFactors
      }
      this.pageLoading = false
      this.factorStage = (this.activeFactorList.length > 0) ? 'tokenVerify' : ''
    });
    this.pageLoading = false
  }

  // Get Active MFA factors
  async getFactorInfo(){
    let getFactorApiUrl = `${environment.nodeApiUrl}/v1/user/mfa/factorList`
    let oktaFactorApiUrl = `${environment.oktaHostURL}/api/v1/users/${this.oktaUserId}/factors`   
    const factorReq = {'apiName': 'factorList', 'apiUrl': oktaFactorApiUrl};
    return await this.oktaAuthService.factorPostRequest(getFactorApiUrl, factorReq).toPromise()
  }

  // Remove MFA factor
  removeFactor(factorReq) {
    this.pageLoading = true
    let factorId;
    let factorName;
    this.getFactorInfo().then(userFactorList => {
      userFactorList.forEach(userFactor => {
        if((userFactor.factorType == factorReq.factorType) && (userFactor.provider == factorReq.provider)){
          factorId = userFactor.id
          if(userFactor.factorType == 'token:software:totp')
            factorName = `${capitalize(userFactor.vendorName)} Authenticator`
          if(userFactor.factorType == 'sms')
            factorName = 'SMS Authentication'
        }
      });
      let deleteFactorApiUrl = `${environment.nodeApiUrl}/v1/user/mfa/deleteFactor`
      let oktaDeleteFactorApiUrl = `${environment.oktaHostURL}/api/v1/users/${this.oktaUserId}/factors/${factorId}`   
      const deleteFactorReq = {'apiName': 'factorList', 'apiUrl': oktaDeleteFactorApiUrl};
      this.pageLoading = false
      const dialogRef = this._dialog.open(OktaDeleteFactorComponent, {
        id: 'remove-factor-dialog',
        data: { factorName: factorName, deleteFactorReq: deleteFactorReq, deleteFactorApiUrl: deleteFactorApiUrl  },
      });
      dialogRef.afterClosed().subscribe((removeFactor) => {
        this.pageLoading = true
        if (removeFactor) {
          this.backToFactors()
        }
        this.pageLoading = false
      });
    });
  }

  ngOnDestroy() {
    if(this.userSubscription){
      this.userSubscription.unsubscribe();
    }
  }
}
