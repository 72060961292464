import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiCallService } from '../api-call.service';
@Injectable()
export class LocationSearchService {

  constructor(public apiCallService: ApiCallService) { }

  public getLocationByAddressKey(addressKey) {
    const requestBody = { addressKey: addressKey };
    return this.apiCallService.makeHttpRequest('POST', '/api/v2/search/getLocationByAddressKey', undefined, requestBody).pipe(map(res => res ? res : []));
  }

  public searchLocation(query) {
    const params = { query: query };
    return this.apiCallService.makeHttpRequest('GET', '/api/v2/search/locations', params, undefined).pipe(map(res => res ? res : []));
  }
}


