<div class="login-page-wrapper">
  <div *ngIf="displayUpperImage" class="upper-logo-image">
    <img src="../../../../../assets/connectbase.svg" alt="" width="150px">
  </div>
  <div class="box-wrapper">
    <div class="login-card" *ngIf="!isC2fUser">
      <div class="login-form">
        <div class="not_found">
          {{unKnownUser.email}} is not a valid Connectbase user.
        </div>
        <div fxLayoutAlign="center center">
          <a class="link" (click)="back()">Back to CB Login</a>
        </div>
      </div>
    </div>
    <div class="company-logo-card" *ngIf="!isC2fUser">
      <div class="img-container"><img src="../../../../../assets/connectbase.svg" alt="" width="250px"></div>
    </div>
  </div>
  <div *ngIf="pageLoading" class="backdrop">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>