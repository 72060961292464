import {Component, OnDestroy, OnInit} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { unsubscribe } from '../../utils/unsubscribe-util';

import * as fromStore from 'app/app.reducers';
import { RenderMapData } from '../../../components/connected-world/store/connected-world.actions';
import { HeaderService } from 'app/services/header.service';
import { environment } from 'environments/environment';
import {AuthenticationService} from '../../../modules/authentication/services/authentication/authentication.service';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css']
})
export class MainNavComponent implements OnInit, OnDestroy {
  status: boolean = false;
  accountInfo: any = {};
  adminAccess: boolean = false;
  showMyContainer: boolean = false;
  isHandset$: Observable<boolean> ;
  networkFinderURL: string = environment.networkFinderURL;
  isAccountAllowedMessaging: number;
  isAccountAllowedCpq: number;

  private userSubscription: Subscription;

  constructor(private breakpointObserver: BreakpointObserver,
              public headerService: HeaderService,
              private authenticationService: AuthenticationService,
              private store: Store<fromStore.AppState>) {}

  ngOnInit() {
    this.userSubscription = this.authenticationService.userInformation$().subscribe(userInformation => {
      this.accountInfo = userInformation ? userInformation.settings : {};
      this.isAccountAllowedMessaging = userInformation['settings']['allow_messaging'];
      this.isAccountAllowedCpq = userInformation['settings']['allow_cpq'];
      this.adminAccess = (this.accountInfo['isAdmin'] === true ||
        this.accountInfo['isBillingAdmin'] === true ||
        this.accountInfo['role_name'] === 'Carrier Relations Admin');
    });
    this.isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map(result => result.matches));
  }

  searchClickFunction() {
    this.status = !this.status;
  }

  /**
   * Event to be triggered after selecting
   * an item in address search
   * @param address
   */
  setMapboxAddress(address) {
    this.headerService.hideFilterSideNav();
    this.store.dispatch(new RenderMapData({ type: 'search', data: address }));
  }

  ngOnDestroy() {
    unsubscribe(this.userSubscription);
  }
}
