<div class="export-popup" *ngIf="showExportStatusWindow()">
  <mat-accordion *ngIf="feExpanded">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header class="hideable-panel-header">
        <mat-panel-title class="mpt-justify">
          Export
          <span style="color: white" (click)="onMinimize($event)"
            ><i class="fa fa-minus"></i
          ></span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div>
        <div *ngFor="let exportFile of exportedFiles; index as i">
          <div
            *ngIf="currentExportType.includes(exportFile.module)"
            class="mt-10 export_con"
          >
            <div
              class="export_status_file"
              matTooltip="{{ exportFile.fileName }}"
            >
              {{ exportFile.fileName }}
            </div>
            <span>{{ exportFile["status"] }}</span>
            <div class="export-functions">
              <span
                *ngIf="exportFile['status'] == FAILED"
                matTooltip="{{ exportFile.message }}"
                >{{ exportFile.message }}</span
              >

              <span
                matTooltip="InProgress"
                *ngIf="
                  exportFile['status'] == IN_PROGRESS ||
                  exportFile['status'] == STARTED
                "
              >
                <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
              </span>

              <span
                matTooltip="Error"
                *ngIf="
                  exportFile['status'] == FAILED &&
                  !exportFile.fileErrorPath
                "
              >
                <mat-icon> error</mat-icon>
              </span>
              <button
                color="primary"
                matTooltip="Download"
                mat-icon-button
                (click)="downloadExportedFile(exportFile)"
                *ngIf="exportFile['status'] == FINISHED"
              >
                <mat-icon>file_download</mat-icon>
              </button>

              <button
                color="warn"
                matTooltip="Cancel"
                mat-icon-button
                (click)="cancelFileExport(exportFile)"
                *ngIf="
                  exportFile['status'] == IN_PROGRESS ||
                  exportFile['status'] == STARTED
                "
              >
                <mat-icon>close</mat-icon>
              </button>

              <button
                color="warn"
                matTooltip="Remove"
                mat-icon-button
                (click)="removeExportedTrashFile(i)"
                *ngIf="
                  exportFile['status'] == FINISHED ||
                  exportFile['status'] == FAILED
                "
              >
                <mat-icon>delete_outline</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
