import { CommonUtil } from './common-util';

export class TableUtil {
  public static parseGridFieldResponse(gridFields: Array<Object>) {
    const columns = [];
    gridFields.forEach(item => {
      columns.push({
        field_id: item['buildingGridViewFieldId'],
        field: item['columnValue'],
        title: item['columnTitle'],
        sort: item['sortField'],
        filter_type: item['filterType'],
        value_type: item['valueType'],
        filter: item['filterField'],
        editable: item['editable'],
        custom_field: item['customField'],
        default: item['isDefault']
      });
    });
    return columns;
  }

}
