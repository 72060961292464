import {Component, HostListener, OnInit} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { find } from 'lodash';
import * as FullStory from '@fullstory/browser';
import { Subscription  } from 'rxjs';
import {select, Store} from '@ngrx/store';
/*
  Application Environment File
  Needs to be added manually from root of application
 */
import { environment } from '../../../../../environments/environment';

/* Services Start */
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { OktaAuthService } from '../../services/authentication/okta.authentication.service';

/* Services End */

// Other
import { User } from '../../models/user.model';
import { ApiCallService } from 'app/services/api-call.service';
import * as fromApp from '../../../../app.reducers';
import * as userActions from '../../../authentication/store/user.actions';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {

  myForm: FormGroup;
  public twoFactor: boolean = false;
  public email: boolean;
  public password: boolean;
  private canCall: boolean = true;
  loginError: string;
  title: string;
  initialPage = localStorage.getItem('initialPage') ? localStorage.getItem('initialPage') : '/';
  lastLocationsSearch = localStorage.getItem('lastLocationsSearch');
  showSignupDiv = false;
  showVzSSO : boolean = false;
  oktaSSO  = environment.oktaSSO ;
  extraLinkPosition = 'center';
  displayUpperImage: boolean;
  oktaAuthResponse : any;
  isMfaActive : boolean = false;
  isCompanyMfaActive : boolean = false;
  pageLoading: boolean = false;
  oktaAuthRequestUrl: string = null;
  oktaAuthCode: string = null;
  private userSubscription: Subscription;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private oktaAuthService: OktaAuthService,
    public  apiCallService: ApiCallService,
    private store: Store<fromApp.AppState>
  ) {
      // Init FullStory if environment.ts has fullStoryKey defined    
      if (environment.hasOwnProperty('fullStoryKey')) {
        FullStory.init({ orgId:environment['fullStoryKey'],
        devMode: false });
      }

      // If we are in Verizon Canvas, show Verizon SSO login
      // Otherwise, show normal login form
      if (environment.hasOwnProperty('vzCanvasCookieMatch')) {
        let cookies = decodeURIComponent(document.cookie).split(';');
        for(let i = 0; i < cookies.length; i++) {
          while (cookies[i].charAt(0) == ' ') {
            cookies[i] = cookies[i].substring(1);
          }
          if (cookies[i].indexOf(environment.vzCanvasCookieMatch) == 0) {
            this.showVzSSO = true;
            break;
          }
        }
      }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.displayUpperImage = event.target.innerWidth <= 960;
  }

  ngOnInit() {
    if (window.innerWidth <= 960) { this.displayUpperImage = true; }
    this.title = environment.applicationTitle;
    this.myForm = new FormGroup({
      email: new FormControl(null, [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl(null, [
        Validators.required
      ])
    });

    this.myForm.valueChanges.subscribe(() => this.loginError = null);
  }

  forgotPassword(){
    (environment.application === 'PORTAL')
      ? this.router.navigateByUrl(`/${this.authenticationService.activeCompany}/forgot-password`)
      : this.router.navigateByUrl('/forgot-password');
  }

  onSubmit(){
    const user = new User(this.myForm.value.email, this.myForm.value.password);
    const delay = 2000;

    if (this.canCall) {
      this.canCall = false;

      this.authenticationService.login(user).subscribe(
        (apiRes) => {
          this.pageLoading = true;
          this.isMfaActive = apiRes.user.is_mfa_active;
          apiRes.account_list.filter( account => {
            if(account.is_mfa_enabled){
              this.isCompanyMfaActive = true
            }
          })        
          let twoFactorNecessary = '';
          if (apiRes.settings) {
            twoFactorNecessary = apiRes.settings.two_factor_auth;
          }
          if (twoFactorNecessary !== '1') {

            if(apiRes.account_list.length === 0){
              localStorage.removeItem('token');
              this.loginError = 'No instance configured for this User';
              this.pageLoading = false;
              return;
            }
             let lastUsedAccount = apiRes.account_list.find(account => account.id === apiRes.user.last_used_account_id);
             const userCurrentAccountId = lastUsedAccount ? lastUsedAccount.company_id : apiRes.account_list[0].company_id;
            if (environment.application !== 'PORTAL'){
               if(null != lastUsedAccount && lastUsedAccount.display_role_name === "Portal User")
                  lastUsedAccount = null;
              apiRes.account_list = apiRes.account_list.filter(account => account.display_role_name !== "Portal User");
            }
            // CW uses accountLogin route while all other apps are using login route to dispatch userInformation data.
            if (environment.application !== 'CW' && environment.application !== 'PORTAL') {
              this.authenticationService.setUserInformation(apiRes, userCurrentAccountId);
              let currentAccount = apiRes['account_list'].find(elem => elem.company_id === userCurrentAccountId);
              this.setAccountToken(apiRes['session_token'], userCurrentAccountId,'');
              this.setFullStoryTrack(currentAccount);
              // DCC, NF or DE
            }
            if (environment.application === 'EP') {
              if (this.initialPage === '/search-by-company' && this.lastLocationsSearch) {
                return this.router.navigateByUrl(`${this.initialPage}/${this.lastLocationsSearch}`);
              } else if (this.initialPage === '/search-by-company' && !this.lastLocationsSearch) {
                return this.router.navigateByUrl('/');
              } else {
                return this.router.navigateByUrl(this.initialPage);
              }
            }
            if (environment.application === 'PORTAL') {
              let acc = find(apiRes['account_list'], { display_role_name: 'Portal User', portal_url_suffix:this.authenticationService.activeCompany });
              if (acc && acc['is_att_portal'] == 0) {
                if (acc['portal_url_suffix'] !== this.authenticationService.activeCompany) {
                  this.loginError = 'User not associated with this company portal';
                  this.pageLoading = false;
                  return;
                }
                else {
                  //PORTAL
                  this.setAccountToken(apiRes['session_token'], userCurrentAccountId, acc.portal_url_suffix);
                  this.setFullStoryTrack(acc);
                  acc['portal_terms_condition']=true;
                  this.authenticationService.onAccountChange(apiRes, acc);
                  setTimeout(()=>{
                    this.apiCallService.processHttpRequest('GET', '/api/v1/company/portaltermsandcondition', { 'companyId': apiRes.account_list[0].company_id }, undefined,
                      (
                        response => {
                          if (response && response['isAccepted'] == false) {
                            this.store.dispatch(new userActions.SetAuthentication(false));
                            this.store.dispatch(new userActions.SetTermsandCondition(response));
                            return this.router.navigateByUrl(`/${this.authenticationService.activeCompany}/terms-condition`)
                          }
                          else {
                            this.store.dispatch(new userActions.SetAuthentication(true));
                            return this.router.navigateByUrl('/' + this.authenticationService.activeCompany);
                          }
                        }
                      ), undefined);
                  },500)
                }
              }
              else {
                localStorage.removeItem('token');
                localStorage.removeItem('selected_company_id');
                localStorage.removeItem('portal_company_name');
                this.loginError = 'Not a portal user';
                this.pageLoading = false;
                return;
              }
            }
            if (environment.application === 'CW') {
                if(this.isCompanyMfaActive && this.isMfaActive){
                  this.authenticationService.updatAccountUser(apiRes);
                  this.oktaLogin(this.myForm.value.email, this.myForm.value.password);                
                } else {
                  this.setAccountToken(apiRes['session_token'], userCurrentAccountId, '');
                  // CW uses accountLogin route to dispatch userInformation data.                 
                    const account = lastUsedAccount ? lastUsedAccount : apiRes.account_list[0];
                  //     // TCW
                  this.setFullStoryTrack(account);  
                  this.authenticationService.onAccountChange(apiRes, account);
                }               

            } else {

              this.router.navigateByUrl('/');
            }           

          } else {
            this.email = this.myForm.value.email;
            this.password = this.myForm.value.password;
            this.twoFactor = true;
          }
       // }
        },
        (err) => {
          this.pageLoading = false;
          this.loginError = err.error.message;
        }
      );


      setTimeout(() => {
        this.canCall = true;
      }, delay)
    }

  }

  setAccountToken(session_token, userCurrentAccountId, company_name){
      localStorage.setItem('token', session_token);
      localStorage.setItem('selected_company_id', `${userCurrentAccountId}`);
      localStorage.setItem('portal_company_name', company_name);
  }

  oktaLogin(username, password){
    this.oktaAuthService.login(username, password).then( response => {
      if(response.status == 'MFA_ENROLL' || response.status == 'MFA_REQUIRED'){
        localStorage.setItem('oktaAuthInfo', JSON.stringify(response));
        this.router.navigateByUrl('/mfa-authenticator');
    } else {
      // If MFA disabled (Prompt for Factor) from okta admin
      this.userSubscription = this.authenticationService.accountUser.subscribe(apiRes => {
        if(apiRes !== undefined){
          let lastUsedAccount = apiRes.account_list.find(account => account.id === apiRes.user.last_used_account_id);
          const account = lastUsedAccount ? lastUsedAccount : apiRes.account_list[0];
          const userCurrentAccountId = lastUsedAccount ? lastUsedAccount.company_id : apiRes.account_list[0].company_id;
          localStorage.setItem('token', apiRes['session_token']);      
          localStorage.setItem('selected_company_id', `${userCurrentAccountId}`);
          this.authenticationService.onAccountChange(apiRes, account);
        } 
      })
    }
    }).catch(error => {
      this.pageLoading = false;
      this.loginError = 'MFA Authentication Failed';
      console.error('Exception in oktaLogin:', error.errorSummary)
      return
    });
  }

  oktaLoginWrapper() {
    this.router.navigateByUrl('/okta-login')
  }

  setFullStoryTrack(accountInfo) {
    let name  = '';
    if (environment.hasOwnProperty('fullStoryKey')){
       name = accountInfo.firstname;
      if (undefined != accountInfo.lastname)
        name = name +' '+ accountInfo.lastname;
      FullStory.identify(accountInfo.user_id, {
        uid: accountInfo.account_id,
        displayName: name,
        email: accountInfo.email,
        companyId: accountInfo.company_id,
        companyName: accountInfo.company_name,
        instanceName: accountInfo.instance_name,
        loginType: accountInfo.display_role_name
      });
    }
  }

  ssoLogin(provider) {
    const delay = 2000;

    if (this.canCall) {
      this.canCall = false;

      this.authenticationService.ssoLogin(provider).subscribe(
        (apiRes) => {
          // let twoFactorNecessary = '';
          // if (apiRes.settings) {
          //   twoFactorNecessary = apiRes.settings.two_factor_auth;
          // }
          // if (twoFactorNecessary !== '1') {
            localStorage.setItem('token', apiRes['session_token']);
           // const lastUsedAccount = apiRes.account_list.find(account => account.id === apiRes.user.last_used_account_id);
             let lastUsedAccount = apiRes.account_list.find(account => account.id === apiRes.user.last_used_account_id);
            if (environment.application !== 'PORTAL'){
               if(null != lastUsedAccount && lastUsedAccount.display_role_name === "Portal User")
                  lastUsedAccount = null;
              apiRes.account_list = apiRes.account_list.filter(account => account.display_role_name !== "Portal User");
            }
            if(apiRes.account_list.length === 0){
                localStorage.removeItem('token');
                this.loginError = 'No instance configured for this User';
                return;
            }
            const userCurrentAccountId = lastUsedAccount ? lastUsedAccount.company_id : apiRes.account_list[0].company_id;
            localStorage.setItem('selected_company_id', `${userCurrentAccountId}`);
            // CW uses accountLogin route while all other apps are using login route to dispatch userInformation data.
            if (environment.application !== 'CW' && environment.application !== 'PORTAL') {
              this.authenticationService.setUserInformation(apiRes, userCurrentAccountId);
            }
            // if (environment.application === 'EP') {
            //   if (this.initialPage === '/search-by-company' && this.lastLocationsSearch) {
            //     return this.router.navigateByUrl(`${this.initialPage}/${this.lastLocationsSearch}`);
            //   } else if (this.initialPage === '/search-by-company' && !this.lastLocationsSearch) {
            //     return this.router.navigateByUrl('/');
            //   } else {
            //     return this.router.navigateByUrl(this.initialPage);
            //   }
            // }
            if (environment.application === 'PORTAL') {
              let acc = find(apiRes['account_list'], { display_role_name: 'Portal User', portal_url_suffix:this.authenticationService.activeCompany });
              if (acc && acc['is_att_portal'] == 0) {
                if (acc['portal_url_suffix'] !== this.authenticationService.activeCompany) {
                  this.loginError = 'User not associated with this company portal';
                  return;
                }
                else {
                  this.authenticationService.onAccountChange(apiRes, acc);
                  return this.router.navigateByUrl('/' + this.authenticationService.activeCompany);
                }
              }
              else {
                localStorage.removeItem('token');
                localStorage.removeItem('selected_company_id');
                localStorage.removeItem('portal_company_name')
                this.loginError = 'Not a portal user';
                return;
              }
            }
            if (environment.application === 'CW') {
              // CW uses accountLogin route to dispatch userInformation data.
              const account = lastUsedAccount ? lastUsedAccount : apiRes.account_list[0];
              this.authenticationService.onAccountChange(apiRes, account);
            } else {
              this.router.navigateByUrl('/');
            }
          // } else {
          //   this.email = this.myForm.value.email;
          //   this.password = this.myForm.value.password;
          //   this.twoFactor = true;
          // }
        },
        (err) => {
          this.loginError = err.error.message;
        }
      );

      setTimeout(() => {
        this.canCall = true;
      }, delay)
    }
  }
  
  ngOnDestroy() {
    if(this.userSubscription){
      this.userSubscription.unsubscribe();
    }
  }
}
