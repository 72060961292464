import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/**
 * Okta Library
 */
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
// Components
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SignupComponent } from './components/signup/signup.component';
import { OktaMfaComponent } from './components/mfa/okta-mfa.component';
import { OktaDeleteFactorComponent } from './components/mfa/delete-factor/delete-factor.component';
import { OktaLoginComponent } from './components/okta-login/okta-login.component';
import { OktaCallbackComponent } from './components/okta-login/okta-callback.component';

// Services
import { UnauthGuardService } from './services/unauth-guard/unauth-guard.service';
import config from './okta.config';


/*
  Only CW will be allowed to signup users.
*/
const signupRoute = {
  path: 'signup',
  component: SignupComponent,
  canActivate: [UnauthGuardService]
};

const authRoutes: Routes = [
  {
    path: ':company/login',
    component: LoginComponent,
    canActivate: [UnauthGuardService]
  },
  {
    path: ':company/forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [UnauthGuardService]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [UnauthGuardService]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [UnauthGuardService]
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [UnauthGuardService]
  },
  {
    path: 'mfa-authenticator',
    component: OktaMfaComponent,
    canActivate: [UnauthGuardService]
  },
  {
    path: 'okta-delete-factor',
    component: OktaDeleteFactorComponent,
    canActivate: [UnauthGuardService]
  },
  {
    path: 'okta-login',
    component: OktaLoginComponent,
    canActivate: [UnauthGuardService]
  },
  {
    path: 'okta-callback',
    component: OktaCallbackComponent,
    canActivate: [UnauthGuardService]
  }
];

// Not sure if are going to use signup route to register new users.
// Since users are not allowed to signup from TCW as well, this code should not be used for now.
// if (environment.application === 'CW') {
//   authRoutes.push(signupRoute);
// }

@NgModule({
  imports: [RouterModule.forChild(authRoutes), OktaAuthModule],
  exports: [RouterModule],
  providers: [
    { provide: OKTA_CONFIG, useValue: config.oidc }
  ],
})
export class AuthenticationRoutingModule {}
