/**
 Name: Portal
 Date: 03/20/2020
 Ver. 0.01
 Last Update: Magesh
 */

export const environment = {
  production: true,
  mapboxKey : 'pk.eyJ1IjoiY29ubmVjdGVkMmZpYmVyLXByb2QiLCJhIjoiY2plYTAyb3hzMDE5YzJ4bzc4cjBjazMxNyJ9.oEut28Ra1R_Z6MFxzV3OzQ',
  apiUrl:  'https://cw-api.connectbase.com',
  fileStorage: '//cwstorage.connectbase.com',
  NOT_CONNECTED: { id: 3, text: 'Not Connected'},
  NEARNET: { id: 6, text: 'Near Net'},
  ONNET_ID: 1,
  ONNET_LIMITED_ACCESS_ID: 5,
  CONNECTED_ID: 1,
  INPROGRESS_ID: 2,
  OFFNET_ID: 2,
  NW_STATUS_PROSPECT: 5,
  enterpriseProfilerUrl: 'https://enterpriseprofiler.connectbase.com',
  unitMBInBytes: 1048576,
  buildingIconPath: '/assets/images/mapicon/building/icon/',
  locationIconPath: '/assets/images/mapicon/location/icon/',
  defaultViewZoom: 16,
  defaultRouteColor: '#0E30EE',
  defaultCountry: { id: 223, text: 'United States', code: 'USA', code2: 'US' },
  maxUploadSize: 100,
  applicationVersion: '1.0.0',
  applicationTitle: 'Portal',
  application: 'PORTAL',
  nodeApiUrl: 'https://api-v2.connectbase.com',
  mapboxStaticImageUrl: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/',
  mapboxGeocodeUrl: 'https://api.mapbox.com/geocoding/v5/mapbox.places/',
  networkFinderURL: 'http://nf.connectbase.com',
  epUrl: 'https://enterpriseprofiler.connectbase.com',
  mapboxTilesetApi: 'https://api.mapbox.com/v4/',
  routeBufferZoom: 15,
  // url pointer but in this case this should be pointing internally. EP depends on this value.
  cwUrl: "",
  // ip address for staging/uat offnetPricingApi
  offnetPricingApi: "http://192.168.89.11:8085/api/v2/internal/remotePrice",
  routeBufferMaxDistance: 5000,
  showVzSSO: false,
  fullStoryKey:"YMZ26",
  attCompanyId: 1,
  forceFetch:false,
  maxWaitTime:60000,
  oktaHostURL: "https://connected2fiber.okta.com",
  oktaToken: '00vKFhG0OZRQnLUNUmB3i7fOvJQq0o1Pvztopa1S2H',
  oktaIssuer: "https://connected2fiber.okta.com/oauth2/default",
  oktaRedirectURL: 'https://cw.connectbase.com',
  oktaClientId: "0oa3nj4faXzSSdcyH5d6",
  oktaSSOGroupId: "",
  oktaCallBackUrl: "",
  oktaBookMarkURL: "https://connected2fiber.okta.com/home/bookmark/0oa2aj4cq9kh1EohA5d7/2557",
  oktaSSO: false,
  vzCanvasCookieMatch: "vz",
  feedBackURL: "",
  PORTAL_USER : 9,
  EDIT_USER : 2,
  VIEW_USER : 3,
  ADMIN: 1,
  BILLING_ADMIN: 7,
  ROLE_CARRIER_RELATIONS_ADMIN: 8,
  LIST_MANAGER_PERMISSION: 18
};

