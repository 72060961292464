import {catchError, map} from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';

import { PersistanceService } from '../services/persistence.service';
import { environment } from '../../environments/environment';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor(private router: Router,
    private persistanceService: PersistanceService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // For API without Authentication Header
    if (request.url === '/v1/user/login' ||
        request.url === '/v1/user/accountLogin' ||
        request.url === '/v1/user/account' ||
        request.url === '/v1/reset-password-email' ||
        request.url === '/v1/reset-password-in-db' ||
        request.url === '/v1/is-valid-user' ||
        request.url === '/v1/change-password' ||
        request.url === '/v1/portal-config') {
      request = request.clone({url: `${environment.nodeApiUrl}${request.url}`});
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => event),
      catchError((error: HttpErrorResponse) => {
        // In case of an error start logging errors from here and save errors into elastic search from here.
        if ( error.status === 401 &&
            this.router.url !== '/login' &&
            this.router.url !== '/signup' &&
            this.router.url !== '/forgot-password' &&
            !this.router.url.includes('/reset-password') &&
            !this.router.url.includes('/customersignup') &&
            !this.router.url.includes('/portal-config')) {
          this.persistanceService.logout();
        }
        return throwError(error);
      })
    );
  }
}
