import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { StoreModule } from '@ngrx/store';

// Components
import { ImportDetailsComponent } from './components/import-details/import-details.component';
import { ErrorSnackbarComponent } from './components/ui/error-snackbar/error-snackbar.component';

// Services
import { UserManagementService } from './services/user-management.service';
import { AddressService } from './services/address.service';
import { SnackbarService } from './services/snackbar.service';
import { SharedApiCallService } from './services/shared-api-call.service';
import { AutocompleteService } from './services/autocomplete/autocomplete.service';
import { ProvidersService } from './services/providers.service';

// Reducer
import { sharedComponentReducers } from './shared-components.reducers';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature('sharedComponentFeature', sharedComponentReducers)
  ],
  exports: [
    ImportDetailsComponent,
    ErrorSnackbarComponent
  ],
  declarations: [
    ImportDetailsComponent,
    ErrorSnackbarComponent
  ], entryComponents: [
    ErrorSnackbarComponent
  ]
})

export class SharedComponentsModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedComponentsModule,
      providers: [
        UserManagementService,
        AddressService,
        SnackbarService,
        SharedApiCallService,
        AutocompleteService,
        ProvidersService
      ]
    };
  }
}
