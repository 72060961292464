import { Injectable } from '@angular/core';
import { Router} from '@angular/router';
import { MatDialog } from '@angular/material';
import { isEmpty } from 'lodash';

import { PersistanceService } from './persistence.service';
import { FeedbackComponent } from 'app/shared/components/feedback/feedback.component';
import { UserDetailComponent } from 'app/shared/components/user-detail/user-detail.component';
import { ResetPasswordComponent } from 'app/shared/components/reset-password/reset-password.component';
import { AboutComponent } from 'app/shared/components/about/about.component';
import { InlineService } from './inline-manual/inline-service';
import { InlineManualTracking } from './inline-manual/interfaces';
import { environment } from 'environments/environment';
import { InfoService } from 'app/services/info.service';

@Injectable()
export class HeaderService {

    constructor( private inlineService: InlineService,
        public router: Router, public dialog: MatDialog,
        private persistanceService: PersistanceService,
        private infoService: InfoService) {

      }

      companyLogo(accountInfo) {
        return (environment.fileStorage + '/img/' + accountInfo.company_logo);
      }

      /**
       * Open feedback bottomsheet
       */
      feedback() {
        const dialogRef = this.dialog.open(FeedbackComponent, {
          id: 'feedback_dialog',
          width: '45%'
        });
      }

      navigateToAdmin(accountInfo) {
        this.router.navigateByUrl('/company/'+accountInfo.company_id);
      }

      changeCompany() {
        this.router.navigateByUrl('/chooselogin')
      }

      /**
     * Opens up Add/Edit user dialog
     * @param row
     * @param title
     */
    openProfileDialog(accountInfo) {
      const dialogRef = this.dialog.open(UserDetailComponent, {
        id: 'user_detail_dialog',
        width: '60%',
        disableClose: true,
        data: {
          userInfo: this.profileData(accountInfo),
          title: 'Edit Profile',
          companyId: accountInfo.company_id
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(typeof result == 'object') this.updateSavedData(result, accountInfo);
      });
    }

    openResetView() {
      const dialogRef = this.dialog.open(ResetPasswordComponent, {
        id: 'reset_pwd_dialog',
        width: '35%'
      });
    }

    /**
     * Display application version
     */
    showVersion() {
      const dialogRef = this.dialog.open(AboutComponent, {
        id: 'about_dialog',
        width: '30%'
      });
    }

    profileData(accountInfo) {
      let profile = {
        id: accountInfo.id,
              companyname: accountInfo.company_name,
              displayRoleName: accountInfo.role_name,
              companyLogo: accountInfo.company_logo,
              userID: accountInfo.user_id,
              companyid: accountInfo.company_id,
              isactive: accountInfo.isactive,
              title: accountInfo.work_title,
              email: accountInfo.email,
              workphone: accountInfo.work_phone,
              firstname: accountInfo.first_name,
              lastname: accountInfo.last_name,
              rolename: accountInfo.role_name,
              roleid: accountInfo.role_id,
              contactid: accountInfo.contact_id,
              cwAccessLevel: accountInfo.cw_access_level,
              epAccessLevel: accountInfo.ep_access_level,
              dccAccessLevel: accountInfo.dcc_access_level,
              dmAccessLevel: accountInfo.dm_access_level,
              nfAccessLevel: accountInfo.nf_access_level,
              nfProvidersAccessLevel: accountInfo.nf_providers_access
      };
      return profile;
    }

    private updateSavedData(userInfo, accountInfo) {
      let newValues = {
        work_title: userInfo.title,
        work_phone: userInfo.workphone,
        first_name: userInfo.firstname,
        last_name: userInfo.lastname,
        cw_access_level: userInfo.cwAccessLevel,
        ep_access_level: userInfo.epAccessLevel,
        dcc_access_level: userInfo.dccAccessLevel,
        dm_access_level: userInfo.dmAccessLevel,
        nf_access_level: userInfo.nfAccessLevel,
        nf_providers_access: userInfo.nfProvidersAccessLevel
      };
      accountInfo = Object.assign(accountInfo, newValues);
      this.persistanceService.userAccount = accountInfo;
    }

    trackData(accountInfo) {
      let inlineData: InlineManualTracking = {
        uid: accountInfo.id,
        email: accountInfo.email,
        name: accountInfo.first_name,
        roles: accountInfo.role_name
      };
      return inlineData;
    }

    inlineTrackData(accountInfo) {
      this.inlineService.setTracking(this.trackData(accountInfo));
      var retries = 0;
      var timer = setInterval(() => {
        if (typeof this.inlineService.inlinePlayer !== 'undefined') {
          this.inlineService.createPlayer();
          clearInterval(timer);
        } else if (retries > 10) {
          clearInterval(timer);
        }
        retries++;
      }, 1000);
    }

    showPanel() {
        this.inlineService.showPanel();
    }

    /**
    * Build profile user name
    */
    companyUsername(accountInfo) {
        return accountInfo.first_name;
    }

    logout() {
    this.persistanceService.logout();
    }

    showFilterSideNav() {
      this.inlineService.hidePanel();
      this.infoService.notifyOther(InfoService.SHOW_FILTER);
    }

    hideFilterSideNav() {
      this.infoService.notifyOther(InfoService.HIDE_FILTER);
    }

    upload() {
      this.infoService.notifyOther(InfoService.START_UPLOAD);
    }
}
