import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'page-size',
  templateUrl: './page-size.component.html',
  styleUrls: ['./page-size.component.scss']
})
export class PageSizeComponent implements OnInit {
  @Output() pageLimitChanged = new EventEmitter<number>();
  pageLimitOptions = [ 25, 50, 100, 500 ];
  limit = localStorage.getItem('pageLimit') ? Number(localStorage.getItem('pageLimit')) : 25;

  constructor() { }

  ngOnInit() {
    this.pageLimitChanged.next(this.limit);
  }

  onChangePageLimit({ value }) {
    this.limit = value;
    localStorage.setItem('pageLimit', `${this.limit}`);
    this.pageLimitChanged.next(this.limit);
  }
}
