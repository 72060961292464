import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';
@Injectable()
export class InfoService {

  public static SHOW_FILTER = 'SHOW_FILTER';
  public static HIDE_FILTER = 'HIDE_FILTER';
  public static SHOW_TENANT_OVERLAY = 'SHOW_TENANT_OVERLAY';
  public static START_UPLOAD = 'START_UPLOAD';
  public static HIDE_BOTTOM_SHEET = 'HIDE_BOTTOM_SHEET';

  public static CALL_ATT_PRODUCT_API = "CALL_ATT_PRODUCT_API";
  
  private notify = new Subject<any>();
  notifyObservable$ = this.notify.asObservable();

  constructor(){}

  public notifyOther(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }
  
}